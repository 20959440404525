import styled, { css } from "styled-components";
import { colorsPalette } from "../../styles/colorsPalette";

export const StyledNiwartLogo = styled.div<{checked: boolean}>`
  width: 156px;
  height: 32px;

  & path:first-child, & path:nth-child(2) {
    ${({ checked }) =>
            !checked && css`
              fill: ${colorsPalette.primary["600"]}
            `
    }
  }

  & path:nth-child(n + 3) {
    fill: ${({ checked }) => (checked ? "#fff" : colorsPalette.neutral["500"])};
  }
`
