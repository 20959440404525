import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledPartners = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  padding: 120px 20px;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-template-areas:
    "title text"
    "partners partners";

  & p {
    grid-area: text;
    justify-self: end;
    max-width: 496px;
    line-height: 28px;
    margin: 0;
  }

  @media (max-width: 1080px) {
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "text"
      "partners ";
    gap: 24px;
    & p {
      justify-self: start;
      max-width: 100%;
    }
  }
  @media (max-width: 732px) {
    padding: 48px 20px;

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const StyledTitle = styled.h2`
  max-width: 630px;
  grid-area: title;
  font-size: 48px;
  font-weight: 400;
  line-height: 64px;
  margin: 0;

  & span {
    font-size: 48px;
    line-height: 64px;
    font-style: italic;
    font-weight: 250;
    color: blue;
    background: ${({ theme }) => theme.services.TextBg};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-right: 10px;
    white-space: nowrap;
  }
  
  @media (max-width: 768px) { 
    font-size: 32px;
    line-height: 40px;

    span {
      font-size: 32px;
      line-height: 40px;
    }
  }
`;

export const StyledLogoWrapper = styled(motion.div)`
  grid-area: partners;
  display: grid;
  grid-template-columns: repeat(4, 262px);
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  margin-top: 72px;
  row-gap: 60px;

  & .logo {
    position: relative;

    & svg {
      transition: 0.8s ease;

      &:last-child {
        position: absolute;
        left: 0;
        opacity: 0;
      }
    }

    &:hover {
      & svg:first-child {
        opacity: 0;
      }

      & svg:last-child {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    & .logo:nth-last-child(1) {
      grid-column: 3;
    }
  }
  @media (max-width: 880px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 0;
    & .logo:nth-last-child(1) {
      grid-column: auto;
    }
  }
  @media (max-width: 590px) {
    & .logo svg {
      width: 100%;
      height: auto;
    }
  }
`;
