import { ProjectType } from "../../types";
import refmetrix from "../../assets/images/portfolio/refmetrix.webp";
import refmetrix_mobile from "../../assets/images/portfolio/refmetrix_mobile.webp";
import refmetrix_lower from "../../assets/images/projects/refmetrix_lower.webp";
import refmetrix_middle from "../../assets/images/projects/refmetrix_middle.webp";
import refmetrix_higher from "../../assets/images/projects/refmetrix_higher.webp";
import ProjectHeaderText from "../../components/Project/ProjectHeaderText/ProjectHeaderText";
import ProjectPageCard from "../../components/Project/ProjectPageCard/ProjectPageCard";
import refmetrix_desktop from "../../assets/images/project/refmetrix/refmetrix_main_desktop.png";
import ProjectMainInfo from "../../components/Project/ProjectMainInfo/ProjectMainInfo";
import ProjectTextSection from "../../components/Project/ProjectTextSection/ProjectTextSection";
import refremtrix_create_manage from "../../assets/images/project/refmetrix/create-and-manage.png";
import refremtrix_create from "../../assets/images/project/refmetrix/create.png";
import refremtrix_game_reports from "../../assets/images/project/refmetrix/game-reports.png";
import refremtrix_events from "../../assets/images/project/refmetrix/events.png";
import refremtrix_analyze1 from "../../assets/images/project/refmetrix/analyze1.png";
import refremtrix_analyze2 from "../../assets/images/project/refmetrix/analyze2.png";
import refremtrix_collaborate from "../../assets/images/project/refmetrix/collaborate.png";
import refremtrix_outcomes1 from "../../assets/images/project/refmetrix/outcomes1.png";
import refremtrix_outcomes2 from "../../assets/images/project/refmetrix/outcomes2.png";
import refremtrix_outcomes3 from "../../assets/images/project/refmetrix/outcomes3.png";
import image_phone_half_size_desktop from "../../assets/images/project/phone_case/image_phone_half_size_desktop.png";
import image_phone_half_size_tablet from "../../assets/images/project/phone_case/image_phone_half_size_tablet.png";
import image_phone_half_size_mobile from "../../assets/images/project/phone_case/image_phone_half_size_mobile.png";
import image_phone_horizontal_tablet from "../../assets/images/project/phone_case/image_phone_horizontal_tablet.png";
import image_phone_horizontal_mobile from "../../assets/images/project/phone_case/image_phone_horizontal_mobile.png";
import image_phone_full_shrink_size_desktop from "../../assets/images/project/phone_case/image_phone_full_shrink_size_desktop.png";
import image_phone_full_shrink_size_tablet from "../../assets/images/project/phone_case/image_phone_full_shrink_size_tablet.png";
import image_phone_full_size_mobile from "../../assets/images/project/phone_case/image_phone_full_size_mobile.png";
import refmetrix_outcomes_item_desktop from "../../assets/images/project/refmetrix/refmetrix_outcomes_item_desktop.png";
import image_phone_full_size_desktop from "../../assets/images/project/phone_case/image_phone_full_size_desktop.png";
import image_phone_full_size_tablet from "../../assets/images/project/phone_case/image_phone_full_size_tablet.png";
import image_phone_horizontal_desktop from "../../assets/images/project/phone_case/image_phone_horizontal_desktop.png";
import image_phone_half_shrink_desktop from "../../assets/images/project/phone_case/image_phone_half_shrink_desktop.png";
import image_phone_half_shrink_tablet from "../../assets/images/project/phone_case/image_phone_half_shrink_tablet.png";
import image_phone_full_page_desktop from "../../assets/images/project/phone_case/image_phone_full_page_desktop.png";
import ProjectGoals from "../../components/Project/ProjectGoals/ProjectGoals";
import ProjectStack from "../../components/Project/ProjectStack/ProjectStack";
import { FirebaseIcon, NodeIcon, ReactIcon, ReduxIcon, VictoryNativeIcon } from "../../components/Icons/Icons";

export const refmetrixData: ProjectType = {
  name: "Sports Mobile App",
  industry: "SportTech",
  isFav: true,
  services: [
    "Mobile App Development",
    "UX/UI Design",
    "Information Architecture",
    "Wireframing & Prototyping",
    "QA",
  ],
  portfolio_image: refmetrix,
  portfolio_image_hash: 'LwL,K[|@wcA[w^SNa}n%sASgbHn%',
  portfolio_mobile: refmetrix_mobile,
  color: "#fff",
  description:
    "RefMetrix is the world's first state-of-the-art interactive training and development platform for basketball referees. Niwart has helped transform the client's idea into a top-notch cross-platform mobile application.",
  image: {
    color: "#E84D00",
    lower: refmetrix_lower,
    middle: refmetrix_middle,
    higher: refmetrix_higher,
  },
  href: "sports-mobile-app",
  projectPage: [
    {
      component: ProjectHeaderText,
      props: {
        seoTitle: "Niwart Case Study | Sports Mobile App",
        seoDescription: "Learn how Niwart's tailored approach and cutting-edge technology delivered a game-changing mobile solution for Basketball Referees.",
        title: "Sports Mobile App",
        titleColor: `#E84D00`,
        description:
          "RefMetrix is the world's first state-of-the-art interactive training and development platform for basketball referees. Niwart has helped transform the client's idea into a top-notch cross-platform mobile application.",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: refmetrix_desktop,
              tablet: refmetrix_desktop,
              mobile: refmetrix_desktop,
            },
          },
        ],
        maxWidth: 1920,
        padding: "0",
      },
    },
    {
      component: ProjectMainInfo,
      props: {
        header: ["The Challenge"],
        text: [
          "In the realm of basketball officiating, traditional methods involving notepads and pens have long been the go-to tools for referees and observers to document critical game events. Recognizing the limitations of this analog approach, our revolutionary mobile application transforms the industry by empowering referees to seamlessly record and analyze game data using their smartphones or tablets. By introducing a modern and intuitive interface, we simplify their workflow and provide an efficient platform for collaborative engagement. The application marks a paradigm shift, ushering basketball officiating into the digital age for heightened efficiency and teamwork.",
        ],
        direction: "vertical",
        titleColor: `#E84D00`,
        gap: "18px",
        maxWidth: "1264px",
        industry: "SportTech",
        location: "USA",
        services: [
          "Mobile App Development",
          "Information Architecture",
          "Wireframing & Prototyping",
          "UX/UI Design",
          "QA",
        ],
      },
    },
    {
      component: ProjectGoals,
      props: {
        headerText: ["Project", "Goals"],
        titleColor: `#E84D00`,
        goals: [
          {
            title: ["Diving into Referees' Worlds"],
            text: "We conducted an extensive discovery phase to intimately understand the daily routines, pain points, and intricacies of note-taking processes used by referees and observers, paving the way for targeted improvements.",
          },
          {
            title: ["Crafting a Feature-Packed MVP"],
            text: "Our focus was on identifying and integrating essential functionalities that not only covered the spectrum of end-users’ needs but also formed a robust MVP for the initial app release.",
          },
          {
            title: ["Thriving in the Heat of the Game"],
            text: "Post MVP creation, our attention shifted to ensuring the seamless functionality of the app in the dynamic and fast-paced environment of basketball games, guaranteeing a top-notch user experience for referees during every intense moment on the court.",
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Create and Manage", "Games"],
        titleColor: `#E84D00`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: refremtrix_create_manage,
              tablet: refremtrix_create_manage,
              mobile: refremtrix_create_manage,
            },
          },
        ],
        maxWidth: 1264,
        padding: "24px 20px 64px",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              maxWidth: '455px',
              data: "Users can create games they participate in, invite their referee crew, or receive invites to games.",
              align: "start",
              padding: "0",
              position: {
                desktop: "left",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: refremtrix_create,
              tablet: refremtrix_create,
              mobile: refremtrix_create,
            },
          },
        ],
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              maxWidth: '455px',
              data: "They have the option to share a generated game report with fellow participants and other app users.",
              align: "start",
              padding: "0",
              position: {
                desktop: "right",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: refremtrix_game_reports,
              tablet: refremtrix_game_reports,
              mobile: refremtrix_game_reports,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Track Game", "Events"],
        titleColor: `#E84D00`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "With a full-court map display and an intuitive interface, users can effortlessly track 1-3 referees, even in fast-paced games. Referees can also rearrange the scorers' table and team benches for accurate observation during the game.",
              align: "center",
              padding: "0 100px",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: refremtrix_events,
              tablet: refremtrix_events,
              mobile: refremtrix_events,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Analyze", "Progress"],
        titleColor: `#E84D00`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "RefMetrix Stats offers a detailed play-by-play breakdown, including specialized stats for individuals and crews.",
              align: "center",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: refremtrix_analyze1,
              tablet: refremtrix_analyze1,
              mobile: refremtrix_analyze1,
            },
          },
          {
            text: {
              data: "Users can easily review and edit stats within the dedicated game log section.",
              align: "center",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: refremtrix_analyze2,
              tablet: refremtrix_analyze2,
              mobile: refremtrix_analyze2,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Collaborate"],
        text: [
          "Utilizing a referee's personalized Metrix ID, connecting and collaborating with other RefMetrix users is made simple and efficient.",
        ],
        titleColor: `#fff`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: refremtrix_collaborate,
              tablet: refremtrix_collaborate,
              mobile: refremtrix_collaborate,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Project", "Outcomes"],
        titleColor: `#E84D00`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: refremtrix_outcomes1,
              tablet: refremtrix_outcomes1,
              mobile: refremtrix_outcomes1,
            },
            text: {
              title: "Addressed Pain Points Successfully",
              data: "The implementation of the abovementioned features directly resolves the previously identified pain points of referees and observers. The app's user-centric design and functionalities alleviate the challenges associated with traditional note-taking methods, offering a more streamlined and effective solution.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: refremtrix_outcomes2,
              tablet: refremtrix_outcomes2,
              mobile: refremtrix_outcomes2,
            },
            text: {
              title: "Mobile and Tablet Flexibility",
              data: "The app's user-friendly design ensures flexibility by being accessible on both mobile devices and tablets. This adaptability allows referees and observers to use the application seamlessly across various platforms, providing convenience and ensuring an optimal user experience regardless of the device used.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: refremtrix_outcomes3,
              tablet: refremtrix_outcomes3,
              mobile: refremtrix_outcomes3,
            },
            text: {
              title: "Cross-Platform Availability",
              data: "With a cross-platform approach, the app caters to a broader user base by being available on both iOS and Android devices. This inclusivity ensures that referees and observers, regardless of their device preferences, can collaborate and benefit from the app's features, promoting widespread adoption and usability.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectStack,
      props: {
        frontendStack: [
          {
            svg: ReactIcon,
            name: 'React Native'
          },
          {
            svg: ReduxIcon,
            name: 'Redux'
          },
          {
            svg: VictoryNativeIcon,
            name: 'Victory Native'
          },
        ],
        backendStack: [
          {
            svg: FirebaseIcon,
            name: 'Firebase'
          },
          {
            svg: NodeIcon,
            name: 'Node.js'
          },
        ]
      },
    },
  ],
  // mobileCardItems: [
  //   {
  //     maxWidth: 1224,
  //     items: [
  //       {
  //         image: {
  //           desktop: image_phone_full_size_desktop,
  //           tablet: image_phone_full_size_tablet,
  //           mobile: image_phone_full_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1016,
  //     items: [
  //       {
  //         text: {
  //           data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris.",
  //           align: "center",
  //           padding: "0",
  //           position: {
  //             desktop: "bottom",
  //             tablet: "bottom",
  //             mobile: "bottom",
  //           },
  //         },
  //         image: {
  //           desktop: image_phone_horizontal_desktop,
  //           tablet: image_phone_horizontal_tablet,
  //           mobile: image_phone_horizontal_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1224,
  //     items: [
  //       {
  //         text: {
  //           data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.",
  //           align: "start",
  //           padding: "0",
  //           position: {
  //             desktop: "left",
  //             tablet: "bottom",
  //             mobile: "bottom",
  //           },
  //         },
  //         image: {
  //           desktop: image_phone_half_size_desktop,
  //           tablet: image_phone_half_size_tablet,
  //           mobile: image_phone_half_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1224,
  //     items: [
  //       {
  //         text: {
  //           data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.",
  //           align: "start",
  //           padding: "0",
  //           position: {
  //             desktop: "right",
  //             tablet: "top",
  //             mobile: "top",
  //           },
  //         },
  //         image: {
  //           desktop: image_phone_half_size_desktop,
  //           tablet: image_phone_half_size_tablet,
  //           mobile: image_phone_half_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1224,
  //     items: [
  //       {
  //         text: {
  //           data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.",
  //           align: "center",
  //           padding: "0",
  //           position: {
  //             desktop: "bottom",
  //             tablet: "bottom",
  //             mobile: "bottom",
  //           },
  //         },
  //         image: {
  //           desktop: image_phone_full_shrink_size_desktop,
  //           tablet: image_phone_full_shrink_size_tablet,
  //           mobile: image_phone_full_size_mobile,
  //         },
  //       },
  //       {
  //         text: {
  //           data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.",
  //           align: "center",
  //           padding: "0",
  //           position: {
  //             desktop: "bottom",
  //             tablet: "bottom",
  //             mobile: "bottom",
  //           },
  //         },
  //         image: {
  //           desktop: image_phone_full_shrink_size_desktop,
  //           tablet: image_phone_full_shrink_size_tablet,
  //           mobile: image_phone_full_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1224,
  //     items: [
  //       {
  //         image: {
  //           desktop: image_phone_half_shrink_desktop,
  //           tablet: image_phone_half_shrink_tablet,
  //           mobile: image_phone_half_size_mobile,
  //         },
  //       },
  //       {
  //         image: {
  //           desktop: image_phone_half_shrink_desktop,
  //           tablet: image_phone_half_shrink_tablet,
  //           mobile: image_phone_half_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1440,
  //     items: [
  //       {
  //         image: {
  //           desktop: image_phone_full_page_desktop,
  //           tablet: image_phone_full_size_tablet,
  //           mobile: image_phone_full_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1224,
  //     items: [
  //       {
  //         text: {
  //           data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.",
  //           align: "start",
  //           padding: "0",
  //           position: {
  //             desktop: "left",
  //             tablet: "bottom",
  //             mobile: "bottom",
  //           },
  //         },
  //         image: {
  //           desktop: image_phone_full_shrink_size_desktop,
  //           tablet: image_phone_full_size_tablet,
  //           mobile: image_phone_full_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     maxWidth: 1224,
  //     items: [
  //       {
  //         text: {
  //           data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.",
  //           align: "start",
  //           padding: "0",
  //           position: {
  //             desktop: "right",
  //             tablet: "top",
  //             mobile: "top",
  //           },
  //         },
  //         image: {
  //           desktop: image_phone_full_shrink_size_desktop,
  //           tablet: image_phone_full_size_tablet,
  //           mobile: image_phone_full_size_mobile,
  //         },
  //       },
  //     ],
  //   },
  // ],
};
