import React, { useRef } from 'react';
import { StyledPreloader } from "./styled";
import { AnimatePresence } from "framer-motion";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

type PropsType = {
  isOpen: boolean
  animationData: unknown
}

const Preloader = ({ isOpen, animationData }: PropsType) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null)
  const animVars = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        type: "easeIn",
      },
    },
  }

  const handleLoaded = () => {
    let current = lottieRef?.current as LottieRefCurrentProps
    current && current?.play();
  }

  return (
    <AnimatePresence>
      {isOpen && <StyledPreloader animate={'visible'} exit={'hidden'} variants={animVars}
                                  transition={{ type: "easeOut", duration: 0.5 }}>
          <Lottie height={'100vh'} lottieRef={lottieRef} animationData={animationData} autoplay={false}
                  onDOMLoaded={handleLoaded} loop={false}/>
      </StyledPreloader>}
    </AnimatePresence>
  );
};

export default Preloader;