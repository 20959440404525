import styled from "styled-components";

export const StyledPortfolioTitle = styled.section`
  & h1 {
    max-width: 1168px;
    margin: 0 auto;
    padding: 152px 24px 72px;
    font-size: 40px;
    line-height: 52px;
    font-weight: 400;
    text-align: center;
    font-style: normal;

    & span {
      font-style: italic;
      font-weight: 200;
      font-size: inherit;
      line-height: inherit;
      color: blue;
      background: ${({ theme }) => theme.services.TextBg};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-right: 1px;
      white-space: nowrap;
    }
  }

  @media (max-width: 550px) {
    & h1 {
      font-size: 20px;
      line-height: 28px;
      padding: 112px 24px 48px;

      & span {
        font-style: inherit;
        font-weight: inherit;
      }
    }
  }
`