import styled from "styled-components";
import svgDark from "../../assets/icons/right-arrow.svg";
import { motion } from "framer-motion";

export const StyledServicesWrapper = styled(motion.section)`
  padding: 185px 0;
  position: relative;
  overflow-x: clip;

  @media (max-width: 1024px) {
    padding: 140px 0 70px;
  }

  
  @media (max-width: 768px) {
    padding: 48px 0;
  }

  & .alignedLeft {
    top: 162px;
    left: 0;
    @media (max-width: 1400px) {
      left: -10%;
    }
    @media (max-width: 1100px) {
      left: 0;
    }
    @media (max-width: 560px) {
      left: -50px;
    }
  }

  & .alignedRight {
    bottom: 5%;
    right: 0;
    @media (max-width: 1800px) {
      bottom: 0;
    }
    @media (max-width: 1400px) {
      right: -10%;
    }
    @media (max-width: 1100px) {
      right: 20px;
    }
    @media (max-width: 560px) {
      right: -50px;
    }
  }
`;

export const StyledServicesBg = styled.div`
    position: absolute;
    width: 606px;
    height: 645px;

    @media (max-width: 1100px) {
        width: 206px;
        height: 366px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 150px;
        display: block;
        width: 456px;
        height: 456px;
        background: ${({ theme }) =>
    theme.text === "#fff"
      ? `radial-gradient(51.87% 53.45% at 48.01% 53.41%, rgba(25, 83, 255, 0.25) 0%, rgba(25, 83, 255, 0.05) 72.3%, rgba(25, 83, 255, 0.00) 90.22%)`
      : `radial-gradient(51.87% 53.45% at 48.01% 53.41%, rgba(25, 83, 255, 0.20) 0%, rgba(25, 83, 255, 0.04) 72.3%, rgba(25, 83, 255, 0.00) 90.22%)`};
        filter: blur(34px);
        border-radius: 100%;
        animation: moveBgSmall 10s ease-in-out both infinite;

        @media (max-width: 1100px) {
            width: 233px;
            height: 233px;
            left: 80px;
            animation: moveBgSmall1024 10s ease-in-out both infinite;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 97px;
        left: 8%;
        display: block;
        width: 560px;
        height: 560px;
        border-radius: 100%;
        background: ${({ theme }) =>
    theme.text === "#fff"
      ? `radial-gradient(54.51% 56.16% at 48.01% 53.41%, rgba(0, 153, 255, 0.36) 9.51%, rgba(0, 149, 255, 0.06) 88.14%, rgba(0, 149, 255, 0.00) 100%)`
      : `radial-gradient(54.51% 56.16% at 48.01% 53.41%, rgba(0, 153, 255, 0.30) 9.51%, rgba(0, 149, 255, 0.00) 100%)`};
        filter: blur(34px);
        animation: moveBgBig 10s ease-in-out both infinite;

        @media (max-width: 1100px) {
            width: 303px;
            height: 303px;
            animation: moveBgBig1024 10s ease-in-out both infinite;
        }
    }

    @keyframes moveBgSmall {
        0% {
            top: 8%;
            @media (max-width: 1024px) {
                left: 40px;
            }
        }

        50% {
            top: 35%;
            @media (max-width: 1024px) {
                width: 190px;
                height: 190px;
            }
        }

        100% {
            top: 8%;
            @media (max-width: 1024px) {
                left: 40px;
            }
        }
    }
    @keyframes moveBgBig {
        0% {
            top: 30px;
            left: 0;
        }

        50% {
            top: 0;
            left: 80px;
            width: 500px;
            height: 500px;
            transform: rotate(-90deg);

            @media (max-width: 1024px) {
                width: 150px;
                height: 150px;
                left: 20px;
            }

        }

        100% {
            top: 30px;
            left: 0;
        }
    }
    @keyframes moveBgSmall1024 {
        0% {
            top: 0;
            left: 80px;
        }

        50% {
            top: 20%;
            left: 80px;
        }
        100% {
            top: 0;
            left: 80px;
        }
    }
    @keyframes moveBgBig1024 {
        0% {
            top: 30px;
            left: 0;
        }

        50% {
            top: 0;
            transform: rotate(-90deg);

            width: 250px;
            height: 250px;
            left: 60px;

        }

        100% {
            top: 30px;
            left: 0;
        }
    }
`;

export const StyledServices = styled.article`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;

  & h2 {
    font-weight: 400;
    font-size: 48px;
    line-height: 64px;

    & span {
      font-size: 48px;
      line-height: 64px;
      font-style: italic;
      font-weight: 250;
      color: blue;
      background: ${({ theme }) => theme.services.TextBg};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-right: 10px;
    }

    @media (max-width: 768px) {
      font-size: 32px; 
      line-height: 40px; 

      span {
        font-size: 32px; 
        line-height: 40px; 
      }
    }
  }

  button {
    margin: 0 auto;
    margin-top: 64px;
  
    @media (max-width: 768px) {
      width: 90%;
    }
  }

  @media (max-width: 1240px) {
    padding: 0;
    overflow: hidden;
    & h2 {
      padding: 0 20px;
    }
  }
`;

export const SliderContainer = styled.div`
  padding: 0 10px;
  
  @media (max-width: 768px) {
    padding: 0 24px;
  }
`;

export const SliderCards = styled.div`
  && {
    width: 100%;
    display: none;
    overflow: visible;
    @media screen and (max-width: 1240px) {
      display: flex;
    }
  }
`;

export const NonSlideCards = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1240px) {
    display: none;
  }
`;

export const StyledServiceCard = styled(motion.div)`
  &.keen-slider__slide {
    width: 288px;
    min-width: 288px;
    max-width: 288px;
    margin: 29px 0;
  }

  position: relative;
  height: 380px;
  padding: 32px;
  background-color: ${({ theme }) => theme.services.card.background};
  border: ${({ theme }) => theme.services.card.border};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.4s ease;

  &:hover {
    background: ${({ theme }) => theme.services.card.hoverBg};
    box-shadow: ${({ theme }) => theme.services.card.hoverShadow};
    opacity: 1;

    & * {
      color: ${({ theme }) => theme.services.card.hoveredTitle};
    }

    & .tag {
      opacity: 0;
    }

    & .subTitle {
      opacity: 1;
      transform: translateY(-120px);
    }

    & .tap {
      opacity: 0;
      transition: 0.5s ease;
    }
  }

  & .subTitle {
    position: absolute;
    bottom: -70px;
    opacity: 0;
    transition: 0.6s all cubic-bezier(0.17, 0.67, 0.16, 1.21);
    flex-grow: 1;
    font-size: 14px;
    line-height: 24px;
    max-width: 222px;
  }

  & .tags {
    //height: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:nth-child(2) {
      justify-content: center;
    }

    & .tag {
      position: relative;
      display: flex;
      gap: 8px;
      font-size: 14px;
      line-height: 20px;

      & svg {
        position: relative;
        top: 6px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 6px;
        width: 14px;
        height: 10px;
        background: ${({ theme }) =>
    theme.text === "#fff" ? `url(${svgDark})` : "none"};
      }

      & path {
        fill: ${({ theme }) => theme.services.card.iconFill};
      }
    }
  }

  & h3 {
    color: ${({ theme }) => theme.services.card.title};
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  & .tap {
    display: none;
    position: absolute;
    bottom: 16px;
    right: 16px;
    opacity: ${({ theme }) => (theme.text === "#fff" ? 0.6 : 1)};
    @media (hover: none) {
      display: block;
    }
    @media (max-width: 1230px) {
      display: block;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 112px;
`;
