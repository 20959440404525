import React from "react";
import {
  StyledGoalItem,
  StyledGoalsWrapper,
  StyledProjectGoals,
} from "./styled";
import ProjectTextSection from "../ProjectTextSection/ProjectTextSection";

type PropsType = {
  headerText: Array<string>;
  titleColor: string;
  goals: Array<{
    title: Array<string>;
    text: string;
  }>;
};

const ProjectGoals = ({ headerText, titleColor, goals }: PropsType) => {
  return (
    <StyledProjectGoals>
      <ProjectTextSection
        direction={"vertical"}
        header={headerText}
        titleColor={titleColor}
        padding={"0"}
      />
      <StyledGoalsWrapper>
        {goals.map((item, index) => (
          <StyledGoalItem key={index} $basis={100 / goals.length}>
            {item.title.map((title, i) => (
              <h3 key={i}>{title}</h3>
            ))}
            <p>{item.text}</p>
          </StyledGoalItem>
        ))}
      </StyledGoalsWrapper>
    </StyledProjectGoals>
  );
};

export default ProjectGoals;
