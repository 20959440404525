import { ArrowWrapper, StyledButton } from "./styled";
import { RightArrow } from "../Icons/Icons";
import { ReactNode } from "react";

type PropsType = {
  type: "primary" | "secondary";
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  icon?: ReactNode
};

const Button = ({ onClick, type, text, disabled, icon }: PropsType) => {
  return (
    <StyledButton onClick={onClick} type={type} $text={text} disabled={disabled}>
      <p>{text}</p>
      {icon
        ? <ArrowWrapper>
          {icon}
          {icon}
        </ArrowWrapper>
        : <ArrowWrapper>
          <RightArrow/>
          <RightArrow/>
        </ArrowWrapper>
      }
    </StyledButton>
  );
};

export default Button;
