import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const StyledTestimonials = styled.section`
  min-height: 750px;
  height: fit-content;
  position: relative;
  padding: 104px 0 60px;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding: 0;
    overflow: visible;
  }

   @media (min-width: 2024px) {
    min-height: 900px;
  }
`;

export const StyledWordsWrapper = styled.article`
  position: absolute;
  width: 936px;
  top: 50%;
  left: 50%;
  transform: translate(-53%, -50%);
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  height: min-content;

  & p {
    margin: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    opacity: 1;
    text-align: center;

    @media (max-width: 880px) {
      font-size: 32px;
      line-height: 40px;
    }

    &:last-child,
    &:nth-last-child(2) {
      font-style: italic;
      font-weight: 200;
      color: ${({ theme }) => theme.projects.titleColor};
      background: ${({ theme }) => theme.projects.titleBg};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  @media (max-width: 1024px) {
    width: 720px;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 760px) {
    width: 80%;
    max-width: 460px;
  }
  @media (max-width: 520px) {
    transform: translate(-50%, -100%);
  }
`;

export const StyledStoriesWrapper = styled.article`
  top: 150px;
  max-width: 958px;
  margin: 0 auto;
  position: relative;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  height: min-content;
  @media (max-width: 1024px) {
    opacity: 0;
    height: fit-content;
    min-height: 856px;
    padding: 32px;
    display: flex;
    position: static;
  }
`;

export const StyledStories = styled.div`
  & > p {
    max-width: 852px;
    width: 60vw;
    margin: 0 auto;
    position: relative;
    text-align: center;
    transform: translateY(calc(40%));

    @media (max-width: 1240px) {
      transform: translateY(20%);
    }
    @media (max-width: 1134px) {
      transform: translateY(0);
    }

    & span {
      text-align: center;
      font-size: 38px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px;

      &:last-child {
        display: inline;
        padding-right: 10px;
        font-style: italic;
        font-weight: 200;
        color: ${({ theme }) => theme.projects.titleColor};
        background: ${({ theme }) => theme.projects.titleBg};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
`;

export const StyledStory = styled(motion.div) <{ index: number }>`
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: 80px;
  height: 80px;

  & img {
    height: auto;
    width: 100%;
    border-radius: 100%;
    overflow: hidden;

    &:not(.activeImage) {
      animation: ${({ index }) =>
    index
      ? `blink 3s linear infinite ${index}s`
      : "blink 3s linear infinite"};
      box-shadow: 0 0 60px 0 #3a6bff;
    }
  }

  & .activeImage {
    width: 112px;
    height: 112px;
    animation: scale 0.3s linear forwards;
  }

  & .video-miniature {
    border-radius: 100%;
    animation: ${({ index }) =>
    index
      ? `blink 3s linear infinite ${index}s`
      : "blink 3s linear infinite"};

    & video {
      object-fit: cover;
      border-radius: 100%;
    }
  }

  @keyframes blink {
    0% {
      box-shadow: 0 0 60px 0 #3a6bffff;
    }

    50% {
      box-shadow: 0 0 60px 0 #3a6bff00;
    }

    100% {
      box-shadow: 0 0 60px 0 #3a6bffff;
    }
  }

  @keyframes scale {
    from {
      width: 80px;
      height: 80px;
    }
    to {
      width: 280px;
      height: 280px;
    }
  }

  &.story_1 {
    top: -190px;
    right: 120px;
  }

  &.story_2 {
    top: -65px;
    right: -165px;
  }

  &.story_3 {
    top: 195px;
    right: -100px;
  }

  &.story_4 {
    top: 335px;
    right: 35px;
  }

  &.story_5 {
    top: 322px;
    left: 35px;
  }

  &.story_6 {
    top: 222px;
    left: -145px;
  }

  &.story_7 {
    top: -33px;
    left: -200px;
  }

  &.story_8 {
    top: -195px;
    left: -6px;
  }

  @media (max-width: 1440px) {
    &.story_1 {
      top: -14.2vw;
      right: 8.3vw;
    }

    &.story_2 {
      top: -4.5vw;
      right: -6.45vw;
    }

    &.story_3 {
      top: 13.54vw;
      right: -5.04vw;
    }

    &.story_4 {
      top: 24.65vw;
      right: 8.43vw;
    }

    &.story_5 {
      top: 23.36vw;
      left: 4.43vw;
    }

    &.story_6 {
      top: 12.42vw;
      left: -6vw;
    }

    &.story_7 {
      top: -1.29vw;
      left: -7vw;
    }

    &.story_8 {
      top: -13.54vw;
      left: 4.42vw;
    }
  }

  @media (max-width: 1148px) {
    &.story_1 {
      top: -14.2vw;
      right: 8.3vw;
    }

    &.story_2 {
      top: -4.5vw;
      right: 0.45vw;
    }

    &.story_3 {
      top: 13.54vw;
      right: 1.94vw;
    }

    &.story_4 {
      top: 24.65vw;
      right: 8.43vw;
    }

    &.story_5 {
      top: 23.36vw;
      left: 4.43vw;
    }

    &.story_6 {
      top: 12.42vw;
      left: 0;
    }

    &.story_7 {
      top: -1.29vw;
      left: 0;
    }

    &.story_8 {
      top: -13.54vw;
      left: 4.42vw;
    }
  }
`;

export const StyledVideoWrapper = styled(motion.div)`
  display: none;

  & .inner {
    display: flex;
    gap: 78px;
    @media (max-width: 1221px) {
      gap: 20px;
      justify-content: space-evenly;
    }
    @media (max-width: 1148px) {
      justify-content: center;
      gap: 66px;
    }
  }

  & .emptyDiv {
    width: 280px;
    height: 280px;
    flex-shrink: 0;
  }
`;

export const StyledVideo = styled.div`
  flex-shrink: 0;
  position: relative;
  height: 280px;
  width: 280px;

  &.mobile {
    margin-top: 4px;
    width: 232px;
    height: 232px;

    & div:last-child {
      left: 96px;
    }

    @media (max-width: 480px) {
      width: 312px;
      height: 312px;
      & div:last-child {
        left: 136px;
      }
    }
    @media (max-width: 400px) {
      width: 280px;
      height: 280px;
      & div:last-child {
        left: 120px;
      }
    }
  }

  & video {
    object-fit: cover;
    border-radius: 100%;
  }

  & div:last-child {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 16px;
    left: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    backdrop-filter: blur(7px);
    cursor: pointer;
    z-index: 1;
  }
`;

export const StyledProgress = styled.div<{
  active?: string;
}>`
  position: absolute;
  left: -2px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  z-index: 1;

  & .CircularProgressbar-path {
    stroke-linecap: butt;
    stroke: ${({ theme }) => theme.stories.progressBgActive};

    ${({ active }) =>
    active
      ? css`
                      transition: stroke-dashoffset 0.1s linear;
                    `
      : css`
                      transition: stroke-dashoffset 0s linear;
                    `}
  }

  & .CircularProgressbar-trail {
    stroke: ${({ theme }) => theme.stories.progressBg};
    stroke-width: 2.2;
  }
`;

export const StyledStoryTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  animation: 0.8s Fade ease;

  & div:first-child {
    position: relative;
  }

  & h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 4px;
    text-transform: capitalize;
  }

  h4 {
    margin: 0;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.projects.titleColor};
    background: ${({ theme }) => theme.projects.titleBg};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 1222px) {
    max-width: 52%;
    //justify-self: end;
  }
  @media (max-width: 1148px) {
    max-width: 50%;
  }
  @media (max-width: 1070px) {
    max-width: 44%;
  }

  @keyframes Fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const AnimatedMoveUpText = styled(motion.p) <{ index: number }>`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  gap: 3px;
  top: 4px;
  margin: 0;

  & span {
    font-size: ${({ index }) => (index === 7 || index === 4 ? `28px` : `22px`)};
    font-style: normal;
    font-weight: 500;
    line-height: ${({ index }) => (index === 7 || index === 4 ? `40px` : `32px`)};
    background: ${({ theme }) => theme.text};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const StoriesMobileWrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 790px;


  & .slider {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 610px;
    @media (max-width: 578px) {
      min-height: 710px;
    }
    @media (max-width: 530px) {
      min-height: 780px;
    }
    @media (max-width: 400px) {
      min-height: 820px;
    }
  }
}

& .thumbnail_testimonials {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  & .thumb_testimonials {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    aspect-ratio: 1/1;
    max-width: 10%;
    border-radius: 100%;
    background: transparent;

    & img {
      height: 100%;
      width: 100%;
      padding: 2px;
    }

    &.active {
      background: ${({ theme }) => theme.buttons.primary.hoverBorder};
    }
  }
}

& .button_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 30px;

  & p {
    width: 30px;
    display: block;
    color: ${({ theme }) => theme.stories.companyName};
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    transition: opacity 0.3s ease;

    &.button_light {
      border: 2px solid #E7EAF0;

      & svg path {
        stroke: #05070D;
      }
    }

    &.button_dark {
      border: 2px solid #fff;

      & svg path {
        stroke: #fff;
      }
    }

    &:disabled {
      opacity: 0.4;
    }

    & svg path {
      stroke-width: 1px;
    }

    &.left svg {
      transform: rotate(180deg);
    }
  }
`;

export const StyledActiveStory = styled.div`
  &.slide_testimonials {
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .title {
      display: flex;
      align-items: center;
      gap: 24px;
      margin-bottom: 40px;

      & img {
        width: 232px;
        height: 232px;
      }

      & h4 {
        font-size: 20px;
        line-height: 28px;
        text-transform: capitalize;
        margin: 0;
      }

      & p {
        font-size: 16px;
        line-height: 28px;
        margin-top: 2px;
        margin-bottom: 16px;
        text-transform: capitalize;
        color: ${({ theme }) => theme.stories.companyName};
      }

      @media (max-width: 660px) {
        flex-direction: column;
        margin-bottom: 0;
        & .names {
          align-self: start;
        }
      }
      @media (max-width: 480px) {
        & img {
          width: 312px;
          height: 312px;
        }
      }
      @media (max-width: 400px) {
        & img {
          width: 280px;
          height: 280px;
        }
      }
    }

    & .text {
      & .svgBlue path {
        fill: ${({ theme }) => theme.stories.quotesColor};
      }

      & p {
        margin-top: 12px;
        font-size: 24px;
        line-height: 32px;
        text-overflow: ellipsis;
      }

      @media (max-width: 480px) {
        & .svg {
          height: 29px;
        }

        & p {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
`;
