import styled from "styled-components";

export const StyledIntroduce = styled.section`
  height: 250vh;
  max-width: 1072px;
  margin: 0 auto;
  position: relative;
  padding: 10vh 24px 120px;

  @media (max-width: 600px) {
    padding: 0 24px 32px;
  }

  & div {
    top: calc(50vh - 100px);
    position: sticky;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    height: min-content;

    & p {
      margin: 0;
      font-size: 36px;
      font-weight: 500;
      line-height: 56px;
      opacity: 0;

      &:first-child,
      &:nth-child(2) {
        opacity: 1;
      }

      &:nth-child(3):before {
        content: ", ";
        position: relative;
        left: -5px;
      }

      &:nth-last-child(3) {        
        font-family: "Roboto Mono";        
        color: ${({ theme }) => theme.text === "#fff" ? '#64D2FF' : '#1953FF'};
        font-weight: 500; 
      }

      &:last-child {        
        font-style: italic; 
        font-weight: 200; 
        color: #1953FF;
      }

      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
      }
      @media (max-width: 600px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
`;
