import { ProjectType } from "../../types";
import chainJoes from "../../assets/images/portfolio/chain_joes.webp";
import chainJoes_mobile from "../../assets/images/portfolio/chain_joes_mobile.webp";
import ProjectHeaderText from "../../components/Project/ProjectHeaderText/ProjectHeaderText";
import ProjectPageCard from "../../components/Project/ProjectPageCard/ProjectPageCard";
import chainjoes_desktop from "../../assets/images/project/chainjoes/chainjoes-hero.png";
import chainjoes_welcome from "../../assets/images/project/chainjoes/welcome-cj.png";
import chainjoes_layout from "../../assets/images/project/chainjoes/page_layout.png";
import chainjoes_focus from "../../assets/images/project/chainjoes/chainjoes-focus.png";
import chainjoes_token from "../../assets/images/project/chainjoes/token-purchase.png";
import chainjoes_wallets from "../../assets/images/project/chainjoes/wallets.png";
import project_outcomes1_desktop from "../../assets/images/project/chainjoes/outcomes1.png";
import project_outcomes2_desktop from "../../assets/images/project/chainjoes/outcomes2.png";
import project_outcomes3_desktop from "../../assets/images/project/chainjoes/outcomes3.png";
import ProjectMainInfo from "../../components/Project/ProjectMainInfo/ProjectMainInfo";
import ProjectGoals from "../../components/Project/ProjectGoals/ProjectGoals";
import ProjectTextSection from "../../components/Project/ProjectTextSection/ProjectTextSection";
import ProjectStack from "../../components/Project/ProjectStack/ProjectStack";
import { FastapiIcon, PythonIcon, ReactIcon, ReduxIcon, ScssIcon, TsIcon, WagmiIcon } from "../../components/Icons/Icons";

export const chainjoesData: ProjectType = {
  name: "Web3 gaming project's website",
  href: 'web3-gaming-project',
  industry: "Blockchain",
  services: [
    "Technology Consulting",
    "UX/UI Design",
    "Web App Development",
    "Website Creation",
    "IT Support & Maintenance",
    "Branding and Content Strategy",
  ],
  color: "#fff",
  isFav: true,
  portfolio_image: chainJoes,
  portfolio_image_hash: 'L78sC9Lv0xQ;00qe#BpG5Ou2.SVF',
  portfolio_mobile: chainJoes_mobile,
  description: "",
  image: {
    color: "#114D9C",
    lower: chainJoes,
    middle: chainJoes,
    higher: chainJoes,
  },
  projectPage: [
    {
      component: ProjectHeaderText,
      props: {
        seoTitle: "Niwart Case Study | Web3 Gaming Project",
        seoDescription: "Explore how Niwart helped a Web3 Gaming Project integrate blockchain technologies, improve brand awareness, and modernize their web presence.",
        title: "Web3 gaming project's website",
        titleColor: `#44F4C3`,
        description:
          "Chain Joes is a pioneering GameFi project at the forefront of mobile gaming innovation. They combines cutting-edge technology with captivating gameplay experiences. Niwart helps Chain Joes to revolutionize the way players engage with mobile games.",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: chainjoes_desktop,
              tablet: chainjoes_desktop,
              mobile: chainjoes_desktop,
            },
          },
        ],
        maxWidth: 1920,
        padding: "0",
      },
    },
    {
      component: ProjectMainInfo,
      props: {
        header: ["Challenge"],
        text: [
          "The company's existing website was outdated, lacking the visual appeal needed to captivate modern audiences. Our team successfully developed a new website that not only simplified the user experience but also significantly enhanced its visual allure. The primary goal was to transform the website into a captivating landing page, acting as a dynamic marketing tool rather than a static information repository. The challenge lay in striking the right balance between simplicity and visual impact while effectively conveying the essence of the Chain Joes brand.",
        ],
        direction: "vertical",
        titleColor: `#44F4C3`,
        gap: "18px",
        maxWidth: "1264px",
        industry: "Blockchain",
        location: "Netherlands",
        services: [
          "Technology Consulting",
          "Web App Development",
          "Website Development",
          "IT Support & Maintenance",
          "UX/UI Design",
          "Branding and Content Strategy",
        ],
      },
    },
    {
      component: ProjectGoals,
      props: {
        headerText: ["Project", "Goals"],
        titleColor: `#44F4C3`,
        goals: [
          {
            title: ["Modernizing the Website Interface "],
            text: "Modernizing the website interface aims to simplify the user experience, implementing a more contemporary and user-friendly design using various graphic elements and animation effects. This involves enhancing navigation, visual elements, and overall aesthetics to create a more intuitive and engaging online environment for visitors.",
          },
          {
            title: ["Improving the Brand Awareness"],
            text: "The new website should ensure consistency in branding elements, utilize captivating visual graphics to simplify the Chain Joes introduction, and leverage social media integration to amplify brand messaging and foster community engagement.",
          },
          {
            title: ["Crypto Payments Integration"],
            text: "It enables real-time tracking of token purchases and ensures a transparent and frictionless transaction process for buyers, all within the familiar environment of the website.",
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Practical Introduction to the  ", "Chain Joes Brand"],
        text: [
          "We employed intricate graphic elements, banners, and animation effects to streamline the user experience and introduce the brand effectively.",
        ],
        titleColor: `#44F4C3`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: chainjoes_welcome,
              tablet: chainjoes_welcome,
              mobile: chainjoes_welcome,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 0px",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: chainjoes_layout,
              tablet: chainjoes_layout,
              mobile: chainjoes_layout,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Focus on Each Character with", " Striking Visuals "],
        titleColor: `#44F4C3`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "Each character's video was integrated to offer users an improved visual representation",
              align: "center",
              padding: "24px 100px 0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: chainjoes_focus,
              tablet: chainjoes_focus,
              mobile: chainjoes_focus,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Token Purchase Functionality ", ""],
        titleColor: `#0080FF`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "We incorporated the sale functionality for meme tokens, allowing users to purchase tokens using Ethereum directly from the website for each meme individually. The graphical representation situated beneath the form delineates the current quantity of tokens still accessible for acquisition.",
              align: "start",
              padding: "0",
              maxWidth: "455px",
              position: {
                desktop: "left",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: chainjoes_token,
              tablet: chainjoes_token,
              mobile: chainjoes_token,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Crypto Wallets integration", ""],
        titleColor: `#0080FF`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "The ability for users to integrate various cryptocurrency wallets with the website for token acquisition and subsequent claiming.",
              align: "start",
              padding: "0",
              maxWidth: "455px",
              position: {
                desktop: "right",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: chainjoes_wallets,
              tablet: chainjoes_wallets,
              mobile: chainjoes_wallets,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Project", "Outcomes"],
        titleColor: `#44F4C3`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: project_outcomes1_desktop,
              tablet: project_outcomes1_desktop,
              mobile: project_outcomes1_desktop,
            },
            text: {
              title: "Updated design concept",
              data: "The new design concept amalgamates contemporary design trends, intricate visual elements, the company's market positioning, and seamless brand integration.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: project_outcomes2_desktop,
              tablet: project_outcomes2_desktop,
              mobile: project_outcomes2_desktop,
            },
            text: {
              title: "Elevated Brand Recognition ",
              data: "It was achieved through strategic improvements in design, content, and user experience on the website, ultimately fostering a stronger connection with the target audience and greater visibility in the market. ",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: project_outcomes3_desktop,
              tablet: project_outcomes3_desktop,
              mobile: project_outcomes3_desktop,
            },
            text: {
              title: "Integration of Cryptocurrency payments",
              data: "Incorporating this feature enhanced user experience and broadened the scope of user interaction on the website.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Tech", "Stack"],
        titleColor: `#44F4C3`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectStack,
      props: {
        frontendStack: [
          {
            svg: ReactIcon,
            name: 'ReactJS'
          },
          {
            svg: ScssIcon,
            name: 'SCSS'
          },
          {
            svg: WagmiIcon,
            name: ''
          },
        ],
        backendStack: [
          {
            svg: PythonIcon,
            name: 'Python'
          },
          {
            svg: FastapiIcon,
            name: 'fastApi'
          },
        ]
      },
    },
  ]
}