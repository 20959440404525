import styled from "styled-components";

export const StyledMobileMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 32%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${({ theme }) => (theme.text === "#fff" ? "#05070D" : "#fff")};
  z-index: -1;
  transition: 1s ease;
  padding: 70px 24px 40px;
  opacity: 0;

  @media (max-height: 750px) {
    gap: 20%;
  }

  @media (max-width: 768px) {
    max-width: 100vw;
  }

  &.visible {
    opacity: 1;
    z-index: 20;
  }

  & .switcherWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    & p {
      text-transform: capitalize;
      margin: 12px;
    }
  }

  & nav {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    position: relative;

    & a {
      display: block;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media (max-width: 440px) {
      & button {
        width: 100%;
      }
    }
  }
`;
