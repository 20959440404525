import styled from "styled-components";
import { motion } from "framer-motion";
import { colorsPalette } from "../../styles/colorsPalette";

export const StyledContactUs = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  padding: 120px 20px;
  display: flex;
  justify-content: space-between;

  & h2 {
    margin: 0;
    font-weight: 400;
    font-size: 48px;
    line-height: 64px;

    & span {
      font-size: 48px;
      line-height: 64px;
      font-style: italic;
      font-weight: 250; 
      color: blue;
      background: ${({ theme }) => theme.services.TextBg};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-right: 10px;
    }

    
    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;

      span {       
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 0 20px;
    margin: 64px 0 0;
  }
  @media (max-width: 980px) {
    padding: 48px 20px;
    flex-direction: column;
    justify-content: center;
    gap: 48px;
  }
`;

export const StyledContactUsWrapper = styled(motion.article)`
  min-height: 100vh;
  position: relative;
`;

export const StyledContactUsBg = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  background: ${({ theme }) => theme.contactUs.bgDesktop};

  @media (max-width: 480px) {
    background-position: 50% 50%;
    background-size: 200vw;
  }
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 584px;
  @media (max-width: 980px) {
    max-width: inherit;
    gap: 48px;
  }

  @media (max-width: 620px) {
    gap: 32px;
  }
`;

export const StyledForm = styled.div`
  & h3 {
    margin-left: 4px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  & > div {
    margin-left: 1px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  & form {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;

    & .basic_info {
      width: 100%;
      display: flex;
      gap: 24px;
      justify-content: space-between;
    }

    & .checkbox_wrapper {
      display: flex;
      gap: 8px;
      align-items: start;
      user-select: none;

      & input {
        margin-top: 6px;
      }

      & label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;

        & a {
          font-size: 14px;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }
  }

  & button {
    margin: 64px 4px 4px;
  }

  @media (max-width: 960px) {
    & button {
      margin: 32px 4px 4px;
    }
  }

  @media (max-width: 640px) {
    gap: 48px;
    & form {
      gap: 32px;
    }

    & .basic_info {
      flex-direction: column;
      gap: 32px;
    }
  }
  @media (max-width: 440px) {
    & button {
      width: 96%;
    }
  }
`;

export const StyledFileInputWrapper = styled.div<{ $isError: boolean }>`
  position: relative;

  & input {
    width: 0;
  }

  & > label {
    min-height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.contactUs.fileUpload.background};
    background-image: ${({ theme, $isError }) =>
    `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23${$isError
      ? colorsPalette.red["800"].slice(1, 7)
      : theme.contactUs.fileUpload.borderColor
    }' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e")`};
    border-radius: 16px;

    & p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: ${({ theme }) => theme.contactUs.fileUpload.text};

      & span {
        color: ${({ theme }) => theme.contactUs.fileUpload.highLightText};
      }
    }

    label {
      cursor: pointer;
    }
  }

  & > div {
    display: flex;
    justify-content: space-between;

    @media (max-width: 415px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

export const StyledFileHelperText = styled.p<{ $isError: boolean }>`
  color: ${({ theme, $isError }) =>
    $isError ? colorsPalette.red["800"] : theme.contactUs.fileUpload.text};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 415px) {
    margin: 8px 0 0;
  }
`;

export const StyledFile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.text}`};
  padding: 8px 14px;

  & div {
    & h4 {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }

    & p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }

  & svg:last-child {
    margin-left: auto;

    path {
      fill: ${({ theme }) => `${theme.text}`}
    }
  }

  .revert {
    filter: invert();
  }
`;

export const StyledFileError = styled.div`
  position: absolute;
  bottom: -12px;
  left: 0;
  color: red;
`;

export const StyledModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000aa;
  backdrop-filter: blur(10px);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade 1s ease forwards;

  & p {
    display: block;
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    margin: 0;
    transform: translateY(-50%);
  }

  @keyframes fade {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    & p {
      text-align: center;
      padding: 0 16px;
      font-size: 24px;
      line-height: 32px;
    }
  }
`;
