import styled from "styled-components";

export const StyledRoundTag = styled.div`
  cursor: pointer;
  position: relative;
  width: fit-content;
  padding: 7px 12px 9px;
  border-radius: 100px;
  border: ${({ theme }) => theme.tag.border};
  background: ${({ theme }) => theme.tag.bg};
  font-size: 14px;
  line-height: 20px;
  margin: 1.6px;
  user-select: none;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: -1.6px;
    right: -1.6px;
    bottom: -1.6px;
    left: -1.6px;
    z-index: -1;
    margin: -1px;
    border-radius: inherit;
    background: ${({ theme }) => theme.buttons.primary.hoverBorder};
    opacity: 0;
    transition: 0.4s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.tag.clicked};

    &:before {
      opacity: 1;
    }
  }

  &.tag-clicked {
    background: ${({ theme }) => theme.buttons.primary.hoverBorder};
    color: ${({ theme }) => theme.tag.clicked};
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;

export const StyledRoundTagOutlined = styled.div<{$color?: string}>`
  position: relative;
  width: fit-content;
  padding: 7px 12px 9px;
  border-radius: 100px;
  border: ${({ theme, $color }) => `0.7px solid ${$color || theme.tag.outlineColor}`};
  background: transparent;
  font-size: 14px;
  line-height: 20px;
  margin: 1.6px;
  user-select: none;
  z-index: 1;
`