import styled from "styled-components";

export const StyledPrivacyPage = styled.section`
  max-width: 808px;
  width: 100%;
  margin: 0 auto;
  padding-top: 144px;

  .upd {
    color: #00A3FF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; 
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;
  }

  h1.title {
    color: ${({ theme }) => (theme.body === "#fff" ? "#000" : "#fff")};
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    line-height: 64px;
    margin: 8px 0 64px;
  }

  h2 {
    color: ${({ theme }) => (theme.body === "#fff" ? "#000" : "#fff")};
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin: 64px 0 16px;
  }

  h3 {
    color: ${({ theme }) => (theme.body === "#fff" ? "#000" : "#fff")};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  p {
    color: ${({ theme }) => (theme.body === "#fff" ? "#000" : "#fff")};
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
  }

  ol {
    padding-left: 16px;
  }

  @media screen {
    padding: 105px 24px 0;

    .upd {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px; 
      letter-spacing: 4px;
      text-transform: uppercase; 
    }

    h1.title {
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
      margin-bottom: 48px;
    }

    h2 {
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 48px;
    }

    h3 {
      font-size: 22px;
      font-weight: 400;
      line-height: 28px; 
      margin-top: 40px;
    }
  }
`