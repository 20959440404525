import React, { useEffect, useRef, useState } from "react";
import { StyledLogoWrapper, StyledPartners, StyledTitle } from "./styled";

import useWindowSize from "../../utils/useWindowSize";
import {
  CKLogoDark,
  CKLogoDarkHover,
  CKLogoLight,
  CKLogoLightHover,
  CloudguardLogoDark,
  CloudguardLogoDarkHover,
  CloudguardLogoLight,
  CloudguardLogoLightHover,
  DrScharLogoDark,
  DrScharLogoDarkHover,
  DrScharLogoLight,
  DrScharLogoLightHover,
  NyLogoDark,
  NyLogoDarkHover,
  NyLogoLight,
  NyLogoLightHover,
  SmartcoLogoDark,
  SmartcoLogoDarkHover,
  SmartcoLogoLight,
  SmartcoLogoLightHover,
  SSnCLogoDark,
  SSnCLogoDarkHover,
  SSnCLogoLight,
  SSnCLogoLightHover,
  The3rdkLogoDark,
  The3rdkLogoDarkHover,
  The3rdkLogoLight,
  The3rdkLogoLightHover,
  TrustedAdvLogoDark,
  TrustedAdvLogoDarkHover,
  TrustedAdvLogoLight,
  TrustedAdvLogoLightHover,
} from "../Icons/Icons";
import { useTheme } from "styled-components";
import { motion, Variants } from "framer-motion";
import { useIsInViewport } from "../../utils/useIsInViewPort";

const Partners = () => {
  const { width } = useWindowSize();
  const theme = useTheme();
  const contaiterRef = useRef(null)
  const [hasTriggered, setHasTriggered] = useState(false)
  const isInViewPort = useIsInViewport(contaiterRef, 0.8)
  const partnersDark = [
    { logo: SSnCLogoDark, hover: SSnCLogoDarkHover },
    { logo: SmartcoLogoDark, hover: SmartcoLogoDarkHover },
    { logo: DrScharLogoDark, hover: DrScharLogoDarkHover },
    { logo: CloudguardLogoDark, hover: CloudguardLogoDarkHover },
    { logo: TrustedAdvLogoDark, hover: TrustedAdvLogoDarkHover },
    { logo: The3rdkLogoDark, hover: The3rdkLogoDarkHover },
    { logo: NyLogoDarkHover, hover: NyLogoDark },
    { logo: CKLogoDark, hover: CKLogoDarkHover },
  ];
  const partnersLight = [
    { logo: SSnCLogoLightHover, hover: SSnCLogoLight },
    { logo: SmartcoLogoLightHover, hover: SmartcoLogoLight },
    { logo: DrScharLogoLightHover, hover: DrScharLogoLight },
    { logo: CloudguardLogoLightHover, hover: CloudguardLogoLight },
    { logo: TrustedAdvLogoLightHover, hover: TrustedAdvLogoLight },
    { logo: The3rdkLogoLightHover, hover: The3rdkLogoLight },
    { logo: NyLogoLightHover, hover: NyLogoLight },
    { logo: CKLogoLightHover, hover: CKLogoLight },
  ];

  const animVariants = (ind: number) => ({
    hidden: {
      opacity: 0,
      scale: 0
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: ind * 0.049,
        type: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
        duration: 0.5,
      },
    },
  });

  useEffect(() => {
    if (isInViewPort) {
      setHasTriggered(true)
    }
  }, [isInViewPort]);

  return (
    <StyledPartners>
      <StyledTitle>
        <span>Partnering</span> with industry Leaders for
        <span> mutual Growth</span>
      </StyledTitle>
      <p>
        We empower businesses to create a lasting imprint. Through innovation,
        we help transform ideas into impactful, user-friendly solutions,
        affecting industries and users globally
      </p>
      <StyledLogoWrapper
        ref={contaiterRef}

      >
        {theme.text === "#fff"
          ? partnersDark.map(({ logo, hover }, i) => (
            <motion.div className={"logo"} key={i} animate={hasTriggered || isInViewPort ? 'visible' : 'hidden'}
              variants={animVariants(i)}>
              {hover()}
              {logo()}
            </motion.div>
          ))
          : partnersLight.map(({ logo, hover }, i) => (
            <motion.div className={"logo"} key={i} animate={hasTriggered || isInViewPort ? 'visible' : 'hidden'}
              variants={animVariants(i)}>
              {hover()}
              {logo()}
            </motion.div>
          ))}
      </StyledLogoWrapper>
    </StyledPartners>
  );
};

export default Partners;
