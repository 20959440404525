import React, { Dispatch, SetStateAction, useEffect, useRef, useState, } from "react";
import { StyledProgress, StyledVideo } from "../styled";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { CircularProgressbar } from "react-circular-progressbar";
import { VolumeLogo, VolumeOffLogo } from "../../Icons/Icons";
import { StoryType } from "../../../types";
import { VideoObjType } from "../Stories/Stories";
import useWindowSize from "../../../utils/useWindowSize";

type PropsType = {
  story: StoryType;
  isActive: boolean;
  setVideoObj: Dispatch<SetStateAction<VideoObjType>>;
  videoObj: VideoObjType;
};

const VideoMiniatureMobile = ({
  story,
  videoObj,
  setVideoObj,
  isActive,
}: PropsType) => {
  const { width } = useWindowSize();
  const playerRef = useRef<ReactPlayer | null>(null);
  const [isUnMutedBefore, setIsUnMutedBefore] = useState(false);
  const [valueEnd, setValueEnd] = React.useState(0);

  const onStartHandler = () => {
    let ref = playerRef.current as ReactPlayer | null;

    if (ref) {
      let duration = ref.getDuration();
      setVideoObj((prev) => ({ ...prev, length: duration, isPlaying: true }));
    }
  };
  const onProgressHandler = (e: ReactPlayerProps) => {
    setValueEnd(e.played * 100);
  };
  const onEndHandler = () => {
    setVideoObj((prev) => ({ ...prev, length: 0, isPlaying: false }));
    setValueEnd(0);
    setTimeout(() => {
      setVideoObj((prev) => ({ ...prev, isPlaying: true }));
    }, 300);
  };
  const toggleMuted = () => {
    setVideoObj((prev) => ({ ...prev, isMuted: !prev.isMuted }));
    if (videoObj.isMuted) {
      !isUnMutedBefore && playerRef?.current?.seekTo(0, "seconds");
    }
    setIsUnMutedBefore(true);
  };
  const videoSizeCalc = () => {
    if (width > 480) {
      return 232;
    } else if (width > 400) {
      return 312;
    } else {
      return 280;
    }
  };
  const videoSize = videoSizeCalc();

  useEffect(() => {
    let activePlayer = playerRef?.current
    if (isActive) {
      onStartHandler();
    }
    return () => {
      setVideoObj({ isPlaying: false, length: 0, isMuted: true });
      setValueEnd(0);
      activePlayer?.seekTo(0, "seconds");
    };
    // eslint-disable-next-line
  }, [isActive]);

  const renderItem = () => {
    return story?.videoUrl ? (
      <StyledVideo className={"mobile"}>
        <ReactPlayer
          className={"video"}
          ref={playerRef}
          url={story.videoUrl}
          width={videoSize}
          controls={false}
          playsinline
          height={videoSize}
          playing={videoObj.isPlaying && isActive}
          onProgress={onProgressHandler}
          progressInterval={100}
          onEnded={onEndHandler}
          volume={0.5}
          muted={videoObj.isMuted}
          config={{
            file: {
              attributes: { poster: story.imageUrl },
            },
          }}
        />
        <StyledProgress active={videoObj.isPlaying ? "playing" : undefined}>
          <CircularProgressbar value={valueEnd} strokeWidth={2.5}/>
        </StyledProgress>
        <div onClick={toggleMuted}>
          {videoObj.isMuted ? <VolumeOffLogo/> : <VolumeLogo/>}
        </div>
      </StyledVideo>
    ) : (
      <img src={story.imageMobileUrl} alt={story.company}/>
    );
  };

  return renderItem();
};

export default VideoMiniatureMobile;
