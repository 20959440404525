import {
  ProjectPageCardItem,
  ProjectPageCardText,
  ProjectPageCardWrapper,
} from "./styled";
import { ProjectMobileCardType, TextPositionType } from "../../../types";
import useWindowSize from "../../../utils/useWindowSize";

type PropsType = {
  items: Array<ProjectMobileCardType>;
  maxWidth: number;
  padding?: string;
};

const ProjectPageCard = ({ items, maxWidth, padding }: PropsType) => {
  const { width } = useWindowSize();
  const renderCardItem = (item: ProjectMobileCardType, index: number) => {
    const renderText = () => {
      if (item.text) {
        return (
          <ProjectPageCardText
            $padding={item.text.padding}
            $textAlign={item.text.align}
            $minWidth={item.text.minWidth}
            $maxWidth={item.text?.maxWidth}
          >
            {item.text.title && <h3>{item.text.title}</h3>}
            <p
              style={{
                fontSize: item.text.title ? 14 : 20,
                lineHeight: item.text.title ? "24px" : "28px",
              }}
            >
              {item.text.data}
            </p>
          </ProjectPageCardText>
        );
      }
    };
    const renderImage = () => {
      if (width < 481) {
        return (
          <div>
            <img src={item.image.mobile} alt={`mobile project card`} />
          </div>
        );
      } else if (width < 769) {
        return (
          <div>
            <img src={item.image.tablet} alt="tablet project card" />
          </div>
        );
      }
      return (
        <div>
          <img src={item.image.desktop} alt="desktop project card" />
        </div>
      );
    };
    const getPositionStyle = () => {
      const generateObj = (position: TextPositionType) => {
        const possObj: { [key: string]: any } = {
          left: {
            width: "450px",
            flexDirection: "row",
            alignItems: "center",
            gap: 64,
          },
          right: {
            width: "450px",
            flexDirection: "row-reverse",
            alignItems: "center",
            gap: 64,
          },
          top: {
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
          },
          bottom: {
            flexDirection: "column-reverse",
            alignItems: "center",
            gap: 4,
          },
        };
        return possObj[position];
      };
      if (item.text) {
        if (width < 481) {
          return generateObj(item.text.position.mobile);
        } else if (width < 769) {
          return generateObj(item.text.position.tablet);
        }
        return generateObj(item.text.position.desktop);
      }
    };

    return (
      <ProjectPageCardItem
        key={index}
        $position={getPositionStyle()}
        $basis={items.length > 1 ? `${100 / items.length}%` : "auto"}
      >
        {renderText()}
        {renderImage()}
      </ProjectPageCardItem>
    );
  };

  return (
    <ProjectPageCardWrapper $maxWidth={maxWidth} $padding={padding}>
      {items.map((el, index) => {
        return renderCardItem(el, index);
      })}
    </ProjectPageCardWrapper>
  );
};
export default ProjectPageCard;
