import styled, { css } from "styled-components";

export const ProjectPageCardWrapper = styled.div<{
  $maxWidth: number;
  $padding?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 24px;
  max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
  margin: 0 auto;
  padding: ${({ $padding }) => ($padding ? $padding : "0 20px")};

  @media (max-width: 1280px) {
    padding: ${({ $maxWidth }) => ($maxWidth >= 1440 ? "0" : "0 24px")};
  }
  @media (max-width: 690px) {
    flex-direction: column;
  }
`;

export const ProjectPageCardItem = styled.div<{
  $position?: {
    width?: string;
    flexDirection: string;
    alignItems: string;
    gap: number;
  };
  $basis: string;
}>`
  display: flex;
  flex-basis: ${({ $basis }) => $basis};
  flex-direction: ${({ $position }) =>
    $position?.flexDirection ? $position.flexDirection : "row"};
  align-items: ${({ $position }) =>
    $position?.alignItems ? $position.alignItems : "center"};
  gap: ${({ $position }) => ($position?.gap ? $position.gap : 10)}px;
  flex-shrink: 1;

  ${({ $position }) =>
    $position?.width &&
    css`
      & > p {
        width: ${$position.width};
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    `}
  & img {
    width: 100%;
    height: auto;
  }
`;

export const ProjectPageCardText = styled.div<{
  $textAlign: "start" | "center" | "end";
  $padding: string;
  $minWidth?: number;
  $maxWidth?: string;
}>`
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : "100%")};
  min-width: ${({ $minWidth }) => ($minWidth ? `${$minWidth}px` : "auto")};
  padding: ${({ $padding }) => $padding};
  text-align: ${({ $textAlign }) => $textAlign};

  & h3 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  & p {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  @media (max-width: 768px) {
    text-align: center;
    max-width: 100%;
  }
  @media (max-width: 1028px) {
    padding: 0;
  }
`;
