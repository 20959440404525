import styled from "styled-components";
import { motion } from "framer-motion";
import mask from "../../assets/icons/mask_dark.svg";

export const StyledHero = styled.section`
  max-width: 2100px;
  margin: 0 auto;
  height: calc(0.5625 * 100vw);
  max-height: 1080px;
  display: flex;
  align-items: center;
  overflow: hidden;
  top: -50px;

  @media (max-width: 1600px) {
    min-height: 1000px;
  }

  @media (max-width: 540px) and (min-height: 740px) {
    top: 0;
  }

  & > div:nth-child(2) {
    position: absolute;
    z-index: 1;
    -webkit-mask-image: url(${mask});
    background-color: ${({ theme }) => theme.heroText.maskBg};
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 10px;
  }


  @media (max-width: 870px) {
    min-height: 100vh;
    height: max-content;
    @media (min-width: 610px) {
      overflow: visible;
    }
  }
  @media (max-width: 524px) {
    overflow: hidden;
    bottom: 0;
  }

`;

export const StyledHeroBackground = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;

  @media (max-width: 768px){    
    background: ${({ theme }) => (theme.body === "#fff" ? "none" : "linear-gradient(0deg, rgba(5, 7, 13, 0.60) 0%, rgba(5, 7, 13, 0.60) 100%)")};; 
  }

  & video {
    position: absolute;
    top: 0;
    height: calc(0.5625 * 100vw);
    z-index: -1;

    @media (max-width: 1440px) {
      min-height: 918px;
    }
    @media (max-width: 870px) {
      width: 100%;
      max-height: initial;
      height: initial;
      min-height: initial;
      right: 0;
      left: 0;
    }
    @media (max-width: 542px) {
      max-height: inherit;
      width: 100%;
      height: 90vh;
      object-fit: cover;
    }
    /* @media (max-width: 430px) {
      width: 120vw;
      transform: translate(-10vw, -5%);

    }
    @media (max-width: 430px)  and (min-height: 740px) {
      width: calc(100vw + 26vh);
      transform: translate(-13vh, -5%);
    }
    @media (max-width: 400px) {
      width: calc(100% + 40%);
      transform: translate(-14%, -5%);
    }
    @media (max-width: 360px) {
      width: calc(100% + 50%);
      transform: translate(-17%, -5%);
    } */
  }

  .video-light {
    right: 0;
    max-width: inherit;
    display: ${({ theme }) => (theme.body === "#fff" ? "block" : "none")};
  }

  .video-dark {
    display: ${({ theme }) => (theme.body !== "#fff" ? "block" : "none")};
    right: -5px;
    top: 0;
  }
`;

export const TextWrapper = styled(motion.div)`
  height: 100%;
  width: 100vw;
  display: flex;
  position: relative;
  top: -50px;

  &:nth-child(2) {
   & > div {
      transform: translateY(-7.5%);  
      height: 900px;
      
      @media (max-width: 1800px) {
        height: 805px;
      }
      /* height: 1000px;
      @media (min-width: 2000px) { 
      height: 950px;
      transform: translateY(-5.5%);     
      }
      @media (max-width: 1800px) { 
      transform: translateY(-9.5%);   
      height: 950px;  
      } */
    } 

    @media (max-width: 768px) {
      display: none;
    }
  }

  & > div {
    width: 802px;
    position: relative;
    left: 17vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 870px) {
    height: 100vh;
    width: 100%;
    top: 0;
  }

  @media (max-width: 1100px) {
    & > div {
      width: 96%;
      top: 232px;
      left: 10px;
      align-self: start;
    }
  }
  @media (max-width: 640px) {
    & > div {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 22vh;
    }
  }
`;

export const StyledPhraseWrapper = styled.div<{ $alignself?: string }>`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: ${({ $alignself }) => ($alignself ? $alignself : "flex-start")};
  overflow: hidden;

  & span {
    font-size: 64px;
    line-height: 80px;
    padding-right: 8px;
  }

  button {
    z-index: 1;
    padding: 16px 24px;
    border: 1.5px solid var(--Neo-Gradient, #3A6BFF); 
    background: transparent;
    color: ${({ theme }) => (theme.body === "#fff" ? "#000" : "#fff")};
    margin-bottom: 5px;    
    background: ${({ theme }) => (theme.body === "#fff" ? "rgba(255, 255, 255, 0.10)" : "#05070D")};
    backdrop-filter: blur(3px); 

    &:hover {
      border: 1.5px solid var(--Neo-Gradient, #3A6BFF) !important; 
      background: ${({ theme }) => (theme.body === "#fff" ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.10)")} !important;
    }

    svg {
      path {
        fill: ${({ theme }) => (theme.body === "#fff" ? "#000" : "#fff")};
      }
    }

    &::after {
      top: 48%;
    }

    &:hover:enabled::after {
      transform: translateY(-50%) rotateX(0deg);
    }
  } 

  @media (max-width: 760px) {
    align-self: center;
    flex-direction: column;
    gap: 0;

    span {
      width: 100%;
      font-size: 40px;
      font-weight: 400;
      line-height: 52px;
      text-align: center;
    }

    button {
      margin-top: 44px;
    }
  }
`;

export const StyledInvertedText = styled(motion.span)`
  font-style: italic;
  color: blue;
  cursor: none;
  background: ${({ theme }) => theme.heroText.bg};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  
  @media (max-width: 760px) {
    display: none;
  }
`;

export const StyledHeroBottomText = styled.div`
  margin-top: 193px;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;

  span {
    font-family: Inter; 
    ${({ theme }) => theme.text === "#fff" ? `
    color: blue;
    background: var(--Neo-Gradient, linear-gradient(88deg, #3A6BFF 1.41%, #00C2FF 98.44%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    ` : 'color: #1953FF;'};    
    font-size: 32px;
    font-style: italic;
    font-weight: 200;
    line-height: 40px; 
  }

  
  @media (max-width: 760px) {
    margin-top: 140px;
    text-align: center;
    font-size: 26px; 
    line-height: 32px; 
  }
`;
