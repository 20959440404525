import React, { useState } from "react";
import { AnimatedMoveUpText, StyledStories, StyledStoryTextWrapper, StyledVideoWrapper, } from "../styled";
import { storiesData } from "../../../data/storiesData";
import "react-circular-progressbar/dist/styles.css";
import { useTheme } from "styled-components";
import ChoseStory from "./ChoseStory";
import { StoriesAnimType } from "../../../types";
import StoryMiniature from "./StoryMiniature";

export type VideoObjType = {
  isPlaying: boolean;
  isMuted: boolean;
  length: number;
};

const Stories = () => {
  const theme = useTheme();
  const [activeStory, setActiveStory] = useState<null | number>(null);
  const [videoObj, setVideoObj] = useState<VideoObjType>({
    isPlaying: false,
    isMuted: true,
    length: 0,
  });

  const storyClickHandler = (i: number | null) => {
    setActiveStory(i);
  };
  const animations: StoriesAnimType = {
    spanAnimation: {
      active: {
        opacity: 1,
        transition: {
          duration: 1.5,
        },
      },
      hidden: {
        opacity: 0,
        transition: {
          duration: 1,
        },
      },
    },
    spanChildrenAnimation: {
      active: {
        opacity: 1,
      },
      hidden: {
        opacity: 0,
        transition: {
          delay: 0.5,
          duration: 1,
        },
      },
    },
    chosenStory: (activeStory: number | null) => {
      return {
        active: {
          display: "flex",
          transition: {
            opacity: 1,
            duration: 1.5,
            delay: activeStory !== null ? 0 : 1.8,
          },
        },
        hidden: {
          opacity: 0.3,
          display: "none",
        },
      };
    },
    paragraphAnimation: {
      active: {
        display: "block",
      },
      hidden: {
        transition: {
          delay: activeStory !== null ? 0 : 1.5,
        },
      },
    },
    textSpanAnim: (i: number) => ({
      complete: {
        backgroundColor: [
          theme.text,
          theme.stories.highLightText,
          theme.stories.highLightText,
          theme.stories.completeText,
        ],
        transition: {
          ease: "easeInOut",
          duration: 5,
          delay: i / 2,
        },
      },
    }),
  };

  return (
    <StyledStories>
      {storiesData.map((story, i) => (
        <StoryMiniature
          key={i}
          isActive={activeStory === i}
          className={`story_${i + 1}`}
          story={story}
          setVideoObj={setVideoObj}
          videoObj={videoObj}
          index={i}
          onClick={storyClickHandler}
        />
      ))}
      {
        <StyledVideoWrapper
          animate={activeStory !== null ? "active" : "hidden"}
          variants={animations.chosenStory(activeStory)}
        >
          {storiesData.map((el, i) => {
            return i === activeStory && videoObj.isPlaying ? (
              <div key={i} className={"inner"}>
                <div className={"emptyDiv"}></div>
                <StyledStoryTextWrapper>
                  <div>
                    <AnimatedMoveUpText index={i}>
                      <span>{el.text}</span>
                    </AnimatedMoveUpText>
                  </div>
                  <div>
                    <h3>{el.name}</h3>
                    <h4>{el.company}</h4>
                  </div>
                </StyledStoryTextWrapper>
              </div>
            ) : (
              <React.Fragment key={i}></React.Fragment>
            );
          })}
        </StyledVideoWrapper>
      }
      <ChoseStory activeStory={activeStory} animations={animations}/>
    </StyledStories>
  );
};

export default Stories;
