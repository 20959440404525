import { useEffect, useState } from "react";

function useHasViewedApp() {
  const [hasViewed, setHasViewed] = useState(false);

  useEffect(() => {
    const viewed = sessionStorage.getItem("hasViewedApp");
    if (viewed) {
      setHasViewed(true);
      return;
    } else {
      const header = document.querySelector("header");
      const section = document.querySelector("section");
      header && header.classList.add("on-load");
      section && section.classList.add("on-load");

      setTimeout(() => {
        sessionStorage.setItem("hasViewedApp", "true");
        setHasViewed(true);
        // header && header.classList.remove("on-load");
        // section && section.classList.remove("on-load");
      }, 3.3 * 1000);
    }
  }, []);

  return hasViewed;
}

export default useHasViewedApp;
