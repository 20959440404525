import { ReactComponent as Moon } from "../../assets/icons/moon.svg";
import { ReactComponent as Sun } from "../../assets/icons/sun.svg";
import { ReactComponent as Arrow } from "../../assets/icons/east.svg";
import { ReactComponent as NiwartLogo } from "../../assets/icons/niwart_logo.svg";
import { StyledNiwartLogo } from "./styled";
import { ReactComponent as NeonSvg } from "../../assets/icons/right-arrow.svg";
import { ReactComponent as ModlaSvg } from "../../assets/icons/modla-card.svg";
import { ReactComponent as SmartcoSvgDark } from "../../assets/icons/partners/smartco_dark.svg";
import { ReactComponent as SSnCSvgDark } from "../../assets/icons/partners/ssc_dark.svg";
import { ReactComponent as DrScharCSvgDark } from "../../assets/icons/partners/drSchar_dark.svg";
import { ReactComponent as CloudguardSvgDark } from "../../assets/icons/partners/cloudgard_dark.svg";
import { ReactComponent as TrustedAdvSvgDark } from "../../assets/icons/partners/trAdvisor_dark.svg";
import { ReactComponent as The3rdSvgDark } from "../../assets/icons/partners/the3rd_dark.svg";
import { ReactComponent as NySvgDark } from "../../assets/icons/partners/ny_dark.svg";
import { ReactComponent as CKSvgDark } from "../../assets/icons/partners/ck_dark.svg";
import { ReactComponent as SmartcoSvgDarkHover } from "../../assets/icons/partners/smartco_dark-hover.svg";
import { ReactComponent as SSnCSvgDarkHover } from "../../assets/icons/partners/ssc_dark-hover.svg";
import { ReactComponent as DrScharCSvgDarkHover } from "../../assets/icons/partners/drSchar_dark-hover.svg";
import { ReactComponent as CloudguardSvgDarkHover } from "../../assets/icons/partners/cloudgard_dark-hover.svg";
import { ReactComponent as TrustedAdvSvgDarkHover } from "../../assets/icons/partners/trAdvisor_dark-hover.svg";
import { ReactComponent as The3rdSvgDarkHover } from "../../assets/icons/partners/the3rd_dark-hover.svg";
import { ReactComponent as CKSvgDarkHover } from "../../assets/icons/partners/ck_dark-hover.svg";
import { ReactComponent as NySvgDarkHover } from "../../assets/icons/partners/ny_dark-hover.svg";

import { ReactComponent as SmartcoSvgLight } from "../../assets/icons/partners/smartco_light.svg";
import { ReactComponent as SSnCSvgLight } from "../../assets/icons/partners/ssc_light.svg";
import { ReactComponent as DrScharCSvgLight } from "../../assets/icons/partners/drSchar_light.svg";
import { ReactComponent as CloudguardSvgLight } from "../../assets/icons/partners/cloudgard_light.svg";
import { ReactComponent as TrustedAdvSvgLight } from "../../assets/icons/partners/trAdvisor_light.svg";
import { ReactComponent as The3rdSvgLight } from "../../assets/icons/partners/the3rd_light.svg";
import { ReactComponent as CKSvgLight } from "../../assets/icons/partners/ck_light.svg";
import { ReactComponent as NySvgLight } from "../../assets/icons/partners/ny_light.svg";

import { ReactComponent as SmartcoSvgLightHover } from "../../assets/icons/partners/smartco_light-hover.svg";
import { ReactComponent as SSnCSvgLightHover } from "../../assets/icons/partners/ssc_light-hover.svg";
import { ReactComponent as DrScharCSvgLightHover } from "../../assets/icons/partners/drSchar_light-hover.svg";
import { ReactComponent as CloudguardSvgLightHover } from "../../assets/icons/partners/cloudgard_light-hover.svg";
import { ReactComponent as TrustedAdvSvgLightHover } from "../../assets/icons/partners/trAdvisor_light-hover.svg";
import { ReactComponent as The3rdSvgLightHover } from "../../assets/icons/partners/the3rd_light-hover.svg";
import { ReactComponent as CKSvgLightHover } from "../../assets/icons/partners/ck_light-hover.svg";
import { ReactComponent as NySvgLightHover } from "../../assets/icons/partners/ny_light-hover.svg";

import { ReactComponent as RefmetrixSvg } from "../../assets/icons/refmetrix-card.svg";
import { ReactComponent as Volume } from "../../assets/icons/volume.svg";
import { ReactComponent as VolumeOff } from "../../assets/icons/volume_off.svg";
import { ReactComponent as Quotes } from "../../assets/icons/quotes.svg";
import { ReactComponent as Social_b } from "../../assets/icons/social_b.svg";
import { ReactComponent as Social_c } from "../../assets/icons/social_c.svg";
import { ReactComponent as Social_v } from "../../assets/icons/social_v.svg";
import { ReactComponent as Social_in } from "../../assets/icons/social_in.svg";
import { ReactComponent as Social_b_light } from "../../assets/icons/social-b-light.svg";
import { ReactComponent as Social_c_light } from "../../assets/icons/social-c-light.svg";
import { ReactComponent as Social_v_light } from "../../assets/icons/social-v-light.svg";
import { ReactComponent as Social_in_light } from "../../assets/icons/social-in-light.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Tap } from "../../assets/icons/clickable.svg";
import { ReactComponent as TopItComp_light } from "../../assets/icons/top-it-services-companies-light.svg";
import { ReactComponent as TopItComp_dark } from "../../assets/icons/top-it-services-companies-dark.svg";
import { ReactComponent as FileSvg } from "../../assets/icons/file.svg";
import { ReactComponent as ContactSvg } from "../../assets/icons/contact.svg";
import { ReactComponent as ReactSvg } from "../../assets/icons/react.svg";
import { ReactComponent as TsSvg } from "../../assets/icons/ts.svg";
import { ReactComponent as ReduxSvg } from "../../assets/icons/redux.svg";
import { ReactComponent as PythonSvg } from "../../assets/icons/python.svg";
import { ReactComponent as NodeSvg } from "../../assets/icons/node.svg";
import { ReactComponent as FirebaseSvg } from "../../assets/icons/firebase.svg";
import { ReactComponent as VictoryNativeSvg } from "../../assets/icons/victory_native.svg";
import { ReactComponent as JavaScriptSvg } from "../../assets/icons/jsicon.svg";
import { ReactComponent as CssSvg } from "../../assets/icons/cssicon.svg";
import { ReactComponent as WordpressSvg } from "../../assets/icons/wpicon.svg";
import { ReactComponent as PhpSvg } from "../../assets/icons/phpicon.svg";
import { ReactComponent as ScssSvg } from "../../assets/icons/scss.svg";
import { ReactComponent as WagmiSvg } from "../../assets/icons/wagmi.svg";
import { ReactComponent as FastapiSvg } from "../../assets/icons/fastapi.svg";

export const MoonIcon = () => {
  return <Moon className={"moon"}/>;
};
export const SunIcon = () => {
  return <Sun className={"star"}/>;
};
export const NiwartIcon = ({ theme }: {theme: "light" | "dark"}) => {
  return (
    <StyledNiwartLogo checked={theme === "dark"}>
      <NiwartLogo/>
    </StyledNiwartLogo>
  );
};
export const RightArrow = ({ className = "" }: {className?: string}) => {
  return <Arrow className={""}/>;
};

export const RightNeonArrow = () => {
  return <NeonSvg/>;
};

export const ModlaLogo = () => {
  return <ModlaSvg/>;
};
export const SmartcoLogoDark = () => {
  return <SmartcoSvgDark/>;
};
export const SSnCLogoDark = () => {
  return <SSnCSvgDark/>;
};
export const DrScharLogoDark = () => {
  return <DrScharCSvgDark/>;
};
export const CloudguardLogoDark = () => {
  return <CloudguardSvgDark/>;
};
export const TrustedAdvLogoDark = () => {
  return <TrustedAdvSvgDark/>;
};
export const The3rdkLogoDark = () => {
  return <The3rdSvgDark/>;
};
export const NyLogoDark = () => {
  return <NySvgDark/>;
};
export const CKLogoDark = () => {
  return <CKSvgDark/>;
};

export const SmartcoLogoDarkHover = () => {
  return <SmartcoSvgDarkHover/>;
};
export const SSnCLogoDarkHover = () => {
  return <SSnCSvgDarkHover/>;
};
export const DrScharLogoDarkHover = () => {
  return <DrScharCSvgDarkHover/>;
};
export const CloudguardLogoDarkHover = () => {
  return <CloudguardSvgDarkHover/>;
};
export const TrustedAdvLogoDarkHover = () => {
  return <TrustedAdvSvgDarkHover/>;
};
export const The3rdkLogoDarkHover = () => {
  return <The3rdSvgDarkHover/>;
};
export const CKLogoDarkHover = () => {
  return <CKSvgDarkHover/>;
};
export const NyLogoDarkHover = () => {
  return <NySvgDarkHover/>;
};

export const SmartcoLogoLight = () => {
  return <SmartcoSvgLight/>;
};
export const SSnCLogoLight = () => {
  return <SSnCSvgLight/>;
};
export const DrScharLogoLight = () => {
  return <DrScharCSvgLight/>;
};
export const CloudguardLogoLight = () => {
  return <CloudguardSvgLight/>;
};
export const TrustedAdvLogoLight = () => {
  return <TrustedAdvSvgLight/>;
};
export const The3rdkLogoLight = () => {
  return <The3rdSvgLight/>;
};
export const CKLogoLight = () => {
  return <CKSvgLight/>;
};
export const NyLogoLight = () => {
  return <NySvgLight/>;
};
export const SmartcoLogoLightHover = () => {
  return <SmartcoSvgLightHover/>;
};
export const SSnCLogoLightHover = () => {
  return <SSnCSvgLightHover/>;
};
export const DrScharLogoLightHover = () => {
  return <DrScharCSvgLightHover/>;
};
export const CloudguardLogoLightHover = () => {
  return <CloudguardSvgLightHover/>;
};
export const TrustedAdvLogoLightHover = () => {
  return <TrustedAdvSvgLightHover/>;
};
export const The3rdkLogoLightHover = () => {
  return <The3rdSvgLightHover/>;
};
export const CKLogoLightHover = () => {
  return <CKSvgLightHover/>;
};
export const NyLogoLightHover = () => {
  return <NySvgLightHover/>;
};

export const RefmetrixLogo = () => {
  return <RefmetrixSvg/>;
};
export const VolumeLogo = () => {
  return <Volume/>;
};
export const VolumeOffLogo = () => {
  return <VolumeOff/>;
};
export const ContactUsImg = () => {
  return <ContactSvg/>;
};
export const FileLogo = ({ className }: {className?: string}) => {
  return <FileSvg className={className}/>;
};
export const QuotesIcon = ({ className }: {className: string}) => {
  return <Quotes className={className}/>;
};
export const SocialBDarkIcon = ({ className = "" }: {className?: string}) => {
  return <Social_b className={className}/>;
};
export const SocialCDarkIcon = ({ className = "" }: {className?: string}) => {
  return <Social_c className={className}/>;
};
export const SocialVDarkIcon = ({ className = "" }: {className?: string}) => {
  return <Social_v className={className}/>;
};
export const SocialInDarkIcon = ({
  className = "",
}: {
  className?: string;
}) => {
  return <Social_in className={className}/>;
};

export const SocialBLightIcon = ({
  className = "",
}: {
  className?: string;
}) => {
  return <Social_b_light className={className}/>;
};
export const SocialCLightIcon = ({
  className = "",
}: {
  className?: string;
}) => {
  return <Social_c_light className={className}/>;
};
export const SocialVLightIcon = ({
  className = "",
}: {
  className?: string;
}) => {
  return <Social_v_light className={className}/>;
};
export const SocialInLightIcon = ({
  className = "",
}: {
  className?: string;
}) => {
  return <Social_in_light className={className}/>;
};

export const MenuIcon = ({ className = "" }: {className?: string}) => {
  return <Menu className={className}/>;
};
export const XmarkIcon = ({
  className = "", onClick = () => {
  }
}: {className?: string, onClick?: () => void}) => {
  return <Close className={className} onClick={onClick}/>;
};
export const TopItCompLightIcon = ({
  className = "",
}: {
  className?: string;
}) => {
  return <TopItComp_light className={className}/>;
};
export const TopItCompDarkIcon = ({
  className = "",
}: {
  className?: string;
}) => {
  return <TopItComp_dark className={className}/>;
};
export const TapIcon = ({ className = "" }: {className?: string}) => {
  return <Tap className={className}/>;
};
export const ReactIcon = ({ className = "" }: {className?: string}) => {
  return <ReactSvg className={className}/>;
};
export const TsIcon = ({ className = "" }: {className?: string}) => {
  return <TsSvg className={className}/>;
};
export const ReduxIcon = ({ className = "" }: {className?: string}) => {
  return <ReduxSvg className={className}/>;
};
export const PythonIcon = ({ className = "" }: {className?: string}) => {
  return <PythonSvg className={className}/>;
};
export const NodeIcon = ({ className = "" }: {className?: string}) => {
  return <NodeSvg className={className}/>;
};
export const FirebaseIcon = ({ className = "" }: {className?: string}) => {
  return <FirebaseSvg className={className}/>;
};
export const VictoryNativeIcon = ({ className = "" }: {className?: string}) => {
  return <VictoryNativeSvg className={className}/>;
};
export const JavaScriptIcon = ({ className = "" }: {className?: string}) => {
  return <JavaScriptSvg className={className}/>;
};
export const CssIcon = ({ className = "" }: {className?: string}) => {
  return <CssSvg className={className}/>;
};
export const WordPressIcon = ({ className = "" }: {className?: string}) => {
  return <WordpressSvg className={className}/>;
};
export const PhpIcon = ({ className = "" }: {className?: string}) => {
  return <PhpSvg className={className}/>;
};
export const ScssIcon = ({ className = "" }: {className?: string}) => {
  return <ScssSvg className={className}/>;
};
export const WagmiIcon = ({ className = "" }: {className?: string}) => {
  return <WagmiSvg className={className}/>;
};
export const FastapiIcon = ({ className = "" }: {className?: string}) => {
  return <FastapiSvg className={className}/>;
};



