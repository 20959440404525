import React, { Dispatch, Fragment, SetStateAction, useEffect, useLayoutEffect, useState } from "react";
import { StyledHeroBottomText, StyledInvertedText, StyledPhraseWrapper, TextWrapper } from "./styled";
import { motion } from "framer-motion";
import useMousePosition from "../../utils/useMousePossition";
import Button from "../Button/Button";

type PropsType = {
  isAppViewed: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const HeroText = ({ isAppViewed, setShowModal }: PropsType) => {
  const [isHovered, setIsHovered] = useState(false);
  const [size, setSize] = useState(0);
  const { x, y } = useMousePosition();
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    setKey(Math.random());
  }, [isAppViewed]);

  useLayoutEffect(() => {
    if (y > 64) {
      setSize(isHovered ? 85 : 0);
    } else {
      setSize(0);
    }
  }, [y, isHovered]);

  const textProps = (delay: number, duration: number) => ({
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeOut",
        delay: delay,
        duration,
      },
    },
    hidden: {
      y: 80,
      opacity: 0
    },
  });

  return (
    <Fragment key={key}>
      <TextWrapper
        animate={{
          WebkitMaskPosition: `${x - size / 2}px ${y - size}px`,
          WebkitMaskSize: `${size}px`,
        }}
        transition={{ type: "tween", ease: "backOut", duration: 0.2 }}
      >
        <div>
          <StyledPhraseWrapper>
            <StyledInvertedText
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Software
            </StyledInvertedText>
            <StyledInvertedText
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Development
            </StyledInvertedText>
          </StyledPhraseWrapper>
        </div>
      </TextWrapper>
      <TextWrapper>
        <div>
          <StyledPhraseWrapper>
            <motion.span
              variants={textProps(isAppViewed ? 0 : 3.3, 1)}
              initial={"hidden"}
              animate={"visible"}
            >
              Software
            </motion.span>
            <motion.span
              variants={textProps(isAppViewed ? 0.3 : 3.6, 0.7)}
              initial={"hidden"}
              animate={"visible"}
            >
              Development
            </motion.span>
          </StyledPhraseWrapper>
          <StyledPhraseWrapper $alignself={"flex-start"}>
            <motion.span
              variants={textProps(isAppViewed ? 0.7 : 4, 0.8)}
              initial={"hidden"}
              animate={"visible"}
            >
              Company
            </motion.span>
            <motion.div
              variants={textProps(isAppViewed ? 0.7 : 4, 0.8)}
              initial={"hidden"}
              animate={"visible"}
            >
              <Button
                type={"primary"}
                text={`Let’s Collaborate`}
                onClick={() =>
                  setShowModal(true)
                }
              />
            </motion.div>
          </StyledPhraseWrapper>
          <StyledHeroBottomText
          >
            Realizing Potential with <span>Innovations</span>
          </StyledHeroBottomText>
        </div>
      </TextWrapper>
    </Fragment>
  );
};

export default HeroText;
