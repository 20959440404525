import styled from "styled-components";

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInputStatus = styled.div<{ iserror: boolean | undefined }>`
  position: absolute;
  right: 0;
  top: 45%;
  width: 20px;
  height: 20px; 
  transform: translate(0%, -50%);
  border: ${({ theme }) => theme.text === '#fff' ? '1px solid rgba(255, 255, 255, 0.25)' : '1px solid rgba(0, 0, 0, 0.25)'};   
  border-radius: 100%;
  ${({ iserror }) =>
  typeof iserror !== 'undefined' ?
     iserror ?
      `background: #FF453A;`
      :
      `background: #3A6BFF;`
      :
      `background: transparent;`
  }
  

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    
    &:nth-child(1) {
      ${({ iserror }) =>
    typeof iserror === 'undefined' || iserror ?
      `opacity: 0`
      :
      `opacity: 1`
  }
    }

    &:nth-child(2) {      
      ${({ iserror }) =>
    iserror
      ?
      `opacity: 1`
      :
      `opacity: 0`
  }
    }
  }

`;

export const StyledInput = styled.input<{ iserror: boolean | undefined }>`
  position: relative;
  width: inherit;
  padding: 0 0 8px;
  background-color: transparent;
  border: none;
  border-bottom: ${({ theme, iserror }) =>
    iserror
      ? `1px solid ${theme.input.borderError}`
      : `1px solid ${theme.input.borderColor}`};
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  -moz-appearance:textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.text};
    opacity: 1;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &:hover {
    border-bottom: ${({ theme }) => `1px solid ${theme.input.borderHover}`};
  }

  &:focus-visible {
    outline: none;
    border-bottom: ${({ theme }) => `1px solid ${theme.input.borderFocus}`};
  }

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.text};
    box-shadow: inset 0 0 20px 20px transparent;
  }
`;

export const StyledInputError = styled.div`
  position: absolute;
  bottom: -26px;
  color: ${({ theme }) => theme.input.borderError};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 640px) {
    bottom: -21px;
  }
`;
