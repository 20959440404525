import {
  StyledProjectMonoTitle,
  StyledProjectTextSection,
  StyledProjectTwoColorTitle,
} from "./styled";

type PropsType = {
  header: Array<string>;
  text?: Array<string>;
  direction: "vertical" | "horizontal";
  maxWidth?: string;
  gap?: string;
  titleColor: string;
  padding?: string;
  headerMaxWidth?: string;
};

const ProjectTextSection = ({
  text,
  gap,
  header,
  direction,
  maxWidth,
  titleColor,
  padding,
  headerMaxWidth,
}: PropsType) => {
  const renderHeader = () => {
    if (header.length === 1)
      return (
        <StyledProjectMonoTitle
          $color={titleColor}
          $headerMaxWidth={headerMaxWidth}
        >
          {header[0]}
        </StyledProjectMonoTitle>
      );
    return (
      <StyledProjectTwoColorTitle
        $color={titleColor}
        $headerMaxWidth={headerMaxWidth}
      >
        {header[0]} <span>{header[1]}</span>
      </StyledProjectTwoColorTitle>
    );
  };

  return (
    <StyledProjectTextSection
      direction={direction}
      $gap={gap}
      $maxWidth={maxWidth}
      $padding={padding}
    >
      {renderHeader()}
      {text && (
        <div>
          {text.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      )}
    </StyledProjectTextSection>
  );
};

export default ProjectTextSection;
