import { StyledContactUs, StyledContactUsBg, StyledContactUsWrapper, StyledFormWrapper, StyledModal, } from "./styled";
import ContactUsForm from "./ContactUsForm";
import Footer from "../Footer/Footer";
import { useState } from "react";
import { ModalObjType } from "../../types";
import { Variants } from "framer-motion";

const ContactUs = () => {
  const [modalObj, setModalObj] = useState<ModalObjType>({
    isOpen: false,
    text: "Your message has been sent",
  });

  const animVariants: Variants = {
    hidden: {
      opacity: 0.2,
    },
    visible: {
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2.5,
      },
    },
  };

  return (
    <StyledContactUsWrapper
      id={"contact-us"}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={animVariants}
    >
      {modalObj.isOpen && (
        <StyledModal>
          <p>{modalObj.text}</p>
        </StyledModal>
      )}
      <StyledContactUs>
        <StyledFormWrapper>
          <h2>
            Let’s <span>Collaborate</span>
          </h2>
          <ContactUsForm modalObj={modalObj} setModalObj={setModalObj}/>
        </StyledFormWrapper>
        <Footer/>
      </StyledContactUs>
      <StyledContactUsBg/>
    </StyledContactUsWrapper>
  );
};

export default ContactUs;
