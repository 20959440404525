import React, { TextareaHTMLAttributes, useEffect, useRef } from "react";
import { StyledTextArea } from "./styled";
import { StyledInputError, StyledInputStatus, StyledInputWrapper } from "../Input/styled";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  iserror?: boolean;
  errortext?: string;
}

const TextArea = (args: TextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { value } = args;

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [value]);

  return (
    <StyledInputWrapper>
      <StyledTextArea {...args} iserror={args.iserror} ref={textAreaRef} rows={1} />
      {/* TODO: FIX TS ERROR */}
      {/* @ts-ignore */}
      <StyledInputStatus {...args} iserror={args.iserror}>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_7004_4576" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
            <rect x="0.910156" y="0.262939" width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_7004_4576)">
            <path d="M7.57682 11.1964L4.91016 8.52969L5.84349 7.59635L7.57682 9.32969L11.9768 4.92969L12.9102 5.86302L7.57682 11.1964Z" fill="white" />
          </g>
        </svg>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_7004_3520" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_7004_3520)">
            <path d="M8.11111 13C7.80556 13 7.54398 12.8912 7.32639 12.6736C7.1088 12.456 7 12.1944 7 11.8889C7 11.5833 7.1088 11.3218 7.32639 11.1042C7.54398 10.8866 7.80556 10.7778 8.11111 10.7778C8.41667 10.7778 8.67824 10.8866 8.89583 11.1042C9.11343 11.3218 9.22222 11.5833 9.22222 11.8889C9.22222 12.1944 9.11343 12.456 8.89583 12.6736C8.67824 12.8912 8.41667 13 8.11111 13ZM7 9.66667V3H9.22222V9.66667H7Z" fill="white" />
          </g>
        </svg>
      </StyledInputStatus>
      {args.required && args.iserror && (
        <StyledInputError>{args.errortext}</StyledInputError>
      )}
    </StyledInputWrapper>
  );
};

export default TextArea;
