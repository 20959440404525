import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { StoriesMobileWrapper } from "../styled";
import { storiesData } from "../../../data/storiesData";
import ActiveStoryMobile from "./ActiveStoryMobile";
import { RightArrow } from "../../Icons/Icons";
import { useState } from "react";
import { VideoObjType } from "../Stories/Stories";
import { useTheme } from "styled-components";

const StoriesMobile = () => {
  const isDarkTheme = useTheme().text === '#fff'
  const [currentSlide, setCurrentSlide] = useState(0);
  const [videoObj, setVideoObj] = useState<VideoObjType>({
    isPlaying: false,
    isMuted: true,
    length: 0,
  });
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    drag: false,
    selector: ".slide_testimonials",
    slides: {
      perView: 1,
      spacing: 20,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      drag: false,
      slides: {
        perView: "auto",
      },
    },
    [
      (slider) => {
        function removeActive() {
          slider.slides.forEach((slide) => {
            slide.classList.remove("active");
          });
        }

        function addActive(idx: number) {
          slider.slides[idx].classList.add("active");
        }

        function addClickEvents() {
          slider.slides.forEach((slide, idx) => {
            slide.addEventListener("click", () => {
              if (instanceRef.current) {
                instanceRef.current.moveToIdx(idx);
              }
            });
          });
        }

        slider.on("created", () => {
          if (!instanceRef.current) return;
          addActive(slider.track.details.rel);
          addClickEvents();
          instanceRef.current.on("animationStarted", (main) => {
            removeActive();
            const next = main.animator.targetIdx || 0;
            addActive(main.track.absToRel(next));
            slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
          });
        });
      },
    ],
  );

  return (
    <StoriesMobileWrapper>
      <div ref={sliderRef} className="slider">
        {storiesData.map((story, i) => (
          <ActiveStoryMobile
            key={i}
            story={story}
            isActive={currentSlide === i}
            videoObj={videoObj}
            setVideoObj={setVideoObj}
          />
        ))}
      </div>
      <div>
        <div ref={thumbnailRef} className="thumbnail_testimonials">
          {storiesData.map((story, i) => (
            <div key={i} className={"keen-slider__slide thumb_testimonials"}>
              <img src={story.imageUrl} alt={story.company}/>
            </div>
          ))}
        </div>
        <div className={"button_wrapper"}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              instanceRef.current?.prev();
            }}
            disabled={currentSlide === 0}
            className={isDarkTheme ? 'button_dark left' : 'button_light left'}
          >
            <RightArrow/>
          </button>
          <p>
            {currentSlide + 1}/{storiesData.length}
          </p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              instanceRef.current?.next();
            }}
            disabled={currentSlide + 1 === storiesData.length}
            className={isDarkTheme ? 'button_dark' : 'button_light'}
          >
            <RightArrow/>
          </button>
        </div>
      </div>
    </StoriesMobileWrapper>
  );
};

export default StoriesMobile;
