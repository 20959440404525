import styled from "styled-components";
import { colorsPalette } from "../../styles/colorsPalette";

export const StyledLabel = styled.label<{ checked: boolean }>`
  align-self: end;
  width: 52px;
  height: 28px;
  background-color: transparent;
  border-radius: 360px;
  border: ${({ theme }) => theme.themeSwitcher.border};
  transform: translateY(-32%);
  cursor: pointer;
  transition: 0.3s ease background-color;
  overflow: hidden;

  & input {
    display: none;
  }

  & .wrapper {
    position: absolute;
    top: 2px;
    left: ${({ checked }) => (checked ? "25.8px" : "2.8px")};
    background: ${({ checked }) =>
      checked
        ? `linear-gradient(88deg, #3A6BFF 1.41%, #00C2FF 98.44%)`
        : colorsPalette.primary["600"]};
    filter: ${({ checked }) =>
      checked
        ? "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.15))"
        : "drop-shadow(0px 0px 1px rgba(51, 51, 51, 0.25))"};
    width: 22px;
    height: 22px;
    border-radius: 50%;

    transition: 0.2s ease all;
    z-index: 1;
  }

  & .star {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${({ checked }) => (checked ? "none" : "block")};
    transition: all ease 0.3s;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    & path {
      fill: #fff;
    }
  }

  & .moon {
    display: ${({ checked }) => (checked ? "block" : "none")};
    transition: all ease 0.3s;
    position: absolute;
    left: 3.6px;
    top: 3.4px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;
