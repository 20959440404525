import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ModalObjType } from "../../types";
import { StyledContactUs, StyledFormWrapper, StyledModal } from "../ContactUs/styled";
import ContactUsForm from "../ContactUs/ContactUsForm";
import Footer from "../Footer/Footer";
import { StyledContactModal } from "./styled";
import { XmarkIcon } from "../Icons/Icons";

type PropsType = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const ContactModal = ({ setShowModal }: PropsType) => {
  const [modalObj, setModalObj] = useState<ModalObjType>({
    isOpen: false,
    isSuccess: false,
    text: "Your message has been sent",
  });

  const handleClick = () => {
    setShowModal(false);
  }

  useEffect(() => {
    if (modalObj.isSuccess === true) {
      setShowModal(false);
    }
  }, [modalObj.isSuccess, setShowModal]);

  return (
    <StyledContactModal>
      {modalObj.isOpen && (
        <StyledModal>
          <p>{modalObj.text}</p>
        </StyledModal>
      )}
      <StyledContactUs>
        <StyledFormWrapper>
          <XmarkIcon className="close" onClick={handleClick} />
          <h2>
            Let’s <span>Collaborate</span>
          </h2>
          <ContactUsForm modalObj={modalObj} setModalObj={setModalObj} />
        </StyledFormWrapper>
        <Footer />
      </StyledContactUs>
    </StyledContactModal >
  )
}

export default ContactModal;