import React from 'react';
import ContactUs from "../../components/ContactUs/ContactUs";
import { StyledContactPage } from './styled';
import seoImg from "../../assets/images/social-image.jpg"
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  return (
    <StyledContactPage>
      <Helmet>
        <title>Contact Niwart | Get in Touch for Innovative Software Solutions</title>
        <meta name="description" content="Reach out to Niwart's dedicated team to discuss your software development needs, collaborations, or inquiries. We're here to help you bring your ideas to life." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Niwart | Get in Touch for Innovative Software Solutions" />
        <meta property="og:description" content="Reach out to Niwart's dedicated team to discuss your software development needs, collaborations, or inquiries. We're here to help you bring your ideas to life." />
        <meta property="og:image" content={seoImg} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Contact Niwart | Get in Touch for Innovative Software Solutions" />
        <meta property="twitter:description" content="Reach out to Niwart's dedicated team to discuss your software development needs, collaborations, or inquiries. We're here to help you bring your ideas to life." />
        <meta property="twitter:image" content={seoImg} />
      </Helmet>
      <ContactUs/>
    </StyledContactPage>
  );
};

export default ContactPage;