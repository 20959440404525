import useWindowSize from "../../utils/useWindowSize";
import TestimonialsMobile from "../../components/Testimonials/TestimonialsMobile";
import TestimonialsDesktop from "../../components/Testimonials/TestimonialsDesktop";
import Projects from "../../components/Projects/Projects";
import Services from "../../components/Services/Services";
import Partners from "../../components/Partners/Partners";
import ContactUs from "../../components/ContactUs/ContactUs";
import Hero from "../../components/Hero/Hero";
import Introduce from "../../components/Introduce/Introduce";
import { useEffect, useState } from "react";
import ContactModal from "../../components/ContactModal/ContactModal";
import seoImg from "../../assets/images/social-image.jpg"
import { Helmet } from 'react-helmet';

const HomePage = () => {
  const { width } = useWindowSize();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const hash = window.location.hash.substr(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          const top = element.getBoundingClientRect().top;
          window.scrollTo({ top, left: 0, behavior: 'smooth' });
        }
      }
    }, 3000)
  }, [])

  return (
    <>
      <Helmet>
        <title>Niwart: Software Development Company</title>
        <meta name="description" content="Niwart is a trusted software development company merging cutting-edge technology with art to empower businesses to fulfill their diverse IT requirements." />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Niwart: Software Development Company" />
        <meta property="og:description" content="Niwart is a trusted software development company merging cutting-edge technology with art to empower businesses to fulfill their diverse IT requirements." />
        <meta property="og:image" content={seoImg} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Niwart: Software Development Company" />
        <meta property="twitter:description" content="Niwart is a trusted software development company merging cutting-edge technology with art to empower businesses to fulfill their diverse IT requirements." />
        <meta property="twitter:image" content={seoImg} />
      </Helmet>
      <Hero setShowModal={setShowModal} />
      <Introduce />
      <Services setShowModal={setShowModal} />
      <Partners />
      <Projects setShowModal={setShowModal} />
      {width < 1025 ? <TestimonialsMobile /> : <TestimonialsDesktop />}
      <ContactUs />
      {showModal &&
        <ContactModal setShowModal={setShowModal} />
      }
    </>
  );
};

export default HomePage;
