import styled from "styled-components";

export const StyledContactBtn = styled.div<{
  $image: string;
}>`
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s ease;

    &.hide {
      opacity: 0;
      visibility: hidden;
    }
  
    div {
      width: 57px;
      height: 57px;
      background-color: ${({ theme }) =>
    theme.text === "#fff"
      ? '#fff'
      : '#1953FF'
  };
      border-radius: 100%;
      transition: all .3s ease;
    }
  
    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: rotate 15s linear infinite; 
  
      path {
        fill: ${({ theme }) =>
    theme.text === "#fff"
      ? '#fff'
      : '#000'
  };
      }
  
      circle {
        fill: ${({ theme }) =>
    theme.text === "#fff"
      ? '#fff'
      : '#1953FF'
  };
      }
    }
  
    &:hover {
      div {
        transform: scale(1.2)
      }
    }

    @media (max-width: 1500px) {
    width: 80px;
    height: 80px;

    div {
      width: 45px;
      height: 45px;
    }
  }

  
  @media (max-width: 768px) {
    right: 20px;
    bottom: 20px;
  }

  @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
`