import { MutableRefObject, useEffect, useMemo, useState } from "react";

export function useIsInViewport(ref: MutableRefObject<any>, offset?: number) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        {
          threshold: offset || 1,
        },
      ),
    [offset],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
