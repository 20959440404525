import { StyledContactBtn, StyledProjects } from "./styled";
import { useKeenSlider } from "keen-slider/react";
import { projectsData } from "../../data/projectsData";
import ProjectItem from "./ProjectItem";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ProjectsInfo from "./ProjectsInfo";
import useWindowSize from "../../utils/useWindowSize";
import { useIsInViewport } from "../../utils/useIsInViewPort";
import { ContactUsImg, RightArrow } from "../Icons/Icons";
import { StyledTitle } from "../Partners/styled";
import contactBtnImage from "../../assets/images/contact-us-white.png"

type PropsType = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const Projects = ({ setShowModal }: PropsType) => {
  const transitionDuration = 15000;
  const { width } = useWindowSize();
  const projectsRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [sliderActive, setSliderActive] = useState<boolean>(false);
  const isInViewport1 = useIsInViewport(projectsRef, 0.1);
  const projectsForSlider = projectsData.slice(0, 5);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      drag: false,
      loop: true,
      selector: ".slide",
      slides: {
        perView: 1,
      },
      defaultAnimation: {
        duration: 1,
        easing: (t) => t,
      },
    },
    [
      (slider) => {
        let fadeOutTimeout: ReturnType<typeof setTimeout>;

        function clearNextTimeout() {
          clearTimeout(fadeOutTimeout);
        }

        function nextTimeout() {
          clearNextTimeout();
          const currentSlide = instanceRef.current?.track.details.rel || 0;
          fadeOutTimeout = setTimeout(() => {
            activeSlide !== slider.slides.length - 1 &&
              instanceRef.current?.slides[currentSlide].classList.add(
                "fadeOutAnim",
              );
          }, transitionDuration - 400);
          setTimeout(() => {
            instanceRef.current?.slides[currentSlide].classList.remove(
              "fadeOutAnim",
            );
          }, transitionDuration + 100);
        }

        slider.on("optionsChanged", () => {
          if (document.visibilityState === "visible") {
            nextTimeout();
          } else {
            clearNextTimeout();
          }
        });
        slider.on("created", () => {
          document.addEventListener("visibilitychange", () => {
            slider.emit("optionsChanged");
          });
        });
        slider.on("slideChanged", () => {
          nextTimeout();
        });
        slider.on("updated", nextTimeout);
      },
    ],
  );
  const [thumbnailRef, instanceThumbRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      drag: false,
      slides: {
        perView: "auto",
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let currentSlide = 0;
        let nextSlide = 1;

        function clearNextTimeout() {
          clearTimeout(timeout);
        }

        function nextTimeout() {
          clearNextTimeout();
          removeActive();
          currentSlide === 0 && removeToFull();
          addActive(currentSlide);
          timeout = setTimeout(() => {
            nextSlide =
              currentSlide === slider.slides.length - 1 ? 0 : currentSlide + 1;
            instanceRef.current?.moveToIdx(nextSlide);
            setActiveSlide(nextSlide);
            slider.emit("slideChanged");
          }, transitionDuration);
        }

        function removeActive() {
          slider.slides.forEach((slide) => {
            slide.classList.remove("active");
          });
        }

        function addActive(ind: number) {
          setTimeout(() => {
            slider.slides[ind].classList.add("active");
            slider.slides[ind].classList.add("toFullAnim");
          }, 100);
        }

        function removeToFull() {
          slider.slides.forEach((s) => {
            s.classList.remove("toFullAnim");
            s.classList.remove("alreadyFull");
          });
        }

        function addBarToFull(slide: HTMLElement, idx: number) {
          slider.slides.forEach((s, i) => {
            if (i < idx) {
              s.classList.remove("toFullAnim");
              setTimeout(() => {
                s.classList.add("alreadyFull");
              }, 0);
            }
            if (i > idx) {
              s.classList.remove("toFullAnim");
              s.classList.remove("alreadyFull");
            }
          });
          slide.classList.remove("alreadyFull");
          slide.classList.remove("toFullAnim");
          setTimeout(() => {
            slide.classList.add("toFullAnim");
          }, 0);
        }

        function addClickEvents() {
          slider.slides.forEach((slide, idx) => {
            slide.addEventListener("click", () => {
              clickEvent(slide, idx);
            });
          });
        }

        function clickEvent(slide: HTMLElement, idx: number) {
          if (instanceRef.current) {
            if (currentSlide === idx) return;
            currentSlide = idx;
            setActiveSlide(idx);
            nextTimeout();
            instanceRef.current.moveToIdx(idx);
            addBarToFull(slide, idx);
          }
        }

        function removeToFullCurrent(i: number) {
          slider.slides[i].classList.remove("toFullAnim");
        }

        slider.on("optionsChanged", () => {
          addActive(activeSlide);
        });

        slider.on("slideChanged", () => {
          currentSlide = nextSlide;
          if (instanceRef.current) {
            nextTimeout();
          }
        });

        slider.on("created", () => {
          if (!instanceRef.current) return;
          addClickEvents();
          instanceRef.current.on("optionsChanged", () => {
            if (document.visibilityState === "visible") {
              nextTimeout();
            } else {
              clearNextTimeout();
              removeToFullCurrent(currentSlide);
            }
          });
        });
      },
    ],
  );

  const handleClickNext = () => {
    let next = activeSlide + 1 >= projectsData.length ? 0 : activeSlide + 1;
    setActiveSlide(next);
    instanceThumbRef.current?.slides[next].click();
  };
  const handleClickBack = () => {
    let prev = activeSlide - 1 < 0 ? projectsData.length - 1 : activeSlide - 1;
    setActiveSlide(prev);
    instanceThumbRef.current?.slides[prev].click();
  };

  useEffect(() => {
    if (!sliderActive && isInViewport1) {
      instanceRef.current?.emit("optionsChanged");
      setSliderActive(true);
    }
  }, [isInViewport1]);

  console.log(activeSlide);

  return (
    <StyledProjects ref={projectsRef} id={"projects"}>
      <StyledTitle>
        Case <span>Studies</span>
      </StyledTitle>
      {activeSlide !== 0 && (
        <div className={"arrow leftArrow"} onClick={handleClickBack}>
          <RightArrow />
        </div>
      )}
      {activeSlide < 4 && ( 
        <div className={"arrow rightArrow"} onClick={handleClickNext}>
          <RightArrow />
        </div>
      )}
      <div ref={sliderRef} className="slider">
        {projectsForSlider.map((el, i) => (
          <ProjectItem key={i} project={el} />
        ))}
      </div>
      <div ref={thumbnailRef} className="thumbnail">
        {projectsForSlider.map((el, i) => (
          <div key={i} className="keen-slider__slide thumb">
            <div>
              <div className="bar" />
            </div>
            <p>0{i + 1}.</p>
            <h3 className={i === activeSlide ? "active_text" : ""}>
              {el.name}
            </h3>
          </div>
        ))}
      </div>
      {width < 1231 &&
        projectsData
          .filter((_, i) => i === activeSlide)
          .map((el, i) => <ProjectsInfo project={el} key={i} />)
      }
      <StyledContactBtn
        $image={contactBtnImage}
        $animate={isInViewport1 ? true : false}
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div />
        <ContactUsImg />
      </StyledContactBtn>
    </StyledProjects>
  );
};

export default Projects;
