import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CONTACT, HOME, PORTFOLIO, PRIVACY, PROJECT } from "./routes";
import HomePage from "./pages/Home/HomePage";
import "./App.css";
import "./assets/fonts/font-face.css";
import MainLayout from "./components/MainLayout/MainLayout";
import PortfolioPage from "./pages/Home/PortfolioPage";
import ProjectPage from "./pages/Home/ProjectPage";
import useHasViewedApp from "./utils/useHasViewedApp";
import ContactPage from "./pages/ContactPage/ContactPage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";

function App() {
  useHasViewedApp();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME} element={<MainLayout Component={HomePage} />} />
        <Route
          path={PORTFOLIO}
          element={<MainLayout Component={PortfolioPage} />}
        />
        <Route
          path={PROJECT}
          element={<MainLayout Component={ProjectPage} />}
        />
        <Route
          path={CONTACT}
          element={<MainLayout Component={ContactPage} />}
        />
        <Route
          path={PRIVACY}
          element={<MainLayout Component={PrivacyPage} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
