export const servicesData = [
  {
    title: "IT Business Consulting",
    subTitles: [
      "Technology Assessment",
      "IT Strategy and Planning",
      "Technology Consulting",
      "Business Process Optimization",
      "IT Project Management",
    ],
    hoveredSubTitle:
      "Our team of practitioners will study your business with your objectives in mind to help you implement tailored solutions within a reasonable timeframe and budget.",
  },
  {
    title: "Product Design",
    subTitles: [
      "UX/UI Design",
      "Information Architecture",
      "Wireframing and Prototyping",
      "Market Research & Analysis",
    ],
    hoveredSubTitle:
      "Our skilled IT product designers use reliable and innovative methods to create tailored solutions, enhancing the user experience through art and technology.",
  },
  {
    title: "Web & Mobile Development",
    subTitles: [
      "Web App Development",
      "Software Development",
      "Website Creation",
      "Mobile App Development",
      "CMS Development",
    ],
    hoveredSubTitle:
      "We transform your vision into custom, high-quality web and mobile products that enhance your competitive advantage, prioritizing your needs, budget, and timeline.",
  },
  {
    title: "Broader IT Support",
    subTitles: [
      "Broader IT Support",
      "IT Support & Maintenance",
      "Cloud Integration and Migration",
      "Branding and Content Strategy",
      "Training and Documentation",
    ],
    hoveredSubTitle:
      "We offer a wide range of IT services, allowing you to concentrate on your business while we handle the technical aspects to ensure consistent user experiences.",
  },
];