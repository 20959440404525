import React from "react";
import { StyledPortfolioCardItem } from "./styled";
import { ProjectType } from "../../../types";
import RoundTagOutline from "../../RoundTag/RoundTagOutline";
import useWindowSize from "../../../utils/useWindowSize";
import { useNavigate } from "react-router-dom";
import PortfolioSoonTag from "./PortfolioSoonTag";
import ImageLoader from "../../ImageLoader/ImageLoader";

type PropsType = {
  project: ProjectType;
};

const PortfolioCard = ({ project }: PropsType) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const handleCardClick = () => {
    if (project.href !== undefined) {
      if (project.href.includes("http")) {
        window.open(project.href, "_blank");
      } else {
        navigate(`/portfolio/${project.href}`);
      }
    }
  };

  return (
    <StyledPortfolioCardItem
      $isfav={project.isFav || false}
      $color={project.color}
      onClick={handleCardClick}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
    >
      {project.portfolio_image_hash &&
        <ImageLoader imageHash={project.portfolio_image_hash} imageUrl={width > 512 ? project.portfolio_image : project.portfolio_mobile} />
      }
      <RoundTagOutline text={project.industry} color={project.color} />
      {!project?.href && <PortfolioSoonTag />}
      <h3>{project.name}</h3>
    </StyledPortfolioCardItem>
  );
};

export default PortfolioCard;
