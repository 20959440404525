import styled from "styled-components";

export const StyledLink = styled.li`
  width: fit-content;
  height: fit-content;

  & a {
    display: block;
    cursor: none;
    user-select: none;
    width: fit-content;
    position: relative;
    color: ${({ theme }) => theme.buttons.primary.hoverText};
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 10px 24px;
    cursor: none;
    margin: 2px;
    transition: 0.3s border-color ease-in-out;

    &:disabled {
      opacity: 0.6;
    }

    &:hover {
      border: ${({ theme }) => theme.menuLink.hoverBorder};

      &:before {
        opacity: 1;
      }
    }
  }
`;
