export const colorsPalette = {
  neutral: {
    850: "#8C91A1",
    800: "#5C6170", //progress projects names (white)
    700: "#212431", //progress bg carousel, tags border (dark)
    600: "#131620", //tags bg (dark)
    500: "#05070D", //bg - text buttons (dark)
  },
  primary: {
    900: "#F2F5FF",
    800: "#00C2FF", //for gradient
    700: "#3A6BFF", // main
    600: "#1953FF", //progress line projects (white)
    550: "#E7EAF0",
    500: "#D1D5E2", //tag border, progress bg (white)
    400: "#A3ABC2", //incoming project (carousel) (dark)
    300: "#565E76", //text read already (gray) (dark)
  },
  red: {
    800: "#FF1A53", //* in input
  },
};
