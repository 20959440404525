import { FC, useEffect, useState } from "react";
import Header from "../Header/Header";
import { useDarkMode } from "../../utils/useDarkMode";
import { darkTheme, GlobalStyles, lightTheme } from "../../styles/globalStyles";
import { ThemeProvider } from "styled-components";
import MobileMenu from "../MobileMenu/MobileMenu";
import Preloader from "../Preloader/Preloader";
import preloaderDark from "../../assets/video/Preloader_Dark.json";
import preloaderLight from "../../assets/video/Preloader_Light.json";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ContactButton from "../ContactButton/ContactButton";
import ContactModal from "../ContactModal/ContactModal";

const MainLayout: FC<{
  Component: FC;
}> = ({ Component }) => {
  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [isPreloader, setIsPreloader] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let id = setTimeout(() => {
      setIsPreloader(false);
    }, 3000);
    return () => {
      clearTimeout(id);
    };
  }, []);
  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <ScrollToTop />
      <Preloader
        isOpen={isPreloader}
        animationData={theme === "light" ? preloaderLight : preloaderDark}
      />
      <Header
        theme={theme}
        toggleMode={toggleTheme}
        isOpened={mobileMenuOpened}
        setOpened={() => setMobileMenuOpened((prev) => !prev)}
      />
      <MobileMenu
        isOpened={mobileMenuOpened}
        setIsOpened={setMobileMenuOpened}
        theme={theme}
        toggleMode={toggleTheme}
      />
      <Component />
      <ContactButton setShowModal={setShowModal} />
      {showModal &&
        <ContactModal setShowModal={setShowModal} />
      }
      {/* <CookiesConsent/> */}
    </ThemeProvider>
  );
};

export default MainLayout;
