import React, { ReactElement, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StyledStoriesWrapper, StyledTestimonials, StyledWordsWrapper, } from "./styled";
import { TestimonialsPhrases } from "./TestimonialsDesktop";
import StoriesMobile from "./StoriesMobile/StoriesMobile";

const TestimonialsMobile = () => {
  let refs = useRef<Array<HTMLParagraphElement>>([]);
  const container = useRef(null);
  const storiesRef = useRef(null);

  const splitWords = () => {
    let arr: Array<ReactElement<HTMLParagraphElement>> = [];
    TestimonialsPhrases.forEach((phase) => {
      phase.split(" ").forEach((word, ind) => {
        arr.push(
          <p
            key={word + "_" + ind}
            ref={(el: HTMLParagraphElement) => {
              refs.current.push(el);
            }}
          >
            {word}
          </p>,
        );
      });
    });
    return arr;
  };

  const createTextAnimations = () => {
    gsap.to(refs.current, {
      opacity: 0,
      zIndex: -1,
      stagger: (i) => {
        if (i >= refs.current.length - 2) {
          return 0.5;
        }
        return 0;
      },
      //stagger: 0.5,
      ease: "power2.inOut",
      duration: 2,

      scrollTrigger: {
        trigger: container.current,
        start: "20% center",
        once: true,
      },
    });
  };

  const createStoriesAnimations = () => {
    gsap.to(storiesRef.current, {
      opacity: 1,
      ease: "power2.inOut",
      delay: 2.5,
      duration: 1,
      scrollTrigger: {
        trigger: container.current,
        start: "20% center",
      },
    });
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    createTextAnimations();
    createStoriesAnimations();
  }, []);

  return (
    <StyledTestimonials ref={container} id={"stories"}>
      <StyledWordsWrapper>{splitWords()}</StyledWordsWrapper>
      <StyledStoriesWrapper ref={storiesRef}>
        <StoriesMobile/>
      </StyledStoriesWrapper>
    </StyledTestimonials>
  );
};

export default TestimonialsMobile;
