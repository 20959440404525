import { StoryType } from "../../../types";
import { QuotesIcon } from "../../Icons/Icons";
import { StyledActiveStory } from "../styled";
import { useTheme } from "styled-components";
import VideoMiniatureMobile from "./VideoMiniatureMobile";
import { Dispatch, SetStateAction } from "react";
import { VideoObjType } from "../Stories/Stories";

type PropsType = {
  story: StoryType;
  isActive: boolean;
  setVideoObj: Dispatch<SetStateAction<VideoObjType>>;
  videoObj: VideoObjType;
};

const ActiveStoryMobile = ({
  story,
  isActive,
  videoObj,
  setVideoObj,
}: PropsType) => {
  const theme = useTheme();
  return (
    <StyledActiveStory className={"slide_testimonials"}>
      <div className={"title"}>
        <VideoMiniatureMobile
          story={story}
          setVideoObj={setVideoObj}
          videoObj={videoObj}
          isActive={isActive}
        />
        <div className={"names"}>
          <h4>{story.name}</h4>
          <p>{story.company}</p>
        </div>
      </div>
      <div className={"text"}>
        <QuotesIcon
          className={theme.stories?.quotesColor ? "svgBlue svg" : "svg"}
        />
        <p>{story.text}</p>
      </div>
    </StyledActiveStory>
  );
};

export default ActiveStoryMobile;
