import styled, { css } from "styled-components";

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 360px;
  align-self: start;
  margin-top: 160px;

  
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw !important;
    overflow: hidden;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & a,
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      & span {
        display: block;
      }
    }
  }

  & h4 {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.contactUs.contactsTitle};
    margin: 0;
  }

  & p {
    margin: 0;
  }

  @media (max-width: 980px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: inherit;
    margin-top: 0;
    & > p {
      margin: 0 auto;
      text-align: left;
      width: 100%;
    }

    & > div {
      &:last-child {
        width: 100%;
      }
    }
  }
  @media (max-width: 690px) {
    & > div {
      &:nth-last-child(2) {
        align-items: center;
      }
    }
  }
  @media (max-width: 620px) {
    flex-direction: column;
    align-self: flex-start;
    & > div {
      &:nth-last-child(2) {
        align-items: start;
      }
    }
  }
`;

export const IconsWrapper = styled.div<{ $align?: string }>`
  display: flex;
  align-items: flex-end;

  a {
    svg {
      width: 70px;
    }
  }

  & .goodfirm-widget { 
    width: max-content;
    iframe {
      margin-left: -30px;
      width: 220px;
      transform: scale(.6);
    }
  }

  & .clutch-widget
  {    
    margin-left: -30px;
    min-width: 220px;
    transform: scale(.8);
  }

  ${({ $align }) =>
    $align &&
    css`
      align-items: ${$align};
    `}
`;
