import styled from "styled-components";

export const StyledHeaderContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;  

  @media (max-width: 768px) {
    max-width: 100vw;
  }
`;

export const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  z-index: 75;

  &.header--hide {
    transform: translateY(-100%);
    transition: transform 1s ease;
  }

  &.header--show {
    transform: translateY(0);
    transition: transform 1s ease;
  }

  &.header--blur {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: ${({ theme }) => theme.headerBg};
  }

  & nav {
    display: flex;
    flex-direction: row;
    gap: 24px;

    & li {
      list-style: none;
      a:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 1024px) {
      gap: 12px;
    }

    @media (max-width: 880px) {
      display: none;
    }
  }

  & .revert {
    filter: invert();
  }

  & .mobile {
    display: none;
    position: relative;


    & svg:first-child {
      position: relative;
      top: 4px;
    }

    & svg:last-child {
      position: absolute;
      opacity: 0;
      top: 6px;
      left: 9px;
    }

    &.opened {
      & svg:first-child {
        & rect:first-child {
          transition: 0.5s ease;
          transform-origin: center;
          transform: rotate(-45deg);
          opacity: 0;
        }

        & rect:last-child {
          transition: 0.5s ease;
          transform-origin: center;
          transform: rotate(45deg);
          opacity: 0;
        }
      }

      & svg:last-child {
        transition: 0.5s ease;
        transition-delay: 0.2s;
        opacity: 1;
      }
    }

    @media (max-width: 880px) {
      display: block;
      align-self: flex-end;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 42px;
  @media (max-width: 880px) {
    display: none;
  }
`;
