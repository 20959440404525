import styled from "styled-components";

export const StyledProjectMainInfo = styled.section<{ $maxWidth?: string }>`
  display: grid;
  grid-template-columns: 1fr 1.83fr;
  align-items: center;
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : "100%")};
  margin: 0 auto;
  padding: 64px 0;
  gap: 24px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
  }
`;

export const StyledProjectMainInfoTagsWrapper = styled.div<{
  $titleColor: string;
}>`
  display: grid;
  grid-template-rows: min-content min-content min-content min-content;
  gap: 24px;
  padding: 0 20px;

  @media (max-width: 1024px) {
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: min-content min-content min-content;

    & .services {
      order: -1;
      grid-row: 1 / 4;
    }
  }
  @media (max-width: 478px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content;
    & .services {
      grid-row: 1/2;
      grid-column: 1/3;
    }
  }
`;

export const StyledProjectMainInfoTag = styled.div<{ $titleColor: string }>`
  & h3 {
    color: ${({ $titleColor }) => $titleColor};
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    margin: 0 0 8px;
  }

  & p {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
  }

  & div {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    position: relative;
    left: -2px;
  }
`;
