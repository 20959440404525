import { StyledProjectTimeline } from "./styled";
import ProjectTextSection from "../ProjectTextSection/ProjectTextSection";
import useWindowSize from "../../../utils/useWindowSize";
import ProjectPageCard from "../ProjectPageCard/ProjectPageCard";
import { ProjectMobileCardType } from "../../../types";
import ProjectTimelineMobile from "./ProjectTimelineMobile";

type PropsType = {
  header: Array<string>;
  titleColor: string;
  image: Array<ProjectMobileCardType>;
};

const ProjectTimeline = ({ header, titleColor, image }: PropsType) => {
  const { width } = useWindowSize();
  return (
    <StyledProjectTimeline>
      <ProjectTextSection
        header={header}
        titleColor={titleColor}
        direction={"vertical"}
        maxWidth={"1264px"}
      />
      {width < 1440 ? (
        <ProjectTimelineMobile image={image} />
      ) : (
        <ProjectPageCard items={image} maxWidth={1500} />
      )}
    </StyledProjectTimeline>
  );
};

export default ProjectTimeline;
