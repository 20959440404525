import { IconsWrapper, StyledFooter } from "./styled";
import {
  SocialBDarkIcon,
  SocialBLightIcon,
  SocialCDarkIcon,
  SocialCLightIcon,
  SocialInDarkIcon,
  SocialInLightIcon,
  SocialVDarkIcon,
  SocialVLightIcon,
  TopItCompDarkIcon,
  TopItCompLightIcon,
} from "../Icons/Icons";
import { useTheme } from "styled-components";
import { useEffect } from "react";

const Footer = () => {
  const theme = useTheme();
  const isDark = theme.text === "#fff";

  useEffect(() => {
    if (window.CLUTCHCO) {
      window.CLUTCHCO.Init();
    }
    if (window.GOODFIRMS) {
      window.GOODFIRMS.Init();
    }
  }, []);

  return (
    <StyledFooter>
      <div>
        <h4>contacts</h4>
        <a href="mailto:info@niwart.io">info@niwart.io</a>
      </div>
      <div>
        <h4>office</h4>
        <p>
          <span>Warsaw, Poland</span>
          <span>ul. Aleje Jerozolimskie, nr 85, 02-001</span>
        </p>
      </div>
      <div>
        <h4>socials</h4>
        <IconsWrapper>
          <a
            href="https://www.linkedin.com/company/niwart"
            target={"_blank"}
            rel="noreferrer"
          >
            {isDark ? <SocialInDarkIcon /> : <SocialInLightIcon />}
          </a>
          <a
            href="https://clutch.co/profile/niwart#highlights"
            target={"_blank"}
            rel="noreferrer"
          >
            {isDark ? <SocialCDarkIcon /> : <SocialCLightIcon />}
          </a>
          <a
            href="https://www.goodfirms.co/company/niwart"
            target={"_blank"}
            rel="noreferrer"
          >
            {isDark ? <SocialBDarkIcon /> : <SocialBLightIcon />}
          </a>
          <a
            href="https://www.designrush.com/agency/profile/niwart"
            target={"_blank"}
            rel="noreferrer"
          >
            {isDark ? <SocialVDarkIcon /> : <SocialVLightIcon />}
          </a>
        </IconsWrapper>
      </div>
      <div>
        <IconsWrapper $align={"center"}>
          <a
            href="https://www.designrush.com/agency/profile/niwart"
            target={"_blank"}
            rel="noreferrer"
          >
            {isDark ? <TopItCompDarkIcon /> : <TopItCompLightIcon />}
          </a>
          <div
            className="goodfirm-widget"
            data-widget-type="goodfirms-widget-t1"
            data-company-id="126251"
          ></div>
          <div
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="1"
            data-height="40"
            data-nofollow="true"
            data-expandifr="false"
            data-scale="100"
            data-clutchcompany-id="1940340"
          ></div>
        </IconsWrapper>
      </div>
      <p>©2024 Niwart LLC. All Rights Reserved.</p>
    </StyledFooter>
  );
};

export default Footer;
