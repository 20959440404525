import { StyledHero } from "./styled";
import HeroBackground from "./HeroBackground";
import HeroText from "./HeroText";
import useHasViewedApp from "../../utils/useHasViewedApp";
import { Dispatch, SetStateAction } from "react";

type PropsType = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const Hero = ({ setShowModal }: PropsType) => {
  const hasViewed = useHasViewedApp();
  return (
    <StyledHero>
      <HeroBackground />
      <HeroText isAppViewed={hasViewed} setShowModal={setShowModal} />
    </StyledHero>
  );
};

export default Hero;
