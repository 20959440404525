import React from 'react';
import ContactUs from "../../components/ContactUs/ContactUs";
import PortfolioTitle from "../../components/Portfolio/Title/PortfolioTitle";
import PortfolioContent from "../../components/Portfolio/Content/PortfolioContent";
import seoImg from "../../assets/images/social-image.jpg"
import { Helmet } from 'react-helmet';

const PortfolioPage = () => {
  return (
    <>
      <Helmet>
        <title>Niwart Portfolio | Explore Our Success Stories in Software Development</title>
        <meta name="description" content="Explore Niwart's diverse portfolio, highlighting our successful projects across various industries, demonstrating our expertise and commitment to delivering exceptional software solutions." />
      
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Niwart Portfolio | Explore Our Success Stories in Software Development" />
        <meta property="og:description" content="Explore Niwart's diverse portfolio, highlighting our successful projects across various industries, demonstrating our expertise and commitment to delivering exceptional software solutions." />
        <meta property="og:image" content={seoImg} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Niwart Portfolio | Explore Our Success Stories in Software Development" />
        <meta property="twitter:description" content="Explore Niwart's diverse portfolio, highlighting our successful projects across various industries, demonstrating our expertise and commitment to delivering exceptional software solutions." />
        <meta property="twitter:image" content={seoImg} />
      </Helmet>
      <PortfolioTitle />
      <PortfolioContent />
      <ContactUs />
    </>
  );
};

export default PortfolioPage;