import bgImageLight1080 from "../../assets/images/bh_hero_light.webp";
import bgImageDark1080 from "../../assets/images/bg_hero.webp";
import bgDark1080 from "../../assets/video/Dark Desktop 1080 WEBM.webm";
import bg1080 from "../../assets/video/Desktop 1080 WEBM.webm";
import bgDark1024 from "../../assets/video/Dark Tablet 1024 MP4.mp4";
import bg1040 from "../../assets/video/Tablet 1024 MP4.mp4";
import bgImageLight1024 from "../../assets/images/Tablet 1024.png";
import bgImageDark1024 from "../../assets/images/Dark Tablet 1024.png";
import bgDark480 from "../../assets/video/Dark Mobile 560 MP4.mp4";
import bg480 from "../../assets/video/Mobile 560 MP4.mp4";
import bgImageLight480 from "../../assets/images/Mobile 560.png";
import bgImageDark480 from "../../assets/images/Dark Mobile 560.png";
import { StyledHeroBackground } from "./styled";
import useWindowSize from "../../utils/useWindowSize";

type VideoObjType = {
  desktop: string;
  tablet: string;
  mobile: string;
};

const HeroBackground = () => {
  const { width } = useWindowSize();

  const renderLightVideo = ({ desktop, tablet, mobile }: VideoObjType) => {
    if (width > 870) {
      return (
        <video
          src={desktop}
          autoPlay
          loop
          playsInline
          muted
          poster={bgImageLight1080}
          className={"video-light"}
        />
      );
    }
    if (width < 524) {
      return (
        <video
          src={mobile}
          autoPlay
          loop
          muted
          playsInline
          poster={bgImageLight480}
          className={"video-light"}
        />
      );
    }
    if (width <= 870) {
      return (
        <video
          src={tablet}
          autoPlay
          loop
          muted
          playsInline
          poster={bgImageLight1024}
          className={"video-light"}
        />
      );
    }
  };
  const renderDarkVideo = ({ desktop, tablet, mobile }: VideoObjType) => {
    if (width > 870) {
      return (
        <video
          src={desktop}
          autoPlay
          loop
          playsInline
          muted
          poster={bgImageDark1080}
          className={"video-dark"}
        />
      );
    }
    if (width < 524) {
      return (
        <video
          src={mobile}
          autoPlay
          loop
          playsInline
          muted
          poster={bgImageDark480}
          className={"video-dark"}
        />
      );
    }
    if (width <= 870) {
      return (
        <video
          src={tablet}
          autoPlay
          loop
          muted
          playsInline
          poster={bgImageDark1024}
          className={"video-dark"}
        />
      );
    }
  };

  return (
    <StyledHeroBackground>
      {renderLightVideo({
        desktop: bg1080,
        tablet: bg1040,
        mobile: bg480,
      })}
      {renderDarkVideo({
        desktop: bgDark1080,
        tablet: bgDark1024,
        mobile: bgDark480,
      })}
    </StyledHeroBackground>
  );
};

export default HeroBackground;
