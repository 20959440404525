import styled, { css } from "styled-components";

export const StyledImageLoader = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    div {
      transition: all .45s ease;
    }

    img {
      width: 100%;
      height: 100%;
      transition: all .45s ease;
      object-fit: cover;
    }
`