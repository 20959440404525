import React, { Dispatch, SetStateAction } from 'react';
import { StyledPortfolioContentFilter } from "../styled";
import { ProjectsFilterType } from "../PortfolioContent";
import PortfolioIndustriesFilter from "./PortfolioIndustriesFilter";
import PortfolioServicesFilter from "./PortfolioServicesFilter";
import { ProjectType } from "../../../../types";

type PropsType = {
  filteredProjects: ProjectType[]
  projectsFilter: ProjectsFilterType
  setProjectsFilter: Dispatch<SetStateAction<ProjectsFilterType>>
}

const PortfolioContentFilter = ({ setProjectsFilter, projectsFilter, filteredProjects }: PropsType) => {
  return (
    <StyledPortfolioContentFilter>
      <PortfolioIndustriesFilter filteredProjects={filteredProjects} setProjectsFilter={setProjectsFilter}
                                 projectsFilter={projectsFilter}/>
      <PortfolioServicesFilter filteredProjects={filteredProjects} setProjectsFilter={setProjectsFilter}
                               projectsFilter={projectsFilter}/>
    </StyledPortfolioContentFilter>
  );
};

export default PortfolioContentFilter;