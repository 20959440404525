import { Dispatch, SetStateAction } from "react";
import { StyledMobileMenu } from "./styled";
import DarkModeSwitcher from "../DarkModeSwitcher/DarkModeSwitcher";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";

type PropsType = {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  theme: "light" | "dark";
  toggleMode: () => void;
};

const MobileMenu = ({
  isOpened,
  setIsOpened,
  theme,
  toggleMode,
}: PropsType) => {
  const scrollToContactUs = () => {
    setIsOpened(false);
    document.getElementById("contact-us")?.scrollIntoView();
  };
  const navigate = useNavigate();

  return (
    <StyledMobileMenu className={isOpened ? "visible" : ""}>
      <nav>
        <a href={"/#services"} onClick={() => setIsOpened(false)}>
          Services
        </a>
        <Link to={"/portfolio"} onClick={() => setIsOpened(false)}>
          Portfolio
        </Link>
        <a href={"/#stories"} onClick={() => setIsOpened(false)}>
          Stories
        </a>
      </nav>
      <div>
        <Button
          type={"primary"}
          text={`Contact Us`}
          onClick={() => {setIsOpened(false); navigate('/contacts')}}
        />
        <div className={"switcherWrapper"}>
          <DarkModeSwitcher theme={theme} toggleMode={toggleMode} />
          <p>{theme} Mode</p>
        </div>
      </div>
    </StyledMobileMenu>
  );
};

export default MobileMenu;
