import { useNavigate, useParams } from "react-router-dom";
import { projectsData } from "../../data/projectsData";
import { useLayoutEffect } from "react";
import ContactUs from "../../components/ContactUs/ContactUs";

const ProjectPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const card = projectsData.find((el) => el.href === id);
  useLayoutEffect(() => {
    if (!card) {
      navigate("/portfolio");
    }
  }, []);

  const renderComponent = () => {
    if (card && card.projectPage) {
      return card.projectPage.map((item, index) => {
        const Component = item.component;

        // @ts-ignore
        return <Component key={index} {...item.props} />;
      });
    } else {
      return <></>;
    }
  };

  return (
    
    <article className={"projectPage"}>
      {renderComponent()}
      <ContactUs />
    </article>
  );
};

export default ProjectPage;
