import { ProjectType } from "../../types";
import visas_lower from "../../assets/images/projects/visas_lower.webp";
import visas_middle from "../../assets/images/projects/visas_middle.webp";
import visas_higher from "../../assets/images/projects/visas_higher.webp";
import visas from "../../assets/images/portfolio/visas.webp";
import visas_mobile from "../../assets/images/portfolio/visas_mobile.webp";
import ProjectHeaderText from "../../components/Project/ProjectHeaderText/ProjectHeaderText";
import ProjectPageCard from "../../components/Project/ProjectPageCard/ProjectPageCard";
import visasai_desktop from "../../assets/images/project/visasai/visasai-hero-img.png";
import visasai_automatic_issues_desktop from "../../assets/images/project/visasai/Automatic-Issues-Identification.png";
import ai_chatbot_desktop from "../../assets/images/project/visasai/ai-chatbot.png";
import smart_questionnaire_desktop from "../../assets/images/project/visasai/smart-questionnaire.png";
import community_answers_desktop from "../../assets/images/project/visasai/community-answers.png";
import document_assemble_desktop from "../../assets/images/project/visasai/document-assemble.png";
import project_outcomes1_desktop from "../../assets/images/project/visasai/project-outcomes1.png";
import project_outcomes2_desktop from "../../assets/images/project/visasai/project-outcomes2.png";
import project_outcomes3_desktop from "../../assets/images/project/visasai/project-outcomes3.png";
import ProjectMainInfo from "../../components/Project/ProjectMainInfo/ProjectMainInfo";
import ProjectGoals from "../../components/Project/ProjectGoals/ProjectGoals";
import ProjectTextSection from "../../components/Project/ProjectTextSection/ProjectTextSection";
import ProjectStack from "../../components/Project/ProjectStack/ProjectStack";
import { PythonIcon, ReactIcon, ReduxIcon, TsIcon } from "../../components/Icons/Icons";

export const visasaiData: ProjectType = {
  name: "Intelligent Immigration Platform",
  industry: "LegalTech",
  services: [
    "Web App Development",
    "UX/UI Design",
    "Information Architecture",
    "Wireframing & Prototyping",
    "CMS Development",
    "QA",
  ],
  portfolio_image: visas,
  portfolio_image_hash: 'LP4MuQtQj[VrXYaef5kDRXVtflo#',
  portfolio_mobile: visas_mobile,
  color: "#fff",
  description:
    "Our team has helped Visas.ai to build the first AI-based Intelligent Platform for US immigration attorneys to improve visa approval ratings. The platform combines an intuitive user experience enhanced by the power of AI.",
  image: {
    color: "#114D9C",
    lower: visas_lower,
    middle: visas_middle,
    higher: visas_higher,
  },
  href: "intelligent-immigration-platform",
  projectPage: [
    {
      component: ProjectHeaderText,
      props: {
        seoTitle: "Niwart Case Study | Intelligent Immigration Platform",
        seoDescription: "Discover how Niwart collaborated with Visas.AI to deliver a revolutionary Immigration Platform, showcasing our expertise in AI. Explore our case study now.",
        title: "Intelligent Immigration Platform ",
        titleColor: `#0080FF`,
        description:
          "Our team has helped Visas.ai build the first AI-based Intelligent Platform for US immigration attorneys to improve visa approval ratings. The Platform combines an intuitive user experience enhanced by the power of AI.",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: visasai_desktop,
              tablet: visasai_desktop,
              mobile: visasai_desktop,
            },
          },
        ],
        maxWidth: 1920,
        padding: "0",
      },
    },
    {
      component: ProjectMainInfo,
      props: {
        header: ["Challenge"],
        text: [
          "Motivated to revolutionize the legal sphere and rejuvenate its Intelligent Immigration Platform, OnlineVisas embarked on a comprehensive revamp initiative. Faced with challenges such as an outdated platform design, poor user experience, and a lack of groundbreaking functionalities, we aimed to enhance usability, introduce modern features, including AI integrations, and establish a dedicated website to promote the Platform effectively. This strategic overhaul aimed to transform the Intelligent Immigration Platform into a more accessible, user-centric solution, enabling immigration attorneys to gain a competitive advantage, elevate approval rates, and better serve their clients' needs.",
        ],
        direction: "vertical",
        titleColor: `#0080FF`,
        gap: "18px",
        maxWidth: "1264px",
        industry: "LegalTech",
        location: "USA",
        services: [
          "Web App Development",
          "UX/UI Design",
          "Information Architecture",
          "CMS Development",
          "Wireframing & Prototyping",
          "QA",
        ],
      },
    },
    {
      component: ProjectGoals,
      props: {
        headerText: ["Project", "Goals"],
        titleColor: `#0080FF`,
        goals: [
          {
            title: ["Integration of cutting-edge AI technologies"],
            text: "Implementing revolutionary AI features to provide lawyers with a competitive edge, granting access to pioneer functionalities in the legal industry.",
          },
          {
            title: ["Usability Enhancement"],
            text: "Incorporating a visually engaging and intuitive interface, refining navigation for enhanced user engagement, and optimizing design elements to create a compelling and user-friendly environment, aligning with the client's objectives.",
          },
          {
            title: ["Platform's online presence improvement"],
            text: "The objective was to develop an aesthetically pleasing website that acts as a compelling promotional tool for the Visas.ai platform. This entails designing a visually engaging interface that grabs attention and effectively communicates the application's key features, benefits, and unique selling points, encouraging potential users to explore and engage with the Platform.",
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Automatic Issues", "Identification"],
        text: [
          "An AI-based algorithm was designed to analyze an uploaded document, automatically identify issues and sub-issues of the document, and highlight them for the users.",
        ],
        titleColor: `#0080FF`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: visasai_automatic_issues_desktop,
              tablet: visasai_automatic_issues_desktop,
              mobile: visasai_automatic_issues_desktop,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 0px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["AI Chatbot", "Assistant (Ovie)"],
        titleColor: `#0080FF`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "An intelligent chatbot assistant (Ovie) was developed to support users by answering process-related questions and assisting them by providing helpful information and hints.",
              align: "start",
              padding: "0",
              maxWidth: "250px",
              position: {
                desktop: "right",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: ai_chatbot_desktop,
              tablet: ai_chatbot_desktop,
              mobile: ai_chatbot_desktop,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Smart    ", "Questionnaire"],
        text: [
          "The Questionnaire flow streamlines document completion with a user-friendly interface, customizable questions, and intelligent logic, ensuring a secure and efficient data entry experience.",
        ],
        titleColor: `#0080FF`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: smart_questionnaire_desktop,
              tablet: smart_questionnaire_desktop,
              mobile: smart_questionnaire_desktop,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 0px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Community", "Answers"],
        titleColor: `#0080FF`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "Users can consult the community answer database to discover the most suitable solution for their case. The database is constantly growing and is populated by specialists using the Platform.",
              align: "start",
              padding: "0",
              maxWidth: "250px",
              position: {
                desktop: "right",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: community_answers_desktop,
              tablet: community_answers_desktop,
              mobile: community_answers_desktop,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Document", "Assemble"],
        text: [
          "Ability to merge multiple files into one document with a pre-determined format.",
        ],
        titleColor: `#0080FF`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: document_assemble_desktop,
              tablet: document_assemble_desktop,
              mobile: document_assemble_desktop,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 0px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Project", "Outcomes"],
        titleColor: `#0080FF`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: project_outcomes1_desktop,
              tablet: project_outcomes1_desktop,
              mobile: project_outcomes1_desktop,
            },
            text: {
              title: "High-class User Interface and Architecture ",
              data: "Our design solution successfully transformed the web app's user interface by prioritizing user-centric principles and implementing intuitive navigation. A strategic, user-centric approach, intuitive navigation, and cohesive design elements contributed to an enhanced user experience, marking notable progress in architecture, usability, and client satisfaction.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: project_outcomes2_desktop,
              tablet: project_outcomes2_desktop,
              mobile: project_outcomes2_desktop,
            },
            text: {
              title: "Integration of new AI functionalities",
              data: "The strategic integration of innovative features, including cutting-edge AI technologies, has significantly enhanced the Platform, providing users with a more dynamic and enriched experience. This transformation comprehensively addresses user needs and positions Visas.ai as a versatile and advanced solution, fostering increased engagement and satisfaction among its users.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: project_outcomes3_desktop,
              tablet: project_outcomes3_desktop,
              mobile: project_outcomes3_desktop,
            },
            text: {
              title: "New website creation",
              data: "We have successfully launched a cutting-edge website dedicated to promoting the Visas.ai Platform. Featuring a modern and user-friendly design, the website was meticulously crafted with the latest design principles to ensure a seamless and engaging user experience. Additionally, it was built using industry-best SEO practices, strategically optimizing content and structure to enhance visibility and accessibility, further establishing their online presence and effectively showcasing the capabilities of the Platform.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Tech", "Stack"],
        titleColor: `#0080FF`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectStack,
      props: {
        frontendStack: [
          {
            svg: ReactIcon,
            name: 'ReactJS'
          },
          {
            svg: TsIcon,
            name: 'TypeScript'
          },
          {
            svg: ReduxIcon,
            name: 'Redux'
          },
        ],
        backendStack: [
          {
            svg: PythonIcon,
            name: 'Python'
          },
        ]
      },
    },
  ]
}