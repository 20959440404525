import { StyledIntroduce } from "./styled";
import { ReactElement, useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const phrase =
  "At Niwart we bring together experienced IT specialists to provide professional services for businesses seeking solutions that combine the elements of technology and art.";

const Introduce = () => {
  let refs = useRef<Array<HTMLParagraphElement>>([]);
  const container = useRef(null);

  const splitWords = (phrase: string) => {
    let arr: Array<ReactElement<HTMLParagraphElement>> = [];
    phrase.split(" ").forEach((word, i) => {
      arr.push(
        <p
          key={word + "_" + i}
          ref={(el: HTMLParagraphElement) => {
            refs.current.push(el);
          }}
        >
          {word}
        </p>,
      );
    });

    return arr;
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    createAnimations();
  }, []);

  const createAnimations = () => {
    gsap.to(refs.current, {
      opacity: 1,
      stagger: 0.5,
      ease: "power2.inOut",
      scrollTrigger: {
        trigger: container.current,
        start: "top",
        end: `+=${window.innerHeight * 1.5}`,
        scrub: true,
      },
    });
  };

  return (
    <StyledIntroduce ref={container}>
      <div>{splitWords(phrase)}</div>
    </StyledIntroduce>
  );
};

export default Introduce;
