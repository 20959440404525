import React from "react";
import { StyledServiceCard, TitleWrapper } from "./styled";
import { RightNeonArrow, TapIcon } from "../Icons/Icons";

type PropsType = {
  index?: number;
  title: string;
  subTitles: Array<string>;
  hoveredSubTitle: string;
  className?: string;
};

const ServiceCard = ({
  hoveredSubTitle,
  subTitles,
  title,
  className,
  index,
}: PropsType) => {
  const animProps =
    index !== undefined
      ? {
          initial: "hidden",
          whileInView: "visible",
          viewport: { once: true, amount: 0.5 },
          variants: {
            hidden: {
              opacity: 0,
              y: 100,
            },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                type: "easeOut",
                delay: index * 0.049,
                duration: 0.8,
              },
            },
          },
        }
      : {};

  return (
    <StyledServiceCard
      className={"keen-slider__slide"}
      style={{ maxWidth: 288, minWidth: 288, width: 288 }}
      {...animProps}
    >
      <TitleWrapper>
        <h3>{title}</h3>
      </TitleWrapper>
      <div className="tags">
        <div className={"subTitle"}>{hoveredSubTitle}</div>
        {subTitles.map((el, i) => (
          <div key={i} className={"tag"}>
            <RightNeonArrow /> {el}
          </div>
        ))}
      </div>
      <TapIcon className={"tap"} />
    </StyledServiceCard>
  );
};

export default ServiceCard;
