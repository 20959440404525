import { NonSlideCards, StyledServices, StyledServicesWrapper } from "./styled";
import "keen-slider/keen-slider.min.css";
import ServiceCard from "./ServiceCard";
import ServicesSlider from "./ServicesSlider";
import useWindowSize from "../../utils/useWindowSize";
import ServicesBg from "./ServicesBg";
import { servicesData } from "../../data/servicesData";
import Button from "../Button/Button";
import { Dispatch, SetStateAction } from "react";

type PropsType = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const Services = ({ setShowModal }: PropsType) => {
  const { width } = useWindowSize();

  return (
    <StyledServicesWrapper id={"services"}>
      <ServicesBg className={"alignedLeft"} />
      <ServicesBg className={"alignedRight"} />
      <StyledServices>
        <h2>
          Our <span>Services</span>
        </h2>
        <NonSlideCards>
          {servicesData.map((data, i) => (
            <ServiceCard
              key={i}
              index={i}
              title={data.title}
              subTitles={data.subTitles}
              hoveredSubTitle={data.hoveredSubTitle}
            />
          ))}
        </NonSlideCards>
        {width < 1241 && <ServicesSlider />}
        <Button
          type={"primary"}
          text={`Let Us Help You`}
          onClick={() =>
            setShowModal(true)
          }
        />
      </StyledServices>
    </StyledServicesWrapper>
  );
};

export default Services;
