import { ProjectType } from "../types";
import cloud_lower from "../assets/images/projects/cloud_lower.webp";
import cloud_middle from "../assets/images/projects/cloud_middle.webp";
import cloud_higher from "../assets/images/projects/cloud_higher.webp";
import cyberSec from "../assets/images/portfolio/security.webp";
import cyberSec_mobile from "../assets/images/portfolio/security_mobile.webp";
import webPresense from "../assets/images/portfolio/web_presence.webp";
import webPresenseMobile from "../assets/images/portfolio/web_presence_mobile.webp";
import swissSmartphone from "../assets/images/portfolio/swiss_smartphone.webp";
import swissSmartphoneMobile from "../assets/images/portfolio/swiss_smartphone_mobile.webp";
import aiAnalytics from "../assets/images/portfolio/ai_analitics.webp";
import aiAnalyticsMobile from "../assets/images/portfolio/ai_analitics_mobile.webp";
import wordpressAuction from "../assets/images/portfolio/wordpress_auction.webp";
import wordpressAuctionMobile from "../assets/images/portfolio/wordpress_auction_mobile.webp";
import ezedit from "../assets/images/portfolio/ezedit.webp";
import ezedit_mobile from "../assets/images/portfolio/ezedit_mobile.webp";
import cere from "../assets/images/portfolio/cere.webp";
import cere_mobile from "../assets/images/portfolio/cere_mobile.webp";
import fitness_app from "../assets/images/portfolio/fitness_app.webp";
import fitness_app_mobile from "../assets/images/portfolio/fitness_app_mobile.webp";
import empire from "../assets/images/portfolio/empire.webp";
import empire_mobile from "../assets/images/portfolio/empire_mobile.webp";
import icr from "../assets/images/portfolio/icr.webp";
import icr_mobile from "../assets/images/portfolio/icr_mobile.webp";
import intralink from "../assets/images/portfolio/intralinks.webp";
import intralink_mobile from "../assets/images/portfolio/intralinks.webp";
import marketing_agency from "../assets/images/portfolio/marketing_agency.webp";
import marketing_agency_mobile from "../assets/images/portfolio/marketing_agency_mobile.webp";
import health_data from "../assets/images/portfolio/health-data.webp";
import health_data_mobile from "../assets/images/portfolio/health-data-mobile.webp";
import british_healthcare from "../assets/images/portfolio/British-Healthcare.webp";
import british_healthcare_mobile from "../assets/images/portfolio/British-Healthcare-mobile.webp";
import cloud_solution from "../assets/images/portfolio/Cloud-Solution.webp";
import cloud_solution_mobile from "../assets/images/portfolio/Cloud-Solution-mobile.webp";
import { refmetrixData } from "./refmetrix/refmetrixData";
import { forensicsData } from "./forensics/forensicsData";
import { exam_platform } from "./exam_platform/exam_platform";
import { visasaiData } from "./visasai/visasaiData";
import { chainjoesData } from "./chainjoes/chainjoesData";

export const projectsData: Array<ProjectType> = [
  forensicsData,
  visasaiData,
  refmetrixData,
  exam_platform,
  {
    name: "Cybersecurity AI Platform",
    industry: "Cybersecurity",
    services: [
      "Web App Development",
      "CMS Development",
      "UX/UI Design",
      "IT Support & Maintenance",
      "QA",
    ],
    portfolio_image: cyberSec,
    portfolio_image_hash: 'LQ5vR|e.pwkWp^kCa0aKqFa0Z$kq',
    portfolio_mobile: cyberSec_mobile,
    color: "#05070D",
    description:
      "CloudGuard is a next-generation cybersecurity platform that balances human and machine intelligence to continuously optimize risk and security postures designed by Niwart. Our partnership with CloudGuard since 2021 includes cooperation in many different directions, such as UX/UI design, CMS Development, continuous support, and maintenance services.",
    image: {
      color: "#3BCBCC",
      lower: cloud_lower,
      middle: cloud_middle,
      higher: cloud_higher,
    },
  },
  {
    name: "Revamped Brand Palette for Intralinks",
    industry: "FinTech",
    services: [
      "IT Strategy and Planning",
      "Market Research & Analysis",
      "Wireframing & Prototyping",
      "Branding and Content Strategy",
    ],
    color: "#fff",
    portfolio_image: intralink,
    portfolio_image_hash: 'LJEVFm9U.AMaDk.79I-i?YD,%GRo',
    portfolio_mobile: intralink_mobile,
    description: "",
    isFav: true,
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Web Presence Solutions for Boutique Consultancy",
    href: 'https://www.smart-co.co.uk/',
    industry: "Digital Transformation",
    services: [
      "Technology Consulting",
      "UX/UI Design",
      "CMS Development",
      "IT Support & Maintenance",
      "Branding and Content Strategy",
    ],
    color: "#fff",
    portfolio_image: webPresense,
    portfolio_image_hash: 'LVG]Ih?c4U9i0uE7%K%N0NadxpkR',
    portfolio_mobile: webPresenseMobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Swiss-made Secure Smartphone",
    industry: "Cybersecurity",
    services: [
      "IT Strategy and Planning",
      "Market Research & Analysis",
      "UX/UI Design",
      "Information Architecture",
      "Website Creation",
    ],
    color: "#fff",
    portfolio_image: swissSmartphone,
    portfolio_image_hash: 'L127L3Q88%YQHXlTrmoND4qFyqU]',
    portfolio_mobile: swissSmartphoneMobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "AI-powered Data Analytics Platform",
    industry: "Data Analytics",
    services: [
      "UX/UI Design",
      "Information Architecture",
      "Web App Development",
      "Website Creation",
      "Quality Assurance and Testing",
    ],
    color: "#fff",
    portfolio_image: aiAnalytics,
    portfolio_image_hash: 'LDJbBW0000~q00%L?b.8aO.7IUIU',
    portfolio_mobile: aiAnalyticsMobile,
    description: "",
    isFav: true,
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "The First WordPress-based Auction",
    industry: "Ecommerce",
    services: [
      "Technology Consulting",
      "UX/UI Design",
      "Information Architecture",
      "CMS Development",
      "Quality Assurance and Testing",
      "IT Support & Maintenance",
    ],
    color: "#05070D",
    portfolio_image: wordpressAuction,
    portfolio_image_hash: 'LyJt|At7xnNG~eayRkj@?7Rjt7t7',
    portfolio_mobile: wordpressAuctionMobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Marketing Agency's Website",
    href: 'https://the3rd.de/',
    industry: "Marketing",
    services: [
      "Technology Consulting",
      "Website Creation",
      "CMS Development",
      "Quality Assurance and Testing",
      "IT Support & Maintenance",
    ],
    color: "#fff",
    portfolio_image: marketing_agency,
    portfolio_image_hash: 'LYELj.59-.R95L%0M}o_9jr=WWkU',
    portfolio_mobile: marketing_agency_mobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  chainjoesData,
  {
    name: "Content Creation Platform for Influencers",
    href: 'https://www.ezedit.com/',
    industry: "Video Production",
    services: [
      "IT Strategy and Planning",
      "UX/UI Design",
      "Information Architecture",
      "Wireframing & Prototyping",
      "Web App Development",
      "Quality Assurance and Testing",
    ],
    color: "#fff",
    portfolio_image: ezedit,
    portfolio_image_hash: 'LBRA:**0K64T_ODQbX%}_Ovj%M-=',
    portfolio_mobile: ezedit_mobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Home Fitness Application",
    industry: "SportTech",
    services: [
      "UX/UI Design",
      "Information Architecture",
      "Wireframing & Prototyping",
      "Market Research & Analysis",
      "Web App Development",
      "Quality Assurance and Testing",
    ],
    color: "#fff",
    portfolio_image: fitness_app,
    portfolio_image_hash: 'L7D+[2^i0001OX0M_NtP00I9_3?b',
    portfolio_mobile: fitness_app_mobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Mental Health Diagnostic Platform",
    industry: "HealthTech",
    services: [
      "Technology Consulting",
      "UX/UI Design",
      "Information Architecture",
      "Wireframing & Prototyping",
      "Web App Development",
      "Quality Assurance and Testing",
    ],
    color: "#05070D",
    portfolio_image: cere,
    portfolio_image_hash: 'LOQ0jn-;.88_~qVsMx%g.8R*Mxxu',
    portfolio_mobile: cere_mobile,
    isFav: true,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Health Data Analytics Tool",
    href: 'https://vertedge.io/',
    industry: "HealthTech",
    services: [
      "Technology Consulting",
      "UX/UI Design",
      "CMS Development",
      "IT Support & Maintenance",
      "Branding and Content Strategy",
    ],
    color: "#fff",
    portfolio_image: health_data,
    portfolio_image_hash: 'LPJt}y~n9JIT4ibJ%Iai07M_WFt9',
    portfolio_mobile: health_data_mobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Mobile Gaming Studio's Website",
    href: 'https://empiregames.studio/',
    industry: "Game Development",
    services: [
      "Technology Consulting",
      "UX/UI Design",
      "Website Creation",
      "CMS Development",
      "IT Support & Maintenance",
      "Branding and Content Strategy",
    ],
    color: "#fff",
    portfolio_image: empire,
    portfolio_image_hash: 'L6Bo:dxb8^nL00M_%%kD03$jroRS',
    portfolio_mobile: empire_mobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Redesigned Aesthetics for an Australian Advisory",
    industry: "Advisory",
    services: [
      "IT Strategy and Planning",
      "UX/UI Design",
      "Market Research & Analysis",
      "CMS Development",
      "IT Support & Maintenance",
    ],
    color: "#05070D",
    portfolio_image: icr,
    portfolio_image_hash: 'LOIGZD00S#}[55-pr@RjggRPs:bu',
    portfolio_mobile: icr_mobile,
    isFav: true,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "British Healthcare Startup's Website",
    href: 'https://modlappe.com/',
    industry: "Healthcare",
    services: [
      "Technology Consulting",
      "UX/UI Design",
      "Website Creation",
      "CMS Development",
      "IT Support & Maintenance",
    ],
    color: "#fff",
    portfolio_image: british_healthcare,
    portfolio_image_hash: 'L7Lqnk9#000000Iqxaxu00.7~V-p',
    portfolio_mobile: british_healthcare_mobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
  {
    name: "Cloud Solution Provider",
    href: 'https://www.optiim.io/',
    industry: "Cloud Computing",
    services: [
      "Technology Consulting",
      "UX/UI Design",
      "Branding and Content Strategy",
      "CMS Development",
      "IT Support & Maintenance",
    ],
    color: "#fff",
    portfolio_image: cloud_solution,
    portfolio_image_hash: 'LAA981V@}ZV@0xt75Qofv~R*tkWB',
    portfolio_mobile: cloud_solution_mobile,
    description: "",
    image: {
      color: "",
      lower: "",
      middle: "",
      higher: "",
    },
  },
];
