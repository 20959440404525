import React from "react";
import { StyledProjectBtns, StyledProjectDescription, StyledProjectInfo, StyledProjectServices, } from "./styled";
import RoundTag from "../RoundTag/RoundTag";
import Button from "../Button/Button";
import { ProjectType } from "../../types";
import { useNavigate } from "react-router-dom";

type PropsType = {
  project: ProjectType;
};

const ProjectsInfo = ({ project }: PropsType) => {
  const navigate = useNavigate();

  return (
    <StyledProjectInfo>
      <StyledProjectServices>
        <h2>{project.name}</h2>
        <h3>Industry</h3>
        <div>
          <RoundTag
            text={project.industry}
            onClick={() => navigate(`/portfolio?industries=${project.industry}`)}
          />
        </div>
      </StyledProjectServices>
      <StyledProjectServices>
        <h2>{project.name}</h2>
        <h3>Services</h3>
        <div>
          {project.services.map((service, ind) => (
            <RoundTag text={service} key={ind} onClick={() => navigate(`/portfolio?service=${service}`)} />
          ))}
        </div>
      </StyledProjectServices>
      <StyledProjectDescription>
        <h3>Description</h3>
        <p>{project.description}</p>
      </StyledProjectDescription>
      <StyledProjectBtns>
        {project.href &&
          <Button
            type={"primary"}
            text={`Explore  Case`}
            onClick={() =>
              navigate(`/portfolio/${project.href}`)
            }
          />
        }
        <Button text={"View  More Cases"} type={"secondary"}
          onClick={() => navigate('/portfolio')} />
      </StyledProjectBtns>
    </StyledProjectInfo>
  );
};

export default ProjectsInfo;
