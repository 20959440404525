import { ProjectType } from "../../types";
import ProjectHeaderText from "../../components/Project/ProjectHeaderText/ProjectHeaderText";
import ProjectPageCard from "../../components/Project/ProjectPageCard/ProjectPageCard";
import forensics_hero_desktop from "../../assets/images/project/forensics/forensics_hero_desktop.png";
import ProjectMainInfo from "../../components/Project/ProjectMainInfo/ProjectMainInfo";
import ProjectGoals from "../../components/Project/ProjectGoals/ProjectGoals";
import ProjectTimeline from "../../components/Project/ProjectTimeline/ProjectTimeline";
import refmetrix_outcomes_item_desktop from "../../assets/images/project/refmetrix/refmetrix_outcomes_item_desktop.png";
import exam from "../../assets/images/portfolio/exam.webp";
import exam_mobile from "../../assets/images/portfolio/exam_mobile.webp";
import the3rd_lower from "../../assets/images/projects/the3rd_lower.webp";
import the3rd_middle from "../../assets/images/projects/the3rd_middle.webp";
import the3rd_higher from "../../assets/images/projects/the3rd_higher.webp";
import timeline from "../../assets/images/project/exam_platform/examp_platform_timeline.png";
import ProjectTextSection from "../../components/Project/ProjectTextSection/ProjectTextSection";

export const exam_platform: ProjectType = {
  name: "Exam Preparation Platform",
  industry: "EdTech",
  // href: "exam_platform",
  services: [
    "UX/UI Design",
    "Information Architecture",
    "Wireframing & Prototyping",
    "Market Research & Analysis",
  ],
  color: "#05070D",
  portfolio_image: exam,
  portfolio_image_hash: 'L7LYQr0BRX0D0I9h~2^y]}5EIu${',
  portfolio_mobile: exam_mobile,
  description:
    "The project focused on crafting an intuitive UX/UI design for a US student-centric learning platform, simplifying school exam preparation. We've built the platform's entire architecture from scratch, ensuring a robust foundation for this innovative educational resource.",
  image: {
    color: "#3699FF",
    lower: the3rd_lower,
    middle: the3rd_middle,
    higher: the3rd_higher,
  },
  projectPage: [
    {
      component: ProjectHeaderText,
      props: {
        seoTitle: "Niwart Case Study | Forensics Solution Provider's Website",
        seoDescription: "Uncover the story behind Niwart's impactful contribution to enhance SalvationData's web presence to attract new users and increase sales.",      
        title: "Exam Preparation Platform",
        titleColor: `#00A3FF`,
        description:
          "Innovative educational platform, designed for  tackling ISEE, SSAT, SHSAT exams, seamlessly unites students, parents, and teachers. It offers effective exam preparation, real-time progress tracking, and interactive online lessons, creating a comprehensive educational space",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: forensics_hero_desktop,
              tablet: forensics_hero_desktop,
              mobile: forensics_hero_desktop,
            },
          },
        ],
        maxWidth: 1920,
        padding: "0",
      },
    },
    {
      component: ProjectMainInfo,
      props: {
        header: ["Challenge"],
        text: [
          "Our project emerged from the client's captivating idea: to create an educational platform for U.S. students, aligning with the standards of ISEE, SSAT, SHSAT exams. However, the challenge was how to unite students, parents, and teachers in one application.",
          "We started from scratch, building a robust architecture for an innovative educational resource. Our approach involved integrating functionality for students (exam preparation and taking), parents (tracking their children's progress), and teachers (conducting online lessons).",
          "We tackled the task not just to create a platform but to ensure its effectiveness for all users. As a result, students successfully prepare for and take exams, parents instantly monitor their children's progress, and teachers conduct effective online lessons. Our goal is to create a universal educational space, providing a complete cycle of learning and collaboration.",
        ],
        direction: "vertical",
        titleColor: `#00A3FF`,
        gap: "18px",
        maxWidth: "1264px",
        industry: "EdTech",
        location: "New York, USA",
        services: [
          "UX/UI Design",
          "Information Architecture",
          "Wireframing & Prototyping",
          "Market Research & Analysis",
        ],
      },
    },
    {
      component: ProjectGoals,
      props: {
        headerText: ["Project", "Goals"],
        titleColor: `#00A3FF`,
        goals: [
          {
            title: ["Holistic Learning Integration"],
            text: "Seamlessly unite exam preparation tools, progress tracking, and online teaching functionalities, creating a harmonious ecosystem that caters to the needs of students, parents, and teachers",
          },
          {
            title: ["Innovative User-Centric Design"],
            text: "Develop an intuitive and visually appealing UX/UI that not only adheres to educational standards but also prioritizes user satisfaction, ensuring a positive experience for all platform users",
          },
          {
            title: ["Effective Learning Ecosystem"],
            text: "Establish a dynamic learning environment by enabling effective exam preparation, real-time progress updates, and engaging online lessons, empowering students in their educational journey",
          },
        ],
      },
    },
    {
      component: ProjectTimeline,
      props: {
        header: ["Project", "Timeline"],
        titleColor: `#00A3FF`,
        image: [
          {
            image: {
              desktop: timeline,
              tablet: timeline,
              mobile: timeline,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Project", "Outcomes"],
        titleColor: `#00A3FF`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: refmetrix_outcomes_item_desktop,
              tablet: refmetrix_outcomes_item_desktop,
              mobile: refmetrix_outcomes_item_desktop,
            },
            text: {
              title: "Gamified Learning Hub",
              data: "Successfully developed an educational hub with gamification elements, providing an engaging and user-friendly space for students.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: refmetrix_outcomes_item_desktop,
              tablet: refmetrix_outcomes_item_desktop,
              mobile: refmetrix_outcomes_item_desktop,
            },
            text: {
              title: "Parental Progress Dashboard",
              data: "Introduced a parent-friendly feature, the student progress card, allowing parents to have a clear and accessible overview of their child's academic performance.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: refmetrix_outcomes_item_desktop,
              tablet: refmetrix_outcomes_item_desktop,
              mobile: refmetrix_outcomes_item_desktop,
            },
            text: {
              title: "Multifunctional Teacher Toolkit",
              data: "A robust toolkit tailored for teachers, providing diverse resources and features to streamline exam preparation strategies.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
  ],
};
