import {
  StyledProjectMainInfoTag,
  StyledProjectMainInfoTagsWrapper,
} from "./styled";
import RoundTag from "../../RoundTag/RoundTag";
import { useNavigate } from "react-router-dom";

type PropsType = {
  location: string;
  services: Array<string>;
  industry: string;
  titleColor: string;
};

const ProjectMainInfoTagsWrapper = ({
  services,
  industry,
  location,
  titleColor,
}: PropsType) => {
  const navigate = useNavigate();
  
  return (
    <StyledProjectMainInfoTagsWrapper $titleColor={titleColor}>
      <StyledProjectMainInfoTag $titleColor={titleColor}>
        <h3>Industry</h3>
        <p>{industry}</p>
      </StyledProjectMainInfoTag>
      <StyledProjectMainInfoTag $titleColor={titleColor} className={"services"}>
        <h3>Services</h3>
        <div>
          {services.map((item, index) => (
            <RoundTag key={index} text={item} onClick={() => navigate(`/portfolio?service=${item}`)} />
          ))}
        </div>
      </StyledProjectMainInfoTag>
      <StyledProjectMainInfoTag $titleColor={titleColor}>
        <h3>Location</h3>
        <p>{location}</p>
      </StyledProjectMainInfoTag>
    </StyledProjectMainInfoTagsWrapper>
  );
};

export default ProjectMainInfoTagsWrapper;
