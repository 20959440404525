import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const StyledPortfolioContent = styled.section`
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1280px;
`;

export const StyledPortfolioContentFilter = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }
  @media (max-width: 550px) {
    display: none;
  }
`;

export const StyledPortfolioContentFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const StyledPortFolioFilter = styled.div`
  & > p {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 4px;
    margin: 0 0 12px;
    color: ${({ theme }) => theme.projects.titleColor};
    background: ${({ theme }) => theme.projects.titleBg};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-height: 690px) {
    & > p {
      margin: 0 0 6px;
    }
  }
`;

export const StyledPortfolioCards = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding-top: 32px;
  @media (max-width: 912px) {
    grid-template-columns: 1fr;
    max-width: 600px;
    margin: 0 auto;
  }
`;

export const StyledPortfolioCardItem = styled(motion.div) <{
  $isfav: boolean;
  $color: string;
}>`
  background: ${({ theme }) => theme.tag.bg};
  padding: 32px;
  border-radius: 12px;
  user-select: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
  color: ${({ $color }) => $color};

  img {
    top: 0;
    left: 0;
    position: absolute;
  }

  &:hover {
    img {
      transform: scale(1.035);
    }
  }

  ${({ $isfav }) =>
    $isfav
      ? css`
          height: 612px;
          grid-column: span 2;
          @media (max-width: 1024px) {
            height: auto;
            aspect-ratio: 2/1;
          }
          @media (max-width: 912px) {
            grid-column: span 1;
          }
          @media (max-width: 512px) {
            aspect-ratio: 0.75/1;
          }
        `
      : css`
          height: 450px;
          grid-column: span 1;
          @media (max-width: 1024px) {
            height: auto;
            aspect-ratio: 1.2/1;
          }
          @media (max-width: 512px) {
            aspect-ratio: 0.75/1;
          }
        `}
  & h3 {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin-top: 12px;
    z-index: 1;
    position: relative;
    text-shadow: ${({ $color }) =>
    $color === "#fff"
      ? "0 3px 12px rgba(0, 0, 0, 0.27)"
      : "3px 3px 9px rgba(0, 0, 0, 0.1)"};
  }

  @media (max-width: 1024px) {
    padding: 16px;
    & h3 {
      font-size: 22px;
      line-height: 28px;
    }
  }
`;

export const StyledPortfolioMobileFilterButton = styled.div<{
  $ischecked: boolean;
}>`
  display: none;
  position: relative;
  padding: 7px 12px 9px;
  border-radius: 100px;
  background: ${({ theme, $ischecked }) =>
    $ischecked
      ? ` linear-gradient(
        to bottom,
        ${theme.buttons.primary.hoverBg},
         ${theme.buttons.primary.hoverBg}
      )
      padding-box,
      ${theme.buttons.primary.hoverBorder}
      border-box;`
      : `transparent`};
  border: ${({ theme, $ischecked }) =>
    $ischecked ? `0.7px solid transparent` : `0.7px solid ${theme.text}`};

  &:after {
    content: "";
    position: absolute;
    right: 18px;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 6px;
    border-color: ${({ theme }) =>
    `transparent transparent transparent ${theme.text}`};
    transform: translateY(-45%);
  }

  & span {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 550px) {
    display: block;
  }
`;

export const StyledMobilePortfolioFilter = styled(motion.div)`
  display: grid;
  grid-template-rows: min-content 1fr 1fr;
  height: 100%;
  padding: 16px 20px;
  position: fixed;
  inset: 0;
  z-index: 300;
  background: ${({ theme }) => theme.body};

  & > div {
    display: flex;
  }

  & > p {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 4px;
    margin: 0 0 12px;
    color: ${({ theme }) => theme.projects.titleColor};
    background: ${({ theme }) => theme.projects.titleBg};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 32px;
    overflow-y: scroll;
    padding-bottom: 64px;
  }
`;

export const PortfolioIndustriesFilterWrapper = styled.div`
  height: max-content;
  margin-top: 32px;
  gap: 24px;
  align-items: flex-start;
  flex-direction: column;
  @media (max-height: 690px) {
    gap: 8px;
    margin-top: 20px;
  }
`;

export const StyledMobilePortfolioFilterHeader = styled.div`
  flex-direction: row;
  justify-content: space-between;

  & .revert {
    filter: invert();
  }

  & .mobile {
    display: block;
    position: relative;

    & svg {
      position: relative;
      opacity: 0;
      top: 6px;
      left: 3px;
    }

    &.opened {
      & svg:first-child {
        & rect:first-child {
          transition: 0.5s ease;
          transform-origin: center;
          transform: rotate(-45deg);
          opacity: 0;
        }

        & rect:last-child {
          transition: 0.5s ease;
          transform-origin: center;
          transform: rotate(45deg);
          opacity: 0;
        }
      }

      & svg:last-child {
        transition: 0.5s ease;
        transition-delay: 0.2s;
        opacity: 1;
      }
    }
  }
`;

export const PortfolioIndustriesFilterButtonsWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  place-self: end center;
  gap: 8px;

  & .xmark {
    width: 20px;
    height: 20px;
  }

  & button {
    min-width: 198px;
    justify-content: space-between;

    & p {
      margin-bottom: 0;
    }
  }
    @media (max-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media (max-width: 450px) {
      flex-wrap: wrap;
      & button {
        width: 100%;
      }
    }
`;

export const StyledShowMoreButton = styled.div`
  & div {
    margin: 24px auto 0;
    padding: 10px 24px;
    transition: 0.5s ease transform;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const StyledPortfolioSoonTag = styled.div`
  position: absolute;
  top: 34.5px;
  right: 32px;
  width: min-content;
  white-space: nowrap;
  padding: 7px 12px 9px;
  background: #fff;
  color: #05070d;
  border-radius: 100px;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 1024px) {
    top: 18.5px;
    right: 17px;
  }
`;
