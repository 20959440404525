import { ProjectType } from "../../types";
import forensics from "../../assets/images/portfolio/forensics.webp";
import forensics_mobile from "../../assets/images/portfolio/forensics_mobile.webp";
import salvation_lower from "../../assets/images/projects/salvation_lower.webp";
import salvation_middle from "../../assets/images/projects/salvation_middle.webp";
import salvation_higher from "../../assets/images/projects/salvation_higher.webp";
import ProjectHeaderText from "../../components/Project/ProjectHeaderText/ProjectHeaderText";
import ProjectPageCard from "../../components/Project/ProjectPageCard/ProjectPageCard";
import ProjectMainInfo from "../../components/Project/ProjectMainInfo/ProjectMainInfo";
import ProjectGoals from "../../components/Project/ProjectGoals/ProjectGoals";
import ProjectTimeline from "../../components/Project/ProjectTimeline/ProjectTimeline";
import forensics_timeline_desktop from "../../assets/images/project/forensics/forensics_timeline_desktop.png";
import refmetrix_outcomes_item_desktop from "../../assets/images/project/refmetrix/refmetrix_outcomes_item_desktop.png";
import salvation_hero from "../../assets/images/project/forensics/salvationdata.jpg";
import page_layout from "../../assets/images/project/forensics/page-layout.png";
import page_layout2 from "../../assets/images/project/forensics/page-layout2.png";
import new_product from "../../assets/images/project/forensics/new-product1.jpg";
import new_product2 from "../../assets/images/project/forensics/new-product2.png";
import new_product3 from "../../assets/images/project/forensics/new-product3.png";
import video_img from "../../assets/images/project/forensics/video.png";
import database_img from "../../assets/images/project/forensics/database.png";
import easy_navigation from "../../assets/images/project/forensics/easy-navigation.png";
import info_descr from "../../assets/images/project/forensics/info-descr.png";
import project1 from "../../assets/images/project/forensics/project1.png";
import project2 from "../../assets/images/project/forensics/project2.png";
import project3 from "../../assets/images/project/forensics/project3.png";
import ProjectTextSection from "../../components/Project/ProjectTextSection/ProjectTextSection";
import ProjectStack from "../../components/Project/ProjectStack/ProjectStack";
import { CssIcon, JavaScriptIcon, PhpIcon, WordPressIcon } from "../../components/Icons/Icons";

export const forensicsData: ProjectType = {
  name: "Forensics Solution Provider’s Website",
  industry: "Digital Forensic",
  href: "forensics-solution-providers-website",
  services: [
    "IT Strategy and Planning",
    "UX/UI Design",
    "QA",
    "CMS Development",
  ],
  color: "#fff",
  portfolio_image: forensics,
  portfolio_image_hash: 'Li4zLZjEa*f,Sobbj;aekZayaxkC',
  portfolio_mobile: forensics_mobile,
  description:
    "The project goal was to redesign and develop a website for a global leading one-stop forensics & investigation solution provider. Niwart has been working closely with the client to create a solution that will showcase their products’ benefits to potential customers.",
  image: {
    color: "#1365E4",
    lower: salvation_lower,
    middle: salvation_middle,
    higher: salvation_higher,
  },
  projectPage: [
    {
      component: ProjectHeaderText,
      props: {
        seoTitle: "Niwart Case Study | Forensics Solution Provider's Website",
        seoDescription: "Uncover the story behind Niwart's impactful contribution to enhance SalvationData's web presence to attract new users and increase sales.",
        title: "Forensics Solution Provider’s Website",
        titleColor: `#136DF7`,
        description:
          "SalvationData is a global leader in digital forensics, providing advanced hardware and software solutions for effective technical operations. Their integrated approach covers digital forensics, data recovery, data security, and big data solutions, serving diverse sectors worldwide",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: salvation_hero,
              tablet: salvation_hero,
              mobile: salvation_hero,
            },
          },
        ],
        maxWidth: 1920,
        padding: "0",
      },
    },
    {
      component: ProjectMainInfo,
      props: {
        header: ["Challenge"],
        text: [
          "Salvationdata Technology INC undertook a comprehensive website redesign initiative to address the challenges posed by its outdated online platform. Faced with a diminishing user base, an antiquated design, a lack of user-friendly features, and low sales figures for their products the company aimed to enhance user experience and modernize its digital presence. The primary goals included attracting a larger audience, fostering a more intuitive interface, increasing their products and services sales, and rectifying performance and SEO deficiencies.",
          "Together, we successfully attracted a larger audience, fostered a more intuitive interface, and rectified performance and SEO deficiencies. This strategic partnership positioned Salvationdata Technology INC as a digitally adept entity, ensuring competitiveness and sustained growth in the online marketplace.",
        ],
        direction: "vertical",
        titleColor: `#136DF7`,
        gap: "18px",
        maxWidth: "1264px",
        industry: "Digital Forensic",
        location: "Sichuan, China",
        services: [
          "CMS Development",
          "UX/UI Design",
          "IT Strategy and Planning",
          "QA",
        ],
      },
    },
    {
      component: ProjectGoals,
      props: {
        headerText: ["Project", "Goals"],
        titleColor: `#136DF7`,
        goals: [
          {
            title: ["Update the Interface"],
            text: "The objective of updating the website interface is to enhance user experience by implementing a more modern and user-friendly design. This includes improving navigation, visual elements, and overall aesthetics to ensure a more intuitive and engaging online environment for visitors.",
          },
          {
            title: ["Attract new users"],
            text: "The aim of the new website is to broaden its user base by employing strategies that boost visibility and attractiveness. This involves optimizing content for search engines, utilizing social media, and creating an engaging user experience to attract and retain new users.",
          },
          {
            title: ["Increase products &", "services sales"],
            text: "Increasing sales for products and services through strategic marketing and an enhanced online shopping experience, including optimized product pages and a streamlined checkout process",
          },
        ],
      },
    },
    // {
    //   component: ProjectTimeline,
    //   props: {
    //     header: ["Project", "Timeline"],
    //     titleColor: `#136DF7`,
    //     image: [
    //       {
    //         image: {
    //           desktop: forensics_timeline_desktop,
    //           tablet: forensics_timeline_desktop,
    //           mobile: forensics_timeline_desktop,
    //         },
    //       },
    //     ],
    //   },
    // },
    {
      component: ProjectTextSection,
      props: {
        header: ["Effective Product Page", "Layout"],
        text: [
          "Our design solution prioritized a clear hierarchy, streamlined navigation, engaging visuals, and concise product descriptions",
          "Mobile responsiveness and user-friendly CTAs, transformed the page into an organized, visually appealing, and conversion-driven experience.",
        ],
        titleColor: `#136DF7`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: page_layout,
              tablet: page_layout,
              mobile: page_layout,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: page_layout2,
              tablet: page_layout2,
              mobile: page_layout2,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["New Product", "Cards"],
        text: [
          "Following thorough research, we have developed a new product card that more accurately represents the client's offering and boasts a distinctively appealing visual design.",
        ],
        titleColor: `#136DF7`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: new_product,
              tablet: new_product,
              mobile: new_product,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: new_product2,
              tablet: new_product2,
              mobile: new_product2,
            },
          },
          {
            image: {
              desktop: new_product3,
              tablet: new_product3,
              mobile: new_product3,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Integration of", "Virtual Office"],
        headerMaxWidth: "312px",
        text: [
          "We added a three-dimensional visual representation of the laboratory on product pages to give customers a clear preview of their future office. Using 3D modeling tools, we created an opportunity for clients to  explore the space, aiding more informed decision-making on the layout.",
        ],
        titleColor: `#136DF7`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: video_img,
              tablet: video_img,
              mobile: video_img,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Access to the", "Database Files"],
        text: [
          " The client  decides to make their extensive knowledge base and programs in the field of digital forensics publicly accessible with the aim of attracting potential clients. Their strategy involves structuring information for user convenience and ensuring a high level of security to preserve data confidentiality. To achieve this goal, we have created a dedicated page where users can effortlessly download any file.",
        ],
        titleColor: `#136DF7`,
        direction: "horizontal",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: database_img,
              tablet: database_img,
              mobile: database_img,
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "0 20px",
        items: [
          {
            text: {
              data: "Easy navigation on various digital security issues",
              maxWidth: "270px",
              align: "start",
              padding: "0",
              position: {
                desktop: "left",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: easy_navigation,
              tablet: easy_navigation,
              mobile: easy_navigation,
            },
          },
        ],
      },
    },
    {
      component: ProjectPageCard,
      props: {
        maxWidth: 1264,
        padding: "64px 20px",
        items: [
          {
            text: {
              data: "An informative description of the file indicating the size and format.",
              align: "start",
              maxWidth: "270px",
              padding: "0",
              position: {
                desktop: "right",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
            image: {
              desktop: info_descr,
              tablet: info_descr,
              mobile: info_descr,
            },
          },
        ],
      },
    },
    {
      component: ProjectTextSection,
      props: {
        header: ["Project", "Outcomes"],
        titleColor: `#136DF7`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectPageCard,
      props: {
        items: [
          {
            image: {
              desktop: project1,
              tablet: project1,
              mobile: project1,
            },
            text: {
              title: "High-conversion pages",
              data: "Working together with the client’s SEO team we’ve improved the page structure and suggested our solution for providing best SEO practices.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: project2,
              tablet: project2,
              mobile: project2,
            },
            text: {
              title: "Modern design concept",
              data: "After the completion of the Discovery phase, we’ve come up with a new design concept that combines Modern design trends and the company's positioning in the market.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
          {
            image: {
              desktop: project3,
              tablet: project3,
              mobile: project3,
            },
            text: {
              title: "New features",
              data: "Our team implemented new features to the website that improved the user experience and expanded the possibilities of user interaction with the website.",
              align: "start",
              padding: "0",
              position: {
                desktop: "bottom",
                tablet: "bottom",
                mobile: "bottom",
              },
            },
          },
        ],
        maxWidth: 1264,
        padding: "64px 20px",
      },
    }, 
    {
      component: ProjectTextSection,
      props: {
        header: ["Tech" , "Stack"],
        titleColor: `#136DF7`,
        direction: "vertical",
        maxWidth: "1264px",
        padding: "64px 20px 0",
      },
    },
    {
      component: ProjectStack,
      props: {
        frontendStack: [
          {
            svg: JavaScriptIcon,
            name: 'JavaScript'
          },
          {
            svg: CssIcon,
            name: 'Css'
          },
        ],
        backendStack: [
          {
            svg: WordPressIcon,
            name: 'Wordpress'
          },
          {
            svg: PhpIcon,
            name: 'php'
          },
        ]
      },
    },
  ],
};
