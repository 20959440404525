import React from 'react';
import { StyledRoundTagOutlined } from "./styled";

type PropsType = {
  text: string;
  isClicked?: boolean;
  onClick?: () => void;
  color?: string
};

const RoundTagOutline = ({ text, isClicked, onClick, color }: PropsType) => {
  const clickableClass = !!onClick ? "cursor_pointer" : "";

  return (
    <StyledRoundTagOutlined
      className={`${isClicked ? `tag-clicked ` : ``} ${clickableClass}`}
      onClick={onClick}
      $color={color}
    >
      {text}
    </StyledRoundTagOutlined>
  );
};

export default RoundTagOutline;