import styled from "styled-components";

export const StyledTextArea = styled.textarea<{ iserror: boolean | undefined }>`
  width: inherit;
  padding: 0 0 8px;
  background-color: transparent;
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.input.borderColor}`};
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  overflow-y: hidden;
  resize: none;
  border-bottom: ${({ theme, iserror }) =>
    iserror
      ? `1px solid ${theme.input.borderError}`
      : `1px solid ${theme.input.borderColor}`};

  &::placeholder {
    color: ${({ theme }) => theme.text};
    opacity: 1;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &:hover {
    border-bottom: ${({ theme }) => `1px solid ${theme.input.borderHover}`};
  }

  &:focus-visible {
    outline: none;
    border-bottom: ${({ theme }) => `1px solid ${theme.input.borderFocus}`};
  }

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.text};
    box-shadow: inset 0 0 20px 20px transparent;
  }
`;


