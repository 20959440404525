import { StyledServicesBg } from "./styled";

type PropsType = {
  className?: string;
};

const ServicesBg = ({ className = "" }: PropsType) => {
  return <StyledServicesBg className={className} />;
};

export default ServicesBg;
