import React, { Dispatch, useState } from "react";
import { StyledFile, StyledFileHelperText, StyledFileInputWrapper, StyledForm } from "./styled";
import RoundTag from "../RoundTag/RoundTag";
import Input from "../Input/Input";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import { useFormik } from "formik";
import TextArea from "../TextArea/TextArea";
import { ModalObjType } from "../../types";
import { FileUploader } from "react-drag-drop-files";
import { FileLogo, XmarkIcon } from "../Icons/Icons";
import { useTheme } from "styled-components";
import useWindowSize from "../../utils/useWindowSize";
import axios from "axios";
import { Link } from "react-router-dom";

const serviceArray = [
  "Web App Development",
  "UX/UI Design",
  "Website Creation",
  "Mobile App Development",
  "MVP for Startup",
  "IT Consultation",
  "Other",
];

type FormType = {
  name: string;
  email: string;
  tel: string;
  company: string;
  message: string;
  services: Array<string>;
  file?: File
};

type PropsType = {
  modalObj: ModalObjType;
  setModalObj: Dispatch<React.SetStateAction<ModalObjType>>;
  setShowModal?: Dispatch<React.SetStateAction<ModalObjType>>;
};

const ContactUsForm = ({ setModalObj }: PropsType) => {
  const theme = useTheme()
  const { width } = useWindowSize()
  const [services, setServices] = useState<Array<string>>([]);
  const [isChecked, setIsChecked] = useState(false);

  const handleFileAdd = (file: File) => {
    formik.setFieldValue('file', file)
  }
  const handleFileDelete = () => {
    formik.setFieldValue('file', undefined)
  }

  const formik = useFormik({
    validate: (values) => {
      const errors = {} as Partial<FormType>;
      if (!values.name) {
        errors.name = "Field is required";
      }
      if (!values.tel) {
        errors.tel = "Field is required";
      }
      if (!values.email) {
        errors.email = "Field is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.message) {
        errors.message = "Field is required";
      }

      return errors;
    },
    initialValues: {
      name: "",
      email: "",
      tel: '',
      company: "",
      message: "",
      file: undefined
    } as Omit<FormType, "services">,
    onSubmit: async (values: Omit<FormType, "services">, { resetForm }) => {
      setModalObj({
        text: "Your message is sending...",
        isOpen: true,
      });
      const servicesString = services.length > 0 ? services.join(', ') : '';
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('company', values.company);
      formData.append('phone', values.tel);
      formData.append('message', values.message);
      formData.append('url', window.location.href);
      formData.append('interested', servicesString);


      if (values.file) {
        formData.append('file', values.file);
      }

      try {
        await axios.post('https://api.niwart.io/feedback', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
          },
        });
        setModalObj((prev) => ({ ...prev, text: 'Your message has been sent' }));
        setTimeout(() => {
          setModalObj((prev) => ({ ...prev, isSuccess: true, text: 'Your message has been sent' }));
        }, 2000)
        resetForm();
        setServices([]);
      } catch (error) {
        console.error('Error sending feedback:', error);
        setModalObj((prev) => ({ ...prev, text: 'Error sending message. Please try again.' }));
      }

      setTimeout(() => {
        setModalObj((prev) => ({ ...prev, isOpen: false }));
      }, 2000);
    },
  });
  const handleServicesClick = (svs: string) => {
    let index = services.findIndex((el) => el === svs);
    if (index === -1) {
      setServices((prev) => [...prev, svs]);
      return;
    }
    setServices((prev) => prev.filter((el) => el !== svs));
  };
  const checkBoxHandler = () => {
    setIsChecked((prev) => !prev);
  };

  return (
    <StyledForm>
      <h3>I’m interested in:</h3>
      <div>
        {serviceArray.map((el, ind) => (
          <RoundTag
            text={el}
            key={ind}
            isClicked={services.includes(el)}
            onClick={() => handleServicesClick(el)}
          />
        ))}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={"basic_info"}>
          <Input
            required={true}
            placeholder={"Full Name*"}
            {...formik.getFieldProps("name")}
            iserror={formik.touched.name && !!formik.errors.name}
            errortext={formik.errors.name}
          />
          <Input
            required={true}
            placeholder={"Email*"}
            {...formik.getFieldProps("email")}
            iserror={formik.touched.email && !!formik.errors.email}
            errortext={formik.errors.email}
          />
        </div>
        <Input
          required={true}
          placeholder={"Phone number*"}
          type={'number'}
          maxLength={30}
          {...formik.getFieldProps("tel")}
          iserror={formik.touched.tel && !!formik.errors.tel}
          errortext={formik.errors.tel}
        />
        <Input
          placeholder={"Company name"}
          maxLength={30}
          {...formik.getFieldProps("company")}
        />
        <TextArea
          required={true}
          placeholder={"How can we help you?*"}
          {...formik.getFieldProps("message")}
          iserror={formik.touched.message && !!formik.errors.message}
          errortext={formik.errors.message}
        />
        <StyledFileInputWrapper $isError={!!formik.errors.file}>
          <FileUploader
            maxSize={30}
            onSizeError={() => formik.setFieldError('file', 'File size more than 30 MB')}
            onTypeError={() => formik.setFieldError('file', 'Invalid file format')}
            handleChange={(file: File) => handleFileAdd(file)}
            disabled={formik.values.file}
            name={`file_${formik.values.name}`}
            types={['PDF', 'DOC', 'ZIP', 'PPT']}
          >
            {formik.values.file ?
              <StyledFile>
                <FileLogo className={theme.text === '#fff' ? 'revert' : ''} />
                <div>
                  <h4>{formik.values.file.name}</h4>
                  <p>{(formik.values.file.size / 1048576).toFixed(2)} MB</p>
                </div>
                <XmarkIcon onClick={handleFileDelete} />
              </StyledFile>
              : <label>
                {width < 880
                  ? <p><span>Choose file</span> to upload</p>
                  : <p>Drag & Drop or <span>Choose file</span> to upload</p>}
              </label>}
          </FileUploader>
          <div>
            <StyledFileHelperText $isError={!!formik.errors?.file && formik.errors?.file === 'Invalid file format'}>
              Supported Formats: PDF, DOC, ZIP, PPT
            </StyledFileHelperText>
            <StyledFileHelperText
              $isError={!!formik.errors?.file && formik.errors?.file === 'File size more than 30 MB'}>
              Maximum File Size: 30MB
            </StyledFileHelperText>
          </div>
        </StyledFileInputWrapper>
        <div className={"checkbox_wrapper"}>
          <label>
            By submitting this form, I consent to processing my personal information as set out in the {'  '}
            <Link to="/privacy-policy">
              Privacy Policy
            </Link>
          </label>
        </div>
      </form>
      <Button
        type={"primary"}
        text={`Let’s Collaborate!`}
        onClick={formik.submitForm}
      />
    </StyledForm>
  );
};

export default ContactUsForm;
