import styled from "styled-components";

export const StyledProjectStack = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 24px;
  width: 100%;
  max-width: 1264px;
  margin: 0 auto;
  padding: 64px 20px;

  @media (max-width: 1280px) {
    padding: 0 24px;
  }
  @media (max-width: 690px) {
    flex-direction: column;
  }
`;

export const StyledStackWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
  
  @media (max-width: 960px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const StyledStackBlock = styled.div`
  display: flex;
  flex-wrap: wrap; 
  gap: 24px;
  padding: 32px; 
  border-radius: 16px;
  border: 1px solid ${({ theme }) => (theme.body !== "#fff" ? "#fff" : "#000")};  
  width: 49%;

  svg {
    path {
      fill: ${({ theme }) => (theme.body !== "#fff" ? "#fff" : "#000")};
    }
  }
  
  @media (max-width: 960px) {
    width: 100%;
  }

  span {
    width: 100%;  
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
`;

export const StyledStackItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
