import { StyledProjectMainInfo } from "./styled";
import ProjectTextSection from "../ProjectTextSection/ProjectTextSection";
import ProjectMainInfoTagsWrapper from "./ProjectMainInfoTagsWrapper";

type PropsType = {
  header: Array<string>;
  text?: Array<string>;
  direction: "vertical" | "horizontal";
  maxWidth?: string;
  gap?: string;
  titleColor: string;
  location: string;
  services: Array<string>;
  industry: string;
};

const ProjectMainInfo = ({
  gap,
  header,
  direction,
  services,
  maxWidth,
  text,
  titleColor,
  location,
  industry,
}: PropsType) => {
  return (
    <StyledProjectMainInfo $maxWidth={maxWidth}>
      <ProjectMainInfoTagsWrapper
        titleColor={titleColor}
        industry={industry}
        location={location}
        services={services}
      />
      <ProjectTextSection
        direction={"vertical"}
        gap={gap}
        text={text}
        header={header}
        titleColor={titleColor}
      />
    </StyledProjectMainInfo>
  );
};

export default ProjectMainInfo;
