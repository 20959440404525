import styled from "styled-components";

export const StyledContactModal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: ${({ theme }) =>
    theme.text === "#fff"
      ? '#05070d'
      : '#fff'}; 
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
  animation: .3s ease-out fade forwards;

  .close {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    opacity: 1;
    transition: all .4s ease;
    transform: translateY(22px);

    path {
      fill: ${({ theme }) =>
    theme.text === "#fff"
      ? '#fff'
      : '#05070d'}; 
    }

    &:hover {
      opacity: 1;
      transform: translateY(22px) rotate(90deg);
    }
  }

  footer {
    div {
      &:nth-child(3), &:nth-child(4) {
        display: none;
      }
    }

    &>p {
      display: none;
    }
  }

  section {
    position: relative;
    flex-direction: row-reverse;
    padding: 0;
    gap: 260px;

    &::before {
      position: absolute;
      left: -10px;
      top: 70px;
      content: '';
      border-radius: 352px;
      background: linear-gradient(247deg, rgba(25, 83, 255, 0.30) 35.22%, rgba(0, 194, 255, 0.30) 69.6%);
      filter: blur(120px); 
      width: 352px;
      height: 351.818px; 
    }

    button {
      margin-left: auto;
    }
  }

  h2 {
    position: absolute;
    left: 0;
    transform: translateY(25%)
  }

  form {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 36px 0;

    .checkbox_wrapper {
      margin-top: -32px;
    }

    &>div {
      width: 100%;
      &:nth-child(2), &:nth-child(3) {
        width: 48%;

        input {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    height: 100vh;
    overflow-y: scroll;

    section {
      margin-top: 0;
      gap: 48px;
      padding: 96px 24px 126px;
      flex-direction: column;
      
      &::before {
        display: none;
      }

      .close {
        right: 24px;
        top: 48px;
      }

      h2 {
        position: static;
        transform: initial;
      }

      form {
       & > div {
          width: 100% !important;
        }
      }

    }
  }
`