import { StyledRoundTag } from "./styled";

type PropsType = {
  text: string;
  isClicked?: boolean;
  onClick?: () => void;
  isDisabled?: boolean
};

const RoundTag = ({ text, isClicked, onClick, isDisabled }: PropsType) => {
  const clickableClass = !!onClick ? "cursor_pointer" : "";
  const disabledClass = isDisabled ? 'disabled' : ''
  const onClickHandler = () => {
    if (!isDisabled && onClick) {
      onClick()
    }
    return
  }

  return (
    <StyledRoundTag
      className={`${isClicked ? `tag-clicked ` : ``} ${clickableClass} ${disabledClass}`}
      onClick={onClickHandler}
    >
      {text}
    </StyledRoundTag>
  );
};

export default RoundTag;
