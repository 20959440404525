import { HOME } from "../../routes";
import DarkModeSwitcher from "../DarkModeSwitcher/DarkModeSwitcher";
import { MenuIcon, NiwartIcon, XmarkIcon } from "../Icons/Icons";
import { ButtonsWrapper, StyledHeader, StyledHeaderContent } from "./styled";
import Button from "../Button/Button";
import IconButton from "../IconButton/IconButton";
import HeaderLink from "../HeaderLink/HeaderLink";
import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StyledLink } from "../HeaderLink/styled";

type PropsType = {
  theme: "light" | "dark";
  toggleMode: () => void;
  isOpened: boolean;
  setOpened: () => void;
};

let WIN_PREV_POSITION = global?.window && window.pageYOffset;

const Header = ({ theme, toggleMode, setOpened, isOpened }: PropsType) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const getButtonClassName = () => {
    let str = "mobile";
    if (isOpened) str += " opened";
    if (theme === "light") str += " revert";
    return str;
  };

  const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const handleEvent = () => {
    window.requestAnimationFrame(showHideHeaderMenu);
  };

  const showHideHeaderMenu = () => {
    let currentScrollPos = window.pageYOffset;

    if (isOpened || !containerRef.current) return;

    // SHOW _ HIDE MAIN MENU
    if (currentScrollPos > WIN_PREV_POSITION) {
      if (
        isInViewport(containerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return;
      }
      containerRef.current.classList.remove("header--show");
      containerRef.current.classList.add("header--hide");
    } else {
      if (
        !isInViewport(containerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return;
      }
      containerRef.current.classList.add("header--show");
      containerRef.current.classList.remove("header--hide");
    }
    currentScrollPos > 80
      ? containerRef.current.classList.add("header--blur")
      : containerRef.current.classList.remove("header--blur");

    WIN_PREV_POSITION = currentScrollPos;
  };

  useEffect(() => {
    if (isOpened) {
      window.removeEventListener("scroll", handleEvent);
    } else {
      window.addEventListener("scroll", handleEvent);
    }
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, [isOpened]);

  useEffect(() => {
    handleEvent();
  }, [theme, toggleMode]);

  return (
    <StyledHeader ref={containerRef}>
      <StyledHeaderContent>
        <Link to={HOME}>
          <NiwartIcon theme={theme} />
        </Link>
        <nav>
          <HeaderLink text={"Services"} href={"/#services"} />
          <StyledLink>
            <Link to={"/portfolio"}>Portfolio</Link>
          </StyledLink>
          <HeaderLink text={"Stories"} href={"/#stories"} />
        </nav>
        <ButtonsWrapper>
          <DarkModeSwitcher theme={theme} toggleMode={toggleMode} />
          <Button
            type={"primary"}
            text={`Contact Us`}
            onClick={() =>
              navigate('/contacts')
            }
          />
        </ButtonsWrapper>
        <IconButton className={getButtonClassName()} onClick={setOpened}>
          <MenuIcon />
          <XmarkIcon />
        </IconButton>
      </StyledHeaderContent>
    </StyledHeader>
  );
};

export default Header;
