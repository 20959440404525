import { useLayoutEffect, useState } from "react";

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: -5, y: -5 });

  const updateMousePosition = (e: any) => {
    if (e.pageY < window.innerHeight + 200 && window.innerWidth > 820) {
      setMousePosition({ x: e.pageX, y: e.pageY + 90 });
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, []);

  return mousePosition;
};

export default useMousePosition;
