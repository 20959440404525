import React from 'react';
import { StyledPortfolioTitle } from "./styled";

const PortfolioTitle = () => {
  return (
    <StyledPortfolioTitle>
      <h1>
        Niwart's solutions empower businesses to thrive in the ever-evolving market landscape. Explore our
        <span> diverse portfolio</span>, where innovation meets expertise.
      </h1>
    </StyledPortfolioTitle>
  );
};

export default PortfolioTitle;