import styled from "styled-components";

export const StyledContactPage = styled.section`

  section {
    position: relative;
    flex-direction: row-reverse;
    gap: 260px;

    &::before {
      position: absolute;
      left: -10px;
      top: 70px;
      content: '';
      border-radius: 352px;
      background: linear-gradient(247deg, rgba(25, 83, 255, 0.30) 35.22%, rgba(0, 194, 255, 0.30) 69.6%);
      filter: blur(120px); 
      width: 352px;
      height: 351.818px; 
    }

    button {
      margin-left: auto;
    }
  }

  

  #contact-us>div:last-child {
    display: none;
  }

  h2 {
    position: absolute;
    left: 16px;
    transform: translateY(25%)
  }

  form {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 36px 0;

    .checkbox_wrapper {
      margin-top: -32px;
    }

    &>div {
      width: 100%;
      &:nth-child(2), &:nth-child(3) {
        width: 48%;

        input {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    height: 100vh;
    overflow-y: scroll;

    section {
      margin-top: 0;
      gap: 48px;
      padding: 96px 24px 126px;
      flex-direction: column;
      
      &::before {
        display: none;
      }

      .close {
        right: 24px;
        top: 48px;
      }

      h2 {
        position: static;
        transform: initial;
      }

      form {
       & > div {
          width: 100% !important;
        }
      }

    }
  }
`