import React from "react";
import { StyledProjectStack, StyledStackBlock, StyledStackItem, StyledStackWrapper } from "./styled";

type SvgComponentProps = {
  className?: string;
};

type StackItem = {
  svg: React.FunctionComponent<SvgComponentProps>;
  name: string;
};

type PropsType = {
  frontendStack: StackItem[];
  backendStack: StackItem[];
};

const ProjectStack = ({ frontendStack, backendStack }: PropsType) => {
  return (
    <StyledProjectStack>
      <StyledStackWrapper>
        <StyledStackBlock>
          <span>Front End</span>
          {frontendStack.map((item, index) => (
            <StyledStackItem key={index}>
              <item.svg /> {item.name}
            </StyledStackItem>
          ))}
        </StyledStackBlock>
        <StyledStackBlock>
          <span>Back End</span>
          {backendStack.map((item, index) => (
            <StyledStackItem key={index}>
              <item.svg />  {item.name}
            </StyledStackItem>
          ))}
        </StyledStackBlock>
      </StyledStackWrapper>
    </StyledProjectStack>
  );
};

export default ProjectStack;
