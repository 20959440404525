import { StyledStoriesWrapper, StyledTestimonials } from "./styled";
import { useRef } from "react";
import Stories from "./Stories/Stories";

export const TestimonialsPhrases = [
  "Read the stories of our clients and see how Niwart’s solutions have helped them ",
  "reach their ",
  "Business Goals",
];

const TestimonialsDesktop = () => {
  const container = useRef(null);
  const storiesRef = useRef(null);

  return (
    <StyledTestimonials ref={container} id={"stories"}>
      <StyledStoriesWrapper ref={storiesRef}>
        <Stories/>
      </StyledStoriesWrapper>
    </StyledTestimonials>
  );
};

export default TestimonialsDesktop;
