import { StyledProgress, StyledStory, StyledVideo } from "../styled";
import { MiniatureAnimType, StoryType } from "../../../types";
import { CircularProgressbar } from "react-circular-progressbar";
import { VolumeLogo, VolumeOffLogo } from "../../Icons/Icons";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState, } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { VideoObjType } from "./Stories";
import useWindowSize from "../../../utils/useWindowSize";

type PropsType = {
  index: number;
  story: StoryType;
  className: string;
  onClick: (i: number | null) => void;
  isActive: boolean;
  setVideoObj: Dispatch<SetStateAction<VideoObjType>>;
  videoObj: VideoObjType;
};

const StoryMiniature = ({
  className,
  story,
  onClick,
  isActive,
  videoObj,
  setVideoObj,
  index,
}: PropsType) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [isUnMutedBefore, setIsUnMutedBefore] = useState(false);
  const [valueEnd, setValueEnd] = React.useState(0);
  const [debouncedStart, setDebouncedStart] = useState(false);
  const { width } = useWindowSize();

  const onStartHandler = () => {
    let ref = playerRef.current as ReactPlayer | null;

    if (ref) {
      let duration = ref.getDuration();
      setVideoObj((prev) => ({ ...prev, length: duration }));
    }
  };

  const onProgressHandler = (e: ReactPlayerProps) => {
    setValueEnd(e.played * 100);
  };

  const toggleMuted = () => {
    setVideoObj((prev) => ({ ...prev, isMuted: !prev.isMuted }));
    if (videoObj.isMuted) {
      !isUnMutedBefore && playerRef?.current?.seekTo(0, "seconds");
    }
    setIsUnMutedBefore(true);
  };

  const handleStart = () => {
    onClick(index);
    if (!isActive && story.imageUrl) {
      setTimeout(
        () => {
          setDebouncedStart((prev) => !prev);
          setVideoObj((prev) => ({ ...prev, isPlaying: true }));
        },
        videoObj.isPlaying ? 0 : 1500,
      );
    }
  };

  const onEndHandler = () => {
    setTimeout(() => {
      onClick(null);
      setVideoObj((prev) => ({ ...prev, length: 0, isPlaying: false }));
      setValueEnd(0);
    }, 300);
  };

  useEffect(() => {
    onStartHandler();
    setDebouncedStart(false);
    if (!isActive) {
      setIsUnMutedBefore(false);
      setValueEnd(0);
    }
  }, [isActive]);

  const rightPosCalc = () => {
    if (width > 1300) {
      return 635;
    }
    if (width > 1220) {
      return 620;
    }
    if (width > 1070) {
      return 575;
    }
    return 530;
  };

  const animations: MiniatureAnimType = {
    leftSideAnimation: (i: number, isActive: boolean) => {
      return {
        active: {
          top: 7,
          left: width < 1150 ? "13%" : 30,
          width: 280,
          height: 280,
          transition: {
            duration: 0.3,
            delay: isActive ? 0 : 1.5,
          },
        },
        idle: {
          x: [0, 20, 0, 15, 15, 0],
          y: [0, 15, 20, 15, 0, 0],
          transition: {
            duration: 15,
            origin: 0,
            delay: i / 3,
            repeat: Infinity,
          },
        },
      };
    },
    rightSideAnimation: (i: number, isActive: boolean) => ({
      active: {
        top: 7,
        right: rightPosCalc(),
        width: 280,
        height: 280,
        transition: {
          duration: 0.3,
          delay: isActive ? 0 : 1.5,
        },
      },
      idle: {
        x: [0, 20, 0, 15, 15, 0],
        y: [0, 15, 20, 15, 0, 0],
        transition: {
          duration: 15,
          delay: i / 2,
          repeat: Infinity,
        },
      },
    }),
  };

  const renderPlayingVideo = () => {
    return (
      <StyledVideo>
        <ReactPlayer
          className={"video"}
          ref={playerRef}
          url={story.videoUrl}
          width={280}
          height={280}
          playing={videoObj.isPlaying}
          onProgress={onProgressHandler}
          progressInterval={100}
          onEnded={onEndHandler}
          controls={false}
          playsinline
          volume={0.5}
          muted={videoObj.isMuted}
          config={{
            file: {
              attributes: { poster: story.imageUrl },
            },
          }}
        />
        <StyledProgress active={videoObj.isPlaying ? "playing" : undefined}>
          <CircularProgressbar value={valueEnd} strokeWidth={2.5}/>
        </StyledProgress>
        <div onClick={toggleMuted}>
          {videoObj.isMuted ? <VolumeOffLogo/> : <VolumeLogo/>}
        </div>
      </StyledVideo>
    );
  };

  const renderPreview = () => {
    return story?.videoUrl ? (
      <ReactPlayer
        className={"video-miniature"}
        url={story.videoUrl}
        width={width > 1240 ? 112 : 80}
        height={width > 1240 ? 112 : 80}
        playing={true}
        loop={true}
        muted={true}
        config={{
          file: {
            attributes: { poster: story.imageUrl },
          },
        }}
      />
    ) : (
      <img
        src={story.imageUrl}
        alt={className}
        className={isActive && videoObj.isPlaying ? "activeImage" : ""}
      />
    );
  };

  return (
    <StyledStory
      animate={isActive ? "active" : "idle"}
      variants={
        index < 4
          ? animations.rightSideAnimation(index, videoObj.isPlaying)
          : animations.leftSideAnimation(index, videoObj.isPlaying)
      }
      className={className}
      onClick={handleStart}
      index={index}
    >
      {debouncedStart && story?.videoUrl
        ? renderPlayingVideo()
        : renderPreview()}
    </StyledStory>
  );
};

export default StoryMiniature;
