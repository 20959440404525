import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledPreloader = styled(motion.div)`
  position: fixed;
  z-index: 200;
  width: 100dvw;
  height: 100dvh;
  background-color: ${({ theme }) => theme.body};
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    height: 100dvh;
  }
`;
