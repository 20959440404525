import {  useLayoutEffect, useState } from "react";

type ReturnType = [ThemesType, () => void];
type ThemesType = "light" | "dark";

export const useDarkMode = (): ReturnType => {
  const [theme, setTheme] = useState<ThemesType>('light');

  useLayoutEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      toDark();
    } else {
      toLight();
    }
  }, []);

  const toDark = () => {
    setTheme('dark');
    const root = document.querySelector("html");
    if (!root) return;
    !root.classList.contains("dark") && root.classList.add("dark");
    localStorage.theme = "dark";
  };

  const toLight = () => {
    setTheme('light');
    const root = document.querySelector("html");
    if (!root) return;
    root.classList.remove("dark");
    root.classList.add("light")
    localStorage.theme = "light";
  };

  function toggleDarkMode() {
    if (localStorage.theme === "light") {
      toDark();
    } else {
      toLight();
    }
  }

  return [theme, toggleDarkMode];
};
