import React, { useState } from "react";
import { SliderCards, SliderContainer } from "./styled";
import ServiceCard from "./ServiceCard";
import { useKeenSlider } from "keen-slider/react";
import { servicesData } from "../../data/servicesData";

const ServicesSlider = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ref, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: "auto",
      spacing: 24,
    },
    created() {
      setLoaded(true);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const perSlideConf = instanceRef.current?.size
    ? Math.floor(instanceRef.current?.size / 300)
    : 4;

  const slidersCount = instanceRef?.current?.track.details.slides.length ?? 0;

  return (
    <>
      <SliderContainer>
        <SliderCards ref={ref} className="keen-slider">
          {servicesData.map((data, i) => (
            <ServiceCard
              key={i}
              title={data.title}
              subTitles={data.subTitles}
              hoveredSubTitle={data.hoveredSubTitle}
            />
          ))}
        </SliderCards>
      </SliderContainer>
      {loaded && perSlideConf !== 4 && (
        <div className="dots">
          {Array.from(Array(slidersCount + 1 - perSlideConf)?.keys()).map(
            (idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            },
          )}
        </div>
      )}
    </>
  );
};

export default ServicesSlider;
