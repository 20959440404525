import { createGlobalStyle } from "styled-components";
import { colorsPalette } from "./colorsPalette";
import mask_light from "../assets/icons/mask_light.svg";
import mask_dark from "../assets/icons/mask_dark.svg";

export const GlobalStyles = createGlobalStyle`
    * {
        font-family: 'Jacarta', sans-serif;
        font-size: 16px;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #3A6BFF transparent;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 8px;
    }

    *::-webkit-scrollbar-track {
        background-color: inherit;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #5e5e5e;
        border-radius: 8px;

        &:hover {
            background-color: #3b3b3b;
            transition: 1s ease;
        }
    }

    body {
        margin: 0;
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        cursor: ${({ theme }) =>
    theme.body === "#fff"
      ? `url(${mask_light}) 5 6, none`
      : `url(${mask_dark}) 5 6, none`};

     
        @keyframes fade {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        @media (max-width: 768px) {
          max-width: 100vw;
        }
    }

    a {
        color: ${({ theme }) => theme.text};
        text-decoration: none;

        &:visited {
            color: inherit;
        }
    }

    .dots {
        display: flex;
        padding: 12px 0 0;
        justify-content: center;
        gap: 8px;
    }

    .dot {
        border: none;
        width: 8px !important;
        height: 8px;
        background: ${({ theme }) => theme.services.dots.default};;
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
        margin: 0 !important;
    }

    .dot:focus {
        outline: none;
    }

    .dot.active {
        background: ${({ theme }) => theme.services.dots.active};
    }
`;

export const lightTheme = {
  body: "#fff",
  text: colorsPalette.neutral["500"],
  headerBg: `${colorsPalette.primary["500"]}1A`,

  themeSwitcher: {
    border: `1px ${colorsPalette.neutral["500"]} solid`,
  },

  input: {
    borderColor: `${colorsPalette.neutral["500"]}40`,
    borderHover: `${colorsPalette.neutral["500"]}80`,
    borderFocus: colorsPalette.primary["600"],
    borderError: `#FF3B30`,
  },

  tag: {
    border: `1px solid ${colorsPalette.primary["500"]}`,
    bg: colorsPalette.primary["550"],
    hoverBorder: colorsPalette.primary["600"],
    clicked: "#fff",
    outlineColor: colorsPalette.neutral["500"],
  },

  menuLink: {
    hoverBorder: `1px solid #1953FF`,
  },

  buttons: {
    primary: {
      text: "#fff",
      bg: colorsPalette.neutral["500"],
      hoverBg: "#fff",
      hoverText: colorsPalette.neutral["500"],
      hoverBorder: `${colorsPalette.primary["600"]}`,
    },
  },
  heroText: {
    bg: `-webkit-linear-gradient(88.18deg, ${colorsPalette.primary["700"]} 1.41%, ${colorsPalette.primary["800"]} 98.44%)`,
    maskBg: colorsPalette.neutral["500"],
  },
  services: {
    TextBg: colorsPalette.primary["600"],
    card: {
      title: colorsPalette.neutral["500"],
      background: `${colorsPalette.neutral["500"]}05`,
      border: "1px transparent solid",
      hoverBg: colorsPalette.primary["600"],
      hoverShadow: `-5px -5px 32px 0px rgba(5, 7, 13, 0.05), 5px 5px 32px 0px rgba(5, 7, 13, 0.10), 0px 0px 30px 0px rgba(5, 7, 13, 0.40);`,
      hoveredTitle: "#fff",
      iconFill: colorsPalette.primary["600"],
    },
    dots: {
      default: colorsPalette.primary["500"],
      active: colorsPalette.neutral["700"],
    },
  },
  projects: {
    name: colorsPalette.neutral["800"],
    nameActive: colorsPalette.neutral["500"],
    progressBg: colorsPalette.primary["500"],
    progressActive: colorsPalette.primary["600"],
    titleColor: colorsPalette.primary["600"],
    titleBg: colorsPalette.primary["600"],
  },
  stories: {
    progressBg: colorsPalette.primary["550"],
    progressBgActive: colorsPalette.primary["600"],
    highLightText: colorsPalette.primary["600"],
    completeText: colorsPalette.neutral["850"],
    companyName: colorsPalette.neutral["800"],
    quotesColor: colorsPalette.primary["600"],
    buttonColors: colorsPalette.primary["500"],
    arrowColor: colorsPalette.neutral["500"],
  },
  contactUs: {
    bgDesktop: `radial-gradient(108.35% 100% at 50% 0%, #FFF 60.6%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(116.96% 113.1% at 50% 0%, rgba(255, 255, 255, 0.00) 0.05%, #00C2FF 68.47%, #3A6BFF 97.28%);`,
    contactsTitle: colorsPalette.primary["600"],
    fileUpload: {
      borderColor: "05070D40",
      background: `#FFFFFF33`,
      text: colorsPalette.neutral["800"],
      highLightText: colorsPalette.primary["600"],
    },
  },
};

export const darkTheme = {
  body: colorsPalette.neutral["500"],
  text: "#fff",
  headerBg: `${colorsPalette.neutral["600"]}1A`,

  themeSwitcher: {
    border: "1px #fff solid",
  },

  input: {
    borderColor: `#ffffff40`,
    borderHover: `#ffffff80`,
    borderFocus: colorsPalette.primary["600"],
    borderError: `#FF375F`,
  },

  tag: {
    border: `1px solid ${colorsPalette.neutral["700"]}`,
    bg: colorsPalette.neutral["700"],
    hoverBorder: `linear-gradient(88.18deg, ${colorsPalette.primary["700"]} 1.41%, ${colorsPalette.primary["800"]} 98.44%)`,
    clicked: "#fff",
    outlineColor: "#fff",
  },

  menuLink: {
    hoverBorder: `1px solid #4C7DFA`,
  },

  buttons: {
    primary: {
      text: colorsPalette.neutral["500"],
      bg: "#fff",
      hoverText: "#fff",
      hoverBg: colorsPalette.neutral["500"],
      hoverBorder: `linear-gradient(88.18deg, ${colorsPalette.primary["700"]} 1.41%, ${colorsPalette.primary["800"]} 98.44%)`,
      hoverShadow: "none",
    },
  },
  heroText: {
    bg: colorsPalette.primary["600"],
    maskBg: "#fff",
  },
  services: {
    TextBg: `-webkit-linear-gradient(88.18deg, ${colorsPalette.primary["700"]} 1.41%, ${colorsPalette.primary["800"]} 98.44%)`,
    card: {
      title: "#fff",
      background: "transparent",
      border: "1px #fff solid",
      hoverBg: `${colorsPalette.neutral["500"]}03`,
      hoveredTitle: "#fff",
      hoverShadow: `-5px -5px 32px 0 rgba(25, 83, 255, 0.50), 5px 5px 32px 0 rgba(1, 194, 255, 0.50), 0 0 10px 0 ${colorsPalette.primary["600"]}`,
      iconFill: "none",
    },
    dots: {
      default: colorsPalette.neutral["700"],
      active: "#fff",
    },
  },
  projects: {
    name: colorsPalette.primary["400"],
    nameActive: "#fff",
    progressBg: colorsPalette.neutral["700"],
    progressActive: "#fff",
    titleColor: colorsPalette.primary["600"],
    titleBg: `-webkit-linear-gradient(88.18deg, ${colorsPalette.primary["700"]} 1.41%, ${colorsPalette.primary["800"]} 98.44%)`,
  },
  stories: {
    progressBg: `#1e1f25`,
    progressBgActive: "#fff",
    highLightText: colorsPalette.primary["700"],
    completeText: colorsPalette.primary["300"],
    companyName: colorsPalette.primary["400"],
    buttonColors: "#fff",
    arrowColor: "#fff",
  },
  contactUs: {
    bgDesktop: `linear-gradient(180deg, #05070d 0%, rgba(0, 0, 0, 0) 100%),
    radial-gradient(
      118.85% 109.67% at 50% 0%,
      rgba(5, 7, 13, 0) 0.05%,
      rgba(5, 7, 13, 0) 32.1%,
      #0a1946 53.4%,
      #0943f3 73.15%,
      #d6e0fd 90.86%,
      #fff 97.11%
    );`,
    contactsTitle: colorsPalette.primary["400"],
    fileUpload: {
      borderColor: "FFFFFF40",
      background: `#05070D33`,
      text: colorsPalette.primary["400"],
      highLightText: colorsPalette.primary["700"],
    },
  },
};
