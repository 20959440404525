import React, { useState, useEffect } from 'react';
import { Blurhash } from 'react-blurhash';
import { StyledImageLoader } from './styled';

interface ImageLoaderProps {
  imageUrl: string;
  imageHash: string;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ imageUrl, imageHash }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      setImageLoaded(true);
    };
  }, [imageUrl]);

  return (
    <StyledImageLoader>
        <Blurhash
          hash={imageHash}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0, opacity: imageLoaded ? 0 : 1 }}
        />

        <img
          src={imageUrl}
          alt="Loaded"
          style={{opacity: imageLoaded ? 1 : 0 }}
        />
    </StyledImageLoader>
  );
};

export default ImageLoader;
