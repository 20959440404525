import styled from "styled-components";

export const StyledProjectTimeline = styled.section`
  padding: 64px 0;
`;

export const StyledProjectTimelineSlides = styled.div`
  height: 696px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledProjectTimelineImage = styled.img`
  width: 1440px;
  user-select: none;
`;
