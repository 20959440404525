import { StyledProjectImage, StyledProjectImages } from "./styled";
import { ProjectType } from "../../types";
import { motion } from "framer-motion";
import { useRef } from "react";
import ProjectsInfo from "./ProjectsInfo";
import { useIsInViewport } from "../../utils/useIsInViewPort";
import Button from "../Button/Button";

type PropsType = {
  project: ProjectType;
};

const ProjectItem = ({ project }: PropsType) => {
  const container = useRef(null);
  const slide = useRef(null);
  const inViewPort = useIsInViewport(slide, 0.01);

  const fistItemVariants = {
    visible: { opacity: 1, top: `1%`, transition: { duration: 0.5 } },
    hidden: { opacity: 0, top: `100%` },
  };
  const secondItemVariants = {
    visible: {
      opacity: 1,
      top: "-9.5%",
      transition: { duration: 0.5 },
    },
    hidden: { opacity: 0, top: `100%` },
  };

  const lastItemVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 },
    },
    hidden: { scale: 0 },
  };

  return (
    <motion.article className={`slide`} ref={container}>
      <ProjectsInfo project={project} />
      <StyledProjectImages ref={slide} $imagecolor={project.image.color}>
        <StyledProjectImage
          src={project.image.lower}
          className={"project_image"}
          animate={inViewPort ? "visible" : "hidden"}
          variants={fistItemVariants}
        />
        <StyledProjectImage
          src={project.image.middle}
          className={"project_image"}
          animate={inViewPort ? "visible" : "hidden"}
          variants={secondItemVariants}
        />
        <StyledProjectImage
          src={project.image.higher}
          className={"project_image"}
          animate={inViewPort ? "visible" : "hidden"}
          variants={lastItemVariants}
        />
      </StyledProjectImages>
    </motion.article>
  );
};

export default ProjectItem;
