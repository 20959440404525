import { ProjectMobileCardType } from "../../../types";
import {
  StyledProjectTimelineImage,
  StyledProjectTimelineSlides,
} from "./styled";

type PropsType = {
  image: Array<ProjectMobileCardType>;
};

const ProjectTimelineMobile = ({ image }: PropsType) => {
  return (
    <div>
      <StyledProjectTimelineSlides>
        {image.map((el, i) => (
          <StyledProjectTimelineImage
            key={i}
            src={el.image.mobile}
            style={{ maxWidth: 1440, minWidth: 1440, width: 1440 }}
            alt={`mobile project timeline`}
          />
        ))}
      </StyledProjectTimelineSlides>
    </div>
  );
};

export default ProjectTimelineMobile;
