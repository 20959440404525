import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const StyledProjects = styled(motion.section)`
  max-width: 1280px;
  margin: 0 auto;
  padding: 120px 20px;
  opacity: 1;
  transition: 0.8s ease;
  position: relative;
  z-index: 10;

  &.hidden {
    opacity: 0;
  }

  h2 {
    margin-bottom: -12px;
  }

  & .arrow {
    position: absolute;
    width: 44px;
    height: 44px;
    display: none;
    align-items: center;
    border-radius: 100%;
    background-color: ${({ theme }) =>
    theme.text === "#fff"
      ? "rgba(255, 255, 255, 0.18)"
      : "rgba(0, 0, 0, 0.18)"};
    backdrop-filter: blur(5.5px);
    z-index: 1;

    & svg {
      flex: 1 0 auto;
      filter: ${({ theme }) => (theme.text === "#fff" ? "invert(1)" : "#000")};
    }

    @media (max-width: 732px) {
      display: flex;
    }
  }

  & .leftArrow {
    top: 166px;
    left: 22px;
    transform: rotate(180deg);
    
    @media (max-width: 732px) {
      top: 220px;
    }
  }

  & .rightArrow {
    top: 166px;
    right: 22px;
    
    @media (max-width: 732px) {
      top: 220px;
    }
  }

  @media (max-width: 732px) {
    padding: 48px 20px;
  }

  & .slider {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 554px;
    padding-top:38px; 
    margin-bottom: 64px;

    & .slide {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 38px;
      padding: 42px 10px;

      &.fadeOutAnim {
        & .project_image {
          transition: 0.5s linear;

          &:first-child {
            transform: translateY(103%);
          }

          &:nth-child(2) {
            transform: translateY(103%);
          }

          &:last-child {
            opacity: 0;
            scale: 0;
          }
        }
      }

      @media (max-width: 1230px) {
        && {
          max-width: 100%;
          min-width: 100%;
        }

        grid-template-columns: 1fr;
        & > div:first-child {
          display: none;
        }
      }
    }

    @media (max-width: 732px) {
      height: 246px;
      margin-bottom: 24px;
    }
  }

  & .thumbnail {
    width: 99%;
    display: flex;
    justify-content: space-between;

    & .thumb {
      cursor: pointer;
      margin-top: 10px;
      height: 100px;
      max-width: 19.6%;
      min-width: 16.4%;
      transition: 0.5s ease all;

      & .bar {
        width: 0;
        background: ${({ theme }) => theme.projects.progressActive};
      }

      & div {
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.projects.progressBg};
        position: relative;
      }

      & p {
        margin: 8px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }

      & h3 {
        max-width: 202px;
        color: ${({ theme }) => theme.projects.name};
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        margin: 0;
        @media (max-width: 1230px) {
          max-width: 118px;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }

    & h3.active_text {
      color: ${({ theme }) => theme.projects.nameActive};
    }

    & .active {
      max-width: 26.8%;
      min-width: 26.8%;
    }

    & .toFullAnim {
      & .bar {
        width: 100%;
        transition: width 15.2s linear;
      }
    }

    & .alreadyFull {
      & .bar {
        width: 100%;
      }
    }

    @media (max-width: 732px) {
      & .thumb {
        max-width: 4.6%;
        min-width: 4%;
        height: 6px;

        & p {
          display: none;
        }

        & h3 {
          display: none;
        }

        & h3.active {
          position: relative;
          top: 10px;
          display: inline;
          width: inherit;
        }
      }

      & .active {
        max-width: 70%;
        min-width: 70%;
      }
    }
  }
`;

export const StyledProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  & h3 {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 4px;
    margin: 0 0 12px;
    color: ${({ theme }) => theme.projects.titleColor};
    background: ${({ theme }) => theme.projects.titleBg};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 440px) {
    & button {
      width: 96%;
    }
  }

  
  @media (max-width: 900px) {
    div {
      &:nth-child(2) {
        h2 {
          display: none;
        }
      }
    }
  }
`;

export const StyledContactBtn = styled.div<{
  $image: string;
  $animate: boolean;
}>`
  position: absolute;
  right: 0;
  bottom: 220px;
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ $animate }) => $animate ? '1' : '0'};
  transform: translate(100%, ${({ $animate }) => $animate ? '0%' : '-200%'});
  transition: all .4s ease;

  div {
    width: 57px;
    height: 57px;
    background-color: ${({ theme }) =>
    theme.text === "#fff"
      ? '#fff'
      : '#1953FF'
  };
    border-radius: 100%;
    transition: all .3s ease;
  }

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: rotate 15s linear infinite; 

    path {
      fill: ${({ theme }) =>
    theme.text === "#fff"
      ? '#fff'
      : '#000'
  };
    }

    circle {
      fill: ${({ theme }) =>
    theme.text === "#fff"
      ? '#fff'
      : '#1953FF'
  };
    }
  }

  &:hover {
    div {
      transform: scale(1.2)
    }
  }



  @media (max-width: 1500px) {
    right: 60px;
    bottom: 250px;
    width: 80px;
    height: 80px;

    div {
      width: 45px;
      height: 45px;
    }
  }

  
  @media (max-width: 768px) {
    right: 30vw;
    bottom: initial;
    top: 425px;
  }

  @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
`;

export const StyledProjectServices = styled.div`

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  & h2 {
    display: none;
  }

  @media (max-width: 732px) {
    margin-top: 0;
    & h2 {
      display: block;
      color: ${({ theme }) => theme.projects.nameActive};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
`;
export const StyledProjectDescription = styled.div`
  & p {
    max-width: 496px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.text};

    @media (max-width: 1230px) {
      max-width: 100%;
    }
  }
`;

export const StyledProjectImages = styled.div<{ $imagecolor: string }>`
  position: relative;

  & > div {
    position: absolute;

    &:first-child {
      width: 85%;
      height: 408px;
      background-color: ${({ $imagecolor }) => $imagecolor || ``};
      border-radius: 16px;
      z-index: 1;
    }

    &:nth-child(2) {
      z-index: 2;
      height: 454px;
      left: 4%;
    }

    &:last-child {
      z-index: 3;
      width: 460px;
      left: 24%;
      top: 12%;
    }
  }

  @media (max-width: 1230px) {
    width: 500px;
    margin: 0 auto;
    & > div {
      &:first-child {
        width: 500px;
        height: 408px;
        background-color: #0a1d2d;
        border-radius: 16px;
        z-index: 1;
        left: 0;
        top: 100%;
      }

      &:nth-child(2) {
        z-index: 2;
        height: 454px;
        width: 395px;
        top: 100%;
      }

      &:last-child {
        width: 460px;
        z-index: 3;
        top: 12%;
      }
    }
  }
  @media (max-width: 732px) {
    width: 330px;
    left: 8%;
    & > div {
      &:first-child {
        width: 50%;
        height: 221px;
        background-size: contain;
        background-color: #0a1d2d;
        border-radius: 16px;
        z-index: 1;
        left: 0;
        top: 100%;
      }

      &:nth-child(2) {
        z-index: 2;
        height: 246px;
        width: 213px;
        background-size: contain;
        top: 0;
      }

      &:last-child {
        width: 226px;
        background-size: contain;
        z-index: 3;
        top: 12%;
      }
    }
  }
  @media (max-width: 400px) {
    width: 92%;
  }
`;

export const StyledProjectImage = styled(motion.div) <{ src: string }>`
  width: 100%;
  height: 100%;

  ${({ src }) =>
    src &&
    css`
            background-image: url(${src});
            background-repeat: no-repeat;
          `}
`;

export const StyledProjectBtns = styled.div`
  display: flex;
  gap: 13px;

  button[type="secondary"] {
    border: none !important;
    background: none !important;
  }

  @media (max-width: 768px) {
   flex-direction: column;
  }
`;
