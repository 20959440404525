import styled, { css } from "styled-components";

export const StyledButton = styled.button<{
  type: "primary" | "secondary";
  $text: string;
}>`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.buttons.primary.text};
  background: ${({ theme }) => theme.buttons.primary.bg};
  border-radius: 50px;
  padding: 10px 24px;
  border: 1px solid transparent;
  transition: 0.25s all ease;
  cursor: none;
  margin: 2px;
  user-select: none;

  & p {
    margin: 0;
    position: relative;
    transition: 0.3s all ease;
  }

  & path {
    fill: ${({ theme }) => theme.buttons.primary.text};
  }

  &:after {
    display: block;
    content: ${(props) => `"${props.$text ? props.$text : ""}"`};
    position: absolute;
    top: 30px;
    left: 24px;
    z-index: 1;
    transform: translateY(0) rotateX(90deg);
    transition: 0.3s all ease;
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  &:hover:enabled {
    cursor: pointer;
    background: ${({ theme }) => `
      linear-gradient(
        to bottom,
        ${theme.buttons.primary.hoverBg},
         ${theme.buttons.primary.hoverBg}
      )
      padding-box,
      ${
            theme.text === "#fff"
                    ? theme.buttons.primary.hoverBorder
                    : "transparent"
    }
      border-box;
    `};
    color: ${({ theme }) => theme.buttons.primary.hoverText};
    border: ${({ theme }) =>
            theme.text !== "#fff"
                    ? `1px solid ${theme.buttons.primary.hoverBorder}`
                    : "1px solid transparent"};

    & p {
      transform: translateY(-100%) rotateX(90deg);
    }

    &:after {
      transform: translateY(-100%) rotateX(0deg);
    }

    & svg {
      transform: translateX(20px);

      &:last-child {
        opacity: 0;
      }
    }

    & path {
      fill: ${({ theme }) => theme.buttons.primary.hoverText};
    }
  }

  ${({ type }) =>
          type === 'secondary' &&
          css`
            background: ${({ theme }) => theme.body};
            color: ${({ theme }) => theme.text};
            border: ${({ theme }) => `1px solid ${theme.text}`};

            & path {
              fill: ${({ theme }) => theme.text};
            }
          `
  };

  @media (max-width: 440px) {
    justify-content: space-between;
  }
`;

export const ArrowWrapper = styled.div`
  margin-left: 11px;
  width: 20px;
  height: 20px;
  left: 6px;
  position: relative;
  overflow: hidden;

  & svg {
    transition: 0.2s all ease-out;
    position: absolute;
    top: 0;
    left: 0;

    &:first-child {
      left: -20px;
    }
  }
`;
