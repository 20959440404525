import { useNavigate } from "react-router-dom";
import { StyledLink } from "./styled";

type PropsType = {
  text: string;
  href: string;
};

const HeaderLink = ({ href, text }: PropsType) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();

    if (window.location.pathname === '/') {
      const anchorId = href.split('#')[1];
      const anchorElement = document.getElementById(anchorId);

      if (anchorElement) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const top = anchorElement.getBoundingClientRect().top + scrollTop;
        window.scrollTo({ top, left: 0, behavior: 'smooth' });
      }
    } else {
      window.location.href = href;
    }
  }

  return (
    <StyledLink>
      <a href={href} onClick={(e) => handleClick(e)}>{text}</a>
    </StyledLink>
  );
};

export default HeaderLink;
