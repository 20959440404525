import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  StyledPortfolioContentFilterWrapper,
  StyledPortFolioFilter,
} from "../styled";
import RoundTag from "../../../RoundTag/RoundTag";
import { ProjectsFilterType } from "../PortfolioContent";
import { ProjectType } from "../../../../types";
import { projectsData } from "../../../../data/projectsData";
import { useLocation } from "react-router-dom";

type PropsType = {
  filteredProjects: ProjectType[];
  projectsFilter: ProjectsFilterType;
  setProjectsFilter: Dispatch<SetStateAction<ProjectsFilterType>>;
};

const PortfolioServicesFilter = ({
  setProjectsFilter,
  projectsFilter,
  filteredProjects,
}: PropsType) => {

  const servicesTags = projectsData
    .map((el) => el.services)
    .flat()
    .filter((el, i, arr) => arr.indexOf(el) === i);
  const handleServicesFilter = (val: string) => {
    const deleteItem = () => {
      setProjectsFilter((prev) => ({
        ...prev,
        services: prev.services.filter((el) => el !== val),
      }));
    };
    const addItem = () => {
      setProjectsFilter((prev) => ({
        ...prev,
        services: [...prev.services, val],
      }));
    };

    if (val) {
      projectsFilter.services.includes(val) ? deleteItem() : addItem();
    } else {
      setProjectsFilter((prev) => ({ ...prev, services: [] }));
    }
  };

  const disableCheck = (val: string) => {
    return filteredProjects.every((pr) => !pr.services.includes(val));
  };

  return (
    <StyledPortFolioFilter>
      <p>Services</p>
      <StyledPortfolioContentFilterWrapper>
        <RoundTag
          text={"All"}
          isClicked={!projectsFilter.services.length}
          onClick={() => handleServicesFilter("")}
        />
        {servicesTags.map((el, i) => (
          <RoundTag
            text={el}
            key={i}
            isClicked={projectsFilter.services.includes(el)}
            onClick={() => handleServicesFilter(el)}
            isDisabled={disableCheck(el)}
          />
        ))}
      </StyledPortfolioContentFilterWrapper>
    </StyledPortFolioFilter>
  );
};

export default PortfolioServicesFilter;
