import React, { useState } from "react";
import {
  StyledPortfolioCards,
  StyledPortfolioContent,
  StyledShowMoreButton,
} from "./styled";
import PortfolioContentFilter from "./Filter/PortfolioContentFilter";
import { projectsData } from "../../../data/projectsData";
import PortfolioCard from "./PortfolioCard";
import PortfolioFilterMobile from "./Filter/PortfolioFilterMobile";
import PortfolioMobileFilterButton from "./Filter/PortfilioMobileFilterButton";
import { ProjectType } from "../../../types";
import RoundTagOutline from "../../RoundTag/RoundTagOutline";
import { useTheme } from "styled-components";
import { AnimatePresence } from "framer-motion";

export type ProjectsFilterType = {
  industries: string[];
  services: string[];
};

const PortfolioContent = () => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paginationCounter, setPaginationCounter] = useState(5);
  const [pagination, setPagination] = useState<[number, number]>([0, 5]);
  const [projectsFilter, setProjectsFilter] = useState<ProjectsFilterType>({
    industries: [],
    services: [],
  });
  const filteredProjects = projectsData.filter((el) => {
    let industriesRes = !!projectsFilter.industries.length
      ? projectsFilter.industries.includes(el.industry)
      : true;
    let servicesRes = !!projectsFilter.services.length
      ? projectsFilter.services.every((serv) => el.services.includes(serv))
      : true;
    return industriesRes && servicesRes;
  });

  const sortProjects = (projects: ProjectType[]) => {
    const favProjects = projects.filter((project) => project.isFav);
    const nonFavProjects = projects.filter((project) => !project.isFav);

    let sortedProjects = [];

    while (nonFavProjects.length > 0) {
      for (let i = 0; i < 2 && nonFavProjects.length > 0; i++) {
        sortedProjects.push(nonFavProjects.shift());
      }

      if (favProjects.length > 0) {
        sortedProjects.push(favProjects.shift());
      }
    }

    // If there are still favorite projects left, add them to the end
    sortedProjects = [...sortedProjects, ...favProjects];

    if (
      sortedProjects[sortedProjects.length - 2]?.isFav == undefined &&
      sortedProjects[sortedProjects.length - 3]?.isFav
    ) {
      let temp = {
        ...sortedProjects[sortedProjects.length - 1],
      } as ProjectType;
      sortedProjects[sortedProjects.length - 1] =
        sortedProjects[sortedProjects.length - 2];
      sortedProjects[sortedProjects.length - 2] = temp;
    }

    return sortedProjects as ProjectType[];
  };

  const sortedProjects = sortProjects(filteredProjects).slice(
    pagination[0],
    pagination[1],
  );

  const handleMobileButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleClickShowMore = () => {
    const counter = paginationCounter === 5 ? 4 : 5;
    setPagination([pagination[0], pagination[1] + counter]);
    setPaginationCounter(prev => prev === 5 ? 4 : 5);
  };

  return (
    <StyledPortfolioContent>
      <PortfolioFilterMobile
        isOpen={isModalOpen}
        setIsMobileOpen={setIsModalOpen}
        setProjectsFilter={setProjectsFilter}
        projectsFilter={projectsFilter}
        filteredProjects={filteredProjects}
      />
      <PortfolioContentFilter
        filteredProjects={filteredProjects}
        projectsFilter={projectsFilter}
        setProjectsFilter={setProjectsFilter}
      />
      <PortfolioMobileFilterButton
        isChecked={
          !!projectsFilter.services.length || !!projectsFilter.industries.length
        }
        onClick={handleMobileButtonClick}
      />
      <AnimatePresence>
        <StyledPortfolioCards
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {sortedProjects.map((el, i) => (
            <PortfolioCard project={el} key={i} />
          ))}
        </StyledPortfolioCards>
      </AnimatePresence>

      {filteredProjects.length !== sortedProjects.length && (
        <StyledShowMoreButton>
          <RoundTagOutline
            text={"Show more"}
            color={theme.text}
            onClick={handleClickShowMore}
          />
        </StyledShowMoreButton>
      )}
    </StyledPortfolioContent>
  );
};

export default PortfolioContent;
