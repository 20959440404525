import React from "react";
import {
  StyledProjectHeaderText,
  StyledProjectHeaderTextWrapper,
} from "./styled";
import seoImg from "../../../assets/images/social-image.jpg"
import { Helmet } from "react-helmet";

type PropsType = {
  title: string;
  titleColor: string;
  description?: string;  
  seoTitle?: string;
  seoDescription?: string; 
};

const ProjectHeaderText = ({ seoTitle, seoDescription, title, titleColor, description }: PropsType) => {
  return (    
    <StyledProjectHeaderTextWrapper>      
      <Helmet>
        <title>{seoTitle}</title>
        {/* <meta name="description" content={seoDescription} /> */}

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content={seoImg} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={seoTitle} />
        <meta property="twitter:description" content={seoDescription} />
        <meta property="twitter:image" content={seoImg} />
      </Helmet>
      <StyledProjectHeaderText $titleColor={titleColor}>
        {title}
      </StyledProjectHeaderText>
      {description && <p>{description}</p>}
    </StyledProjectHeaderTextWrapper>
  );
};

export default ProjectHeaderText;
