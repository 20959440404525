import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface ScrollToTopProps {}

const ScrollToTop: React.FC<ScrollToTopProps> = () => {
  const locationPathName = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant" as ScrollBehavior,
    });
  }, [locationPathName]);

  return null;
};

export default ScrollToTop;
