import { StyledPortfolioMobileFilterButton } from "../styled";

type PropsType = {
  isChecked: boolean
  onClick: () => void
}

const PortfolioMobileFilterButton = ({ isChecked, onClick }: PropsType) => {
  return (
    <StyledPortfolioMobileFilterButton $ischecked={isChecked} onClick={onClick}>
      <span>
        Filter By
      </span>
    </StyledPortfolioMobileFilterButton>
  );
};

export default PortfolioMobileFilterButton;