import React from "react";
import { motion } from "framer-motion";
import { StoriesAnimType } from "../../../types";

type PropsType = {
  activeStory: number | null;
  animations: StoriesAnimType;
};

const ChoseStory = ({ activeStory, animations }: PropsType) => {
  return (
    <motion.p
      animate={activeStory !== null ? "hidden" : "active"}
      variants={animations.paragraphAnimation}
    >
      <motion.span
        animate={activeStory !== null ? "hidden" : "active"}
        variants={animations.spanAnimation}
      >
        Read the stories of our clients and see how Niwart’s solutions have
        helped them reach their{" "}
      </motion.span>
      <motion.span
        animate={activeStory !== null ? "hidden" : "active"}
        variants={animations.spanChildrenAnimation}
      >
        Business Goals
      </motion.span>
    </motion.p>
  );
};

export default ChoseStory;
