import styled from "styled-components";

export const StyledProjectHeaderTextWrapper = styled.section`
  margin: 0 auto 64px;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  & p {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
    text-align: center;
    margin: 0;
  }

  @media (max-width: 786px) {
    margin-bottom: 30px;

    & p {
      font-size: 28px;
      line-height: 36px;
    }
  }
`;

export const StyledProjectHeaderText = styled.h1<{ $titleColor: string }>`
  width: 100%;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: ${({ $titleColor }) => $titleColor};
  margin-bottom: 8px;
`;
