import { StyledIconButton } from "./styled";
import { ReactNode } from "react";

type PropsType = {
  children: ReactNode;
  className?: string;
  onClick: () => void;
};

const IconButton = ({ children, onClick, className = "" }: PropsType) => {
  return (
    <StyledIconButton className={className} onClick={onClick}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
