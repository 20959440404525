import { StyledContactBtn } from "./styled";
import { ContactUsImg } from "../Icons/Icons";
import contactBtnImage from "../../assets/images/contact-us-white.png"
import { Dispatch, SetStateAction, useState } from "react";

type PropsType = {
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const ContactButton = ({ setShowModal }: PropsType) => {
    const [showBtn, setShowBtn] = useState(true);
    const currentPath = window.location.pathname;
    const hasProjectPath = currentPath.includes('/project');

    window.addEventListener('scroll', function () {
        const contactUsSection = document.getElementById('contact-us');

        if (contactUsSection) {
            const contactUsSectionTop = contactUsSection.offsetTop;
            const windowHeight = window.innerHeight;
            const scrollPosition = window.scrollY;

            if (scrollPosition + windowHeight >= contactUsSectionTop) {
                setShowBtn(false);
            } else {
                setShowBtn(true);
            }
        }
    });

    if (hasProjectPath) {
        return (
            <StyledContactBtn
                className={showBtn ? '' : 'hide'}
                $image={contactBtnImage}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                <div />
                <ContactUsImg />
            </StyledContactBtn>
        );
    }
    return null;
};

export default ContactButton;
