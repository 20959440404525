import { MoonIcon, SunIcon } from "../Icons/Icons";
import { StyledLabel } from "./styled";

type PropsType = {
  theme: "light" | "dark";
  toggleMode: () => void;
};

export default function DarkModeSwitcher({ toggleMode, theme }: PropsType) {
  return (
    <StyledLabel checked={theme === "dark"}>
      <label>
        <input
          type="checkbox"
          checked={theme === "dark"}
          onChange={toggleMode}
        />
        <div className={"wrapper"}>
          <SunIcon />
          <MoonIcon />
        </div>
      </label>
    </StyledLabel>
  );
}
