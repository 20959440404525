import styled from "styled-components";

export const StyledProjectGoals = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 24px;
  width: 100%;
  max-width: 1264px;
  margin: 0 auto;
  padding: 64px 20px;

  @media (max-width: 1280px) {
    padding: 0 24px;
  }
  @media (max-width: 690px) {
    flex-direction: column;
  }
`;

export const StyledGoalsWrapper = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const StyledGoalItem = styled.div<{ $basis: number }>`
  flex-basis: ${({ $basis }) => `${$basis}%`};
  padding: 32px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.text}`};

  & h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  & p {
    margin-top: 16px;
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 960px) {
    max-width: 420px;
  }
`;
