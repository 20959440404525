import styled, { css } from "styled-components";

export const StyledProjectTextSection = styled.section<{
  $maxWidth?: string;
  $gap?: string;
  direction: "vertical" | "horizontal";
  $padding?: string;
}>`
  width: 100%;
  margin: 0 auto;
  display: grid;
  align-items: center;
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : "1440px")};
  gap: ${({ $gap }) => ($gap ? $gap : "24px")};
  padding: ${({ $padding }) => ($padding ? $padding : "0 20px")};

  & p,
  h2 {
    margin: 0;
  }

  & p {
    line-height: 28px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  ${({ direction }) =>
    direction === "vertical"
      ? css`
          grid-template-columns: 1fr;
        `
      : css`
          grid-template-columns: 1fr 1.1fr;

          @media (max-width: 786px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr max-content;
          }
        `}
`;

export const StyledProjectMonoTitle = styled.h2<{
  $color: string;
  $headerMaxWidth?: string;
}>`
  color: ${({ $color, theme }) => ($color === "#fff" ? theme.text : $color)};
  font-size: 40px;
  line-height: 52px;
  font-weight: 400;
  max-width: ${({ $headerMaxWidth }) =>
    $headerMaxWidth ? $headerMaxWidth : "100%"};

  @media (max-width: 786px) {
    max-width: 100%;
  }
`;

export const StyledProjectTwoColorTitle = styled.h2<{
  $color: string;
  $headerMaxWidth?: string;
}>`
  font-size: 48px;
  line-height: 64px;
  font-weight: 400;
  max-width: ${({ $headerMaxWidth }) =>
    $headerMaxWidth ? $headerMaxWidth : "100%"};

  & span {
    font-size: inherit;
    line-height: inherit;
    font-style: italic;
    font-weight: 200;
    color: ${({ $color }) => $color};
  }

  @media (max-width: 786px) {
    font-size: 32px;
    line-height: 40px;
    max-width: 100%;
  }
`;
