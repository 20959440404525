import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  StyledPortfolioContentFilterWrapper,
  StyledPortFolioFilter,
} from "../styled";
import RoundTag from "../../../RoundTag/RoundTag";
import { ProjectsFilterType } from "../PortfolioContent";
import { ProjectType } from "../../../../types";
import { projectsData } from "../../../../data/projectsData";
import { useLocation } from "react-router-dom";

type PropsType = {
  filteredProjects: ProjectType[];
  projectsFilter: ProjectsFilterType;
  setProjectsFilter: Dispatch<SetStateAction<ProjectsFilterType>>;
};

const PortfolioIndustriesFilter = ({
  setProjectsFilter,
  projectsFilter,
  filteredProjects,
}: PropsType) => {  
  const location = useLocation();
  
  const industriesTags = projectsData
    .map(({ industry }) => industry)
    .flat()
    .filter((el, i, arr) => arr.indexOf(el) === i);

  const handleIndustriesFilter = (val: string) => {
    console.log(val);
    const delItem = () => {
      setProjectsFilter((prev) => ({
        ...prev,
        industries: prev.industries.filter((el) => el !== val),
      }));
    };
    const addItem = () => {
      setProjectsFilter((prev) => ({
        ...prev,
        industries: [...prev.industries, val],
      }));
    };

    if (val) {
      projectsFilter.industries.includes(val) ? delItem() : addItem();
    } else {
      setProjectsFilter((prev) => ({ ...prev, industries: [] }));
    }
  };

  const disableCheck = (val: string) => {
    return !filteredProjects.some((pr) => pr.industry.includes(val));
  };

  return (
    <StyledPortFolioFilter>
      <p>Industries</p>
      <StyledPortfolioContentFilterWrapper>
        <RoundTag
          text={"All"}
          onClick={() => handleIndustriesFilter("")}
          isClicked={!projectsFilter.industries.length}
        />
        {industriesTags.map((el, ind) => (
          <RoundTag
            text={el}
            key={ind}
            onClick={() => handleIndustriesFilter(el)}
            isClicked={projectsFilter.industries.includes(el)}
            isDisabled={disableCheck(el)}
          />
        ))}
      </StyledPortfolioContentFilterWrapper>
    </StyledPortFolioFilter>
  );
};

export default PortfolioIndustriesFilter;
