import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  PortfolioIndustriesFilterButtonsWrapper,
  PortfolioIndustriesFilterWrapper,
  StyledMobilePortfolioFilter,
  StyledMobilePortfolioFilterHeader
} from "../styled";
import PortfolioIndustriesFilter from "./PortfolioIndustriesFilter";
import PortfolioServicesFilter from "./PortfolioServicesFilter";
import { ProjectType } from "../../../../types";
import { ProjectsFilterType } from "../PortfolioContent";
import Button from "../../../Button/Button";
import { createPortal } from 'react-dom';
import { Link, useLocation } from "react-router-dom";
import { HOME } from "../../../../routes";
import { NiwartIcon, XmarkIcon } from "../../../Icons/Icons";
import { useTheme } from "styled-components";
import IconButton from "../../../IconButton/IconButton";
import { AnimatePresence } from "framer-motion";

type PropsType = {
  isOpen: boolean
  setIsMobileOpen: Dispatch<SetStateAction<boolean>>
  filteredProjects: ProjectType[]
  projectsFilter: ProjectsFilterType
  setProjectsFilter: Dispatch<SetStateAction<ProjectsFilterType>>
}

const PortfolioFilterMobile = ({
  setIsMobileOpen,
  isOpen,
  projectsFilter,
  filteredProjects,
  setProjectsFilter
}: PropsType) => {
  const theme = useTheme();
  const location = useLocation();
  const [filteredByUrl, setFilteredByUrl] = useState(false);
  const handleClose = () => {
    setIsMobileOpen(false)
  }
  const onClearClick = () => {
    setProjectsFilter({
      industries: [],
      services: []
    })
  }
  const isFilterOn = !!projectsFilter.services.length || !!projectsFilter.industries.length
  const getNumberOfResults = () => {
    return isFilterOn ? ` (${filteredProjects.length})` : ''
  }
  const getButtonClassName = () => {
    let str = 'mobile'
    if (isOpen) str += ' opened'
    if (theme.text !== '#fff') str += ' revert'
    return str
  }

  useEffect(() => {
    if (!filteredByUrl) {
      setFilteredByUrl(true);
      const urlSearchParams = new URLSearchParams(location.search);
      const serviceParam = urlSearchParams.get('service');
      if (serviceParam) {
        setProjectsFilter((prev) => ({
          ...prev,
          services: [...prev.services, serviceParam],
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (!filteredByUrl) {
      setFilteredByUrl(true);
      const urlSearchParams = new URLSearchParams(location.search);
      const industriesParam = urlSearchParams.get('industries');
      if (industriesParam) {
        setProjectsFilter((prev) => ({
          ...prev,
          industries: [...prev.services, industriesParam],
        }));
      }
    }
  }, []);

  return (
    createPortal(<AnimatePresence>
      {isOpen && <StyledMobilePortfolioFilter
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <StyledMobilePortfolioFilterHeader>
          <Link to={HOME}>
            <NiwartIcon theme={theme.text === '#fff' ? 'dark' : 'light'} />
          </Link>
          <IconButton
            className={getButtonClassName()}
            onClick={handleClose}
          >
            <XmarkIcon />
          </IconButton>
        </StyledMobilePortfolioFilterHeader>
        <PortfolioIndustriesFilterWrapper>
          <PortfolioIndustriesFilter
            filteredProjects={filteredProjects}
            setProjectsFilter={setProjectsFilter}
            projectsFilter={projectsFilter} />
          <PortfolioServicesFilter
            filteredProjects={filteredProjects}
            setProjectsFilter={setProjectsFilter}
            projectsFilter={projectsFilter}
          />
        </PortfolioIndustriesFilterWrapper>
        <PortfolioIndustriesFilterButtonsWrapper>
          {isFilterOn &&
            <Button text={`Clear Filters`} type={'secondary'} onClick={onClearClick}
              icon={<XmarkIcon className={'xmark'} />} />}
          {isFilterOn
            ? <Button text={`View Results ${getNumberOfResults()}`} type={'primary'} onClick={handleClose} />
            : <Button type={'secondary'} text={`View Results ${getNumberOfResults()}`} onClick={handleClose} />
          }
        </PortfolioIndustriesFilterButtonsWrapper>
      </StyledMobilePortfolioFilter>}
    </AnimatePresence>, document.body)
  );
};

export default PortfolioFilterMobile;